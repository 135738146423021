import i18n from 'i18next';
import HttpBackend from 'i18next-http-backend';
import LanguageDetector from 'i18next-browser-languagedetector';
import { initReactI18next } from 'react-i18next';

i18n
  .use(HttpBackend)
  .use(LanguageDetector)
  .use(initReactI18next)
  .init({
    fallbackLng: {
      'es-419': ['es', 'en'],  // First try 'es', then 'en' if 'es-419' not found
      'default': ['en']        // Fallback to 'en' for all other cases
    },
    supportedLngs: ['en', 'es', 'es-419'],
    load: 'languageOnly',      // Ignores region code when loading languages
    debug: true,
    backend: {
      loadPath: '/locales/{{lng}}/translation.json'
    },
    interpolation: {
      escapeValue: false,      // React already does escaping
    },
    detection: {
      order: ['querystring', 'cookie', 'localStorage', 'navigator'],
      caches: ['localStorage', 'cookie'],
      checkWhitelist: true     // Ensures only supported languages are used
    }
  });

export default i18n;
