import { createContext, useEffect, useReducer } from "react"
import { auth } from '../firebase/config'

export const AuthContext = createContext()

export const authReducer = (state, action) => {
    switch (action.type){
        case 'LOGIN':
            return {...state, user: action.payload}
        case 'AUTH_IS_READY':
            return { user: action.payload, authIsReady: true }
        case 'LOGOUT':
            return {...state, user: null}
        default:
            return state
    }
} 

export const AuthContextProvider = ({children}) => {
    const [state, dispatch] = useReducer(authReducer, {
        user: null,
        authIsReady: false
    })

    const refreshUser = async () => {
        const user = auth.currentUser;
        if (user) {
            const tokenResult = await user.getIdTokenResult(true);
            const userWithClaims = { ...user, claims: tokenResult.claims };
            dispatch({ type: 'AUTH_IS_READY', payload: userWithClaims });
        }
    };

    useEffect(() => {
        const unsub = auth.onAuthStateChanged(async (user) => {
            if (user) {
                const tokenResult = await user.getIdTokenResult();
                const userWithClaims = { ...user, claims: tokenResult.claims };
                dispatch({ type: 'AUTH_IS_READY', payload: userWithClaims });
              } else {
                dispatch({ type: 'AUTH_IS_READY', payload: null });
              }
              unsub();
        })
    }, [])

    console.log('AuthContext state:', state)

    return (
        <AuthContext.Provider value={{...state, dispatch, refreshUser}}>
            {children}
        </AuthContext.Provider>
    )
}
