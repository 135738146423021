import React, { useState } from 'react';
import { TextField, FormControl, Button } from '@mui/material';
import TempForm from './TempForm';

import Logo from '../Landing/images/Nannapp_large.png';



const FullPage = () => {

  // Add code to submit form here
  return (
    <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', backgroundColor: '#f5f5f5' }}>
      <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
        <img style={{ marginBottom: '1rem', maxWidth: '350px' }} src={Logo} alt="Your file" />
        <TempForm />
      </div>
    </div>
  );
};

export default FullPage;
