/* eslint-disable no-unused-vars */
/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useEffect, useState } from "react";
import "bootstrap/dist/css/bootstrap.css";
import "bootstrap/dist/js/bootstrap.js";
import "./css/style.css";
import { AppBar, Box, Toolbar, Typography, Button } from "@mui/material";
import EmailIcon from "@mui/icons-material/Email";


import MenuNavbar from "./components/MenuNavbar";
import SignUpDialog from "./components/SignUpDialog";
import LoginDialog from "./components/LoginDialog";
import ResetPasswordDialog from "./components/ResetPasswordDialog";
import { Link } from "react-router-dom";
export default function TermsAndConditions() {
  const [imgUrl, setImgUrl] = useState("");
  const [width, setWidth] = useState(window.innerWidth);

  const [openSignUp, setOpenSignUp] = useState(false);
  const [openLogin, setOpenLogin] = useState(false);

  const [openResetPassword, setOpenResetPassword] = useState(false);
  const handleResetPasswordClick = () => {
    setOpenLogin(false);
    setOpenResetPassword(true);
  };

  useEffect(() => {
    const mediaQuery = window.matchMedia("(max-width: 768px)");
    const handleMediaQueryChange = (mediaQuery) => {
      if (mediaQuery.matches) {
        setImgUrl(require("./images/Banner_Slider_p3.png")); /*Mobile Slider*/
      } else {
        setImgUrl(require("./images/Banner_Slider_p.png")); /*Desktop Slider*/
      }
    };

    handleMediaQueryChange(mediaQuery);
    mediaQuery.addListener(handleMediaQueryChange);

    return () => mediaQuery.removeListener(handleMediaQueryChange);
  }, []);

  useEffect(() => {
    const handleResize = () => setWidth(window.innerWidth);
    window.addEventListener("resize", handleResize);
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  return (
    <div>
      <div className="page-wrapper">
        <header className="main-header header-style-two">
          {/* Header Top */}
          <div className="header-top">
            <Box sx={{ flexGrow: 1 }}>
              <AppBar position="static" sx={{ backgroundColor: "#393d72" }}>
                <Toolbar>
                  <Typography
                    variant="h6"
                    component="div"
                    sx={{ flexGrow: 1, textAlign: "left" }}
                  >
                  
                    <Button
                      color="inherit"
                      startIcon={
                        <EmailIcon style={{ color: "rgb(255,174,0)" }} />
                      }
                      href="mailto:nannappcompany@gmail.com"
                    >
                      
                    </Button>
                  </Typography>
                  <Typography
                    variant="h6"
                    component="div"
                    sx={{ flexGrow: 1, textAlign: "right" }}
                  >
                    <Button color="inherit" onClick={() => setOpenSignUp(true)}>
                      Crear Cuenta
                    </Button>
                    <SignUpDialog
                      open={openSignUp}
                      onClose={() => setOpenSignUp(false)}
                    />
                    <Button color="inherit" onClick={() => setOpenLogin(true)}>
                      Iniciar Sesión
                    </Button>
                    <LoginDialog
                      open={openLogin}
                      onClose={() => setOpenLogin(false)}
                      onResetPasswordClick={handleResetPasswordClick} // Added this line
                    />
                    <ResetPasswordDialog
                      open={openResetPassword}
                      onClose={() => setOpenResetPassword(false)}
                    />
                  </Typography>
                </Toolbar>
              </AppBar>
            </Box>
          </div>
          {/* Header Top */}
          {/* Header Upper */}
          <div className="header-lower">
            <MenuNavbar />
          </div>
          {/*End Header Upper*/}
          {/* Sticky Header  */}
          <div className="sticky-header">
            <div className="auto-container clearfix">
              {/*Logo*/}
              <div className="logo pull-left">
                <a href="index.html" title>
                  <img src="images/logo.png" alt="" title />
                </a>
              </div>
              {/*Right Col*/}
              <div className="pull-right">
                {/* Main Menu */}
                <nav className="main-menu">
                  <div className="navbar-collapse show collapse clearfix">
                    <ul className="navigation clearfix">
                      <li className="current dropdown">
                      <Link to='/landing'>Home</Link>
                        <ul>
                          <li>
                            <a href="index.html">Home page 01</a>
                          </li>
                          <li>
                            <a href="index-2.html">Home page 02</a>
                          </li>
                          <li className="dropdown">
                            <a href="index.html">Header Styles</a>
                            <ul>
                              <li>
                                <a href="index.html">Header Style One</a>
                              </li>
                              <li>
                                <a href="index-2.html">Header Style Two</a>
                              </li>
                            </ul>
                          </li>
                        </ul>
                      </li>
                      {/* <li>
                        <a href="about.html">About Us</a>
                      </li>
                      <li className="dropdown">
                        <a href="events.html">Events</a>
                        <ul>
                          <li>
                            <a href="events.html">Events</a>
                          </li>
                          <li>
                            <a href="event-detail.html">Event Detail</a>
                          </li>
                        </ul>
                      </li>
                      <li className="dropdown">
                        <a href="programs.html">Programs</a>
                        <ul>
                          <li>
                            <a href="numbers-matching.html">Numbers Matching</a>
                          </li>
                          <li>
                            <a href="paper-plates-art.html">Paper Plates Art</a>
                          </li>
                          <li>
                            <a href="create-cartoon-egg.html">
                              Create Egg Cartoon
                            </a>
                          </li>
                          <li>
                            <a href="cooking-fun-cake.html">Cooking Fun Cake</a>
                          </li>
                          <li>
                            <a href="super-colors-game.html">
                              Super Colors Game
                            </a>
                          </li>
                          <li>
                            <a href="learning-disciplines.html">
                              Learning Disciplines
                            </a>
                          </li>
                        </ul>
                      </li>
                      <li>
                        <a href="gallery.html">Gallery</a>
                      </li>
                      <li className="dropdown">
                        <a href="news.html">Our News</a>
                        <ul>
                          <li>
                            <a href="news.html">Our News</a>
                          </li>
                          <li>
                            <a href="news-single.html">News Single</a>
                          </li>
                        </ul>
                      </li>
                      <li>
                        <a href="contact.html">Contact</a>
                      </li> */}
                    </ul>
                  </div>
                </nav>
                {/* Main Menu End*/}
              </div>
            </div>
          </div>
          {/* End Sticky Menu */}
        </header>
        {/* End Main Header */}
        {/*Page Title */}
        <section className="page-title" style={{backgroundImage: 'url(images/background/1.jpg)'}}>
    <div className="auto-container">
        <div className="inner-container clearfix">
            
            <h1>Términos y Condiciones</h1>
        </div>
    </div>
</section>
{/* End Page Title */}
        <section style={{padding: '50px', backgroundColor: '#f2f2f2', color: '#333', textAlign: 'justify', fontFamily: 'Arial, sans-serif', paddingLeft: "40px" , paddingRight:"40px"}}>
        De la relacion contractual
 Las presentes condiciones de uso en adelante las ("condiciones") regulan el acceso o uso que
usted como persona haga de las aplicaciones, paginas web, contenido, productos y servicios (los
"servicios") puestos a disposicion por NANNAPP, con domicilio el ubicado en: Juan Salvador Agraz
37 Suite 903, Col. Santa Fe, CP 05348 en la Ciudad de Mexico, lea estas condiciones
detenidamente antes de acceder o usar los servicios.
 Usted se somete juridicamente por estas condiciones mediante su acceso y uso de los
servicios, para establecer una relacion contractual entre usted y&nbsp;NANNAPP. si usted no
acepta estas condiciones, no podra acceder o usar los servicios. NANNAPP podra poner fin de
inmediato a estas condiciones o cualquiera de los servicios respecto de usted o, en general, dejar
de ofrecer o denegar el acceso a los servicios o cualquier parte de ellos, en cualquier momento y
por cualquier motivo.
 NANNAPP&nbsp;podra aplicar condiciones suplementarias a determinados servicios, asi como
politicas para determinados eventos, actividades o una promocion particular, y dichas condiciones
suplementarias se le comunicaran en relacion con los servicios aplicables. las condiciones
suplementarias se establecen ademas de las condiciones, y se consideraran una parte de estas,
para los fines de los servicios aplicables. las condiciones suplementarias prevaleceran sobre las
condiciones en el caso de conflicto con respecto a los servicios aplicables.
 NANNAPP se reserva el derecho en todo momento para modificar las condiciones relativas a
los servicios o productos cuando lo considere oportuno. las modificaciones seran efectivas despues
de la publicacion por parte de NANNAPP de dichas condiciones actualizadas en esta ubicacion o
las politicas modificadas o condiciones suplementarias sobre el servicio aplicable. su acceso o uso
continuado de los servicios despues de dicha publicacion constituye su consentimiento a vincularse
por las condiciones y sus modificaciones.
 Enlaces adicionales
 Aviso de Privacidad Integral.Terminos y condiciones.
 2.&nbsp;&nbsp;De los servicios
 Los servicios constituyen una plataforma de tecnologia que permite a los usuarios de
aplicaciones moviles de&nbsp;NANNAPP&nbsp;o paginas web proporcionadas como parte de los
servicios (cada una, una "aplicacion") solicitar los servicios de nineras domesticas al cuidado de
ninos o bebes, las nineras o prestadoras del servicio deberan tener amplia experiencia,
especializacion, etica y formacion para prestar los servicios solicitados por "EL CLIENTE", a traves
de aplicaciones descargables, incluidos terceros prestadores profesionistas particulares
independientes y terceros proveedores logisticos independientes, o algunos afiliados de NANNAPP
("terceros proveedores"), los servicios se ponen a disposicion solo para su uso personal, no
comercial. usted reconoce que NANNAPP no presta servicios de nineras de ningun tipo, incluidos
terceros prestadores profesionales independientes y terceros proveedores logisticos independientes
o funciona como una empresa de nineras y que dichos servicios de nineras o logistica, se prestan
por terceros prestadores particulares independientes, que no estan empleados por NANNAPP ni
por ninguno de sus afiliados.&nbsp;
 De la Licencia.
 Sujeto al cumplimiento de estas condiciones, NANNAPP le otorga una licencia limitada, no
exclusiva, no sublicenciable, revocable, no transferible para: (i) el acceso y uso de las aplicaciones
en su dispositivo personal solo en relacion con su uso de los servicios; y (ii) el acceso y uso de
cualquier contenido, informacion y material relacionado que pueda ponerse a disposicion a traves
de los servicios, en cada caso solo para su uso personal, no comercial. NANNAPP y sus
licenciantes se reservan cualquier derecho que no haya sido expresamente otorgado por el
presente.
 De las Restricciones.
 Usted no podra realizar cualquier distribucion, publicacion o explotacion comercial o
promocional, o de cualquiera de los contenidos, codigos, datos o materiales, esta estrictamente
prohibida, a menos de que usted haya recibido el previo permiso expreso por escrito otorgado por
(LA EMPRESA), Usted se obliga a no descargar, divulgar, exponer, publicar, copiar, reproducir,
distribuir, transmitir, modificar, ejecutar, difundir, transferir, crear trabajos derivados de, vender o de
cualquier otra manera explotar cualquiera de los contenidos, codigos, datos o materiales en o
disponibles a traves de la aplicaciones moviles o el Sitio Web. Usted se obliga ademas a no alterar,
editar, borrar, quitar, o de otra manera cambiar el significado o la apariencia de, o cambiar el
proposito de, cualquiera de los contenidos, codigos, datos o materiales, incluyendo, sin limitacion, la
alteracion o retiro de cualquier marca comercial, marca registrada, logo, marca de servicios o
cualquier otro contenido de propiedad o notificacion de derechos de propiedad. Usted reconoce que
no adquiere ningun derecho de propiedad al descargar algun material con derechos de autor de o a
traves de las aplicaciones moviles o el Sitio Web. Si usted hace otro uso del Sitio Web, de las
aplicaciones moviles o de los contenidos, codigos, datos o materiales que ahi se encuentren o que
esten disponibles a traves del Sitio Web, a no ser como se ha estipulado anteriormente, usted
puede violar las leyes de derechos de autor y otras leyes de los Estados Unidos Mexicanos
("Mexico") y de otros paises, asi como las leyes estatales aplicables, y puede ser sujeto a
responsabilidad legal por dicho uso no autorizado. Prestacion de los servicios.
 De los Servicios y Contenido de Terceros.&nbsp;&nbsp;
 Los servicios podran ponerse a disposicion o ser accesible en relacion con servicios y contenido
de terceros (incluida la publicidad) que&nbsp;NANNAPP&nbsp;no controle. usted reconoce que
podran ser de aplicacion diferentes condiciones y politicas de privacidad al uso que haga de dichos
servicios y contenido de terceros. NANNAPP no respalda dichos servicios y contenido de terceros y
en ningun caso NANNAPP sera responsable de cualquier producto o servicio de dichos terceros
proveedores. Adicionalmente, Apple inc., Google, inc., Microsoft Corporation o Blackberry Limited
y/o sus correspondientes subsidiarias o afiliados internacionales seran terceros beneficiarios en
este contrato si usted accede a los servicios utilizando aplicaciones desarrolladas para dispositivos
moviles con sistema Ios, Android, Microsoft Windows, respectivamente. estos terceros beneficiarios
no son parte de este contrato y no son responsables de la prestacion o apoyo de los servicios de
ninguna manera. su acceso a los servicios utilizando estos dispositivos se sujeta a las condiciones
establecidas en las condiciones de servicio de terceros beneficiarios aplicables.
 De la Titularidad.
 Los Servicios y todos los Derechos relativos a estos son y permaneceran de la propiedad de
NANNAPP o de sus licenciantes. ninguna de estas condiciones ni su uso de los servicios le
transfieren u otorgan a usted derecho alguno: (i) sobre o en relacion con los servicios, excepto en
cuanto a la licencia limitada otorgada anteriormente; o bien (ii) a utilizar o mencionar en cualquier
modo a los nombres de empresa, logotipos, nombres de producto y servicio, marcas comerciales o
marcas de servicio de NANNAPP o de sus licenciantes.&nbsp;
 &nbsp;&nbsp;Del Uso de los servicios
 De la Cuenta de Usuario.
 Con el objetivo de usar la mayor parte de los servicios en sus aspectos, usted debera
registrarse, aceptar terminos y condiciones, asi como mantener activa una cuenta de perfil de
usuario personalizada para acceder a los servicios en adelante ("perfil de usuario o cuenta"). para
obtener una cuenta debera tener como minimo 18 anos cumplidos al momento del registro en la
cuenta de usuario, el registro de la cuenta le requiere que comunique a NANNAPP determinada
informacion personal, como su nombre, direccion, numero de telefono movil, asi como por lo menos
un metodo de pago valido (bien una tarjeta de credito). usted se compromete en todo momento a
mantener la informacion en su cuenta de forma exacta, completa y actualizada. si no mantiene la
informacion de cuenta de forma exacta, completa y actualizada, incluso el tener un metodo de pago
invalido o que haya vencido, podra resultar en su imposibilidad para acceder y utilizar los servicios
por parte de NANNAPP. Por justificadas razones no omitimos recalcar que usted es y sera el unico
responsable de toda la actividad que ocurra en su cuenta y se compromete a mantener en todo
momento de forma segura y secreta la informacion de su cuenta incluyendo la contrasena de
seguridad de su cuenta, usted solo puede poseer una cuenta de usuario.
 De los Requisitos y la Conducta del Usuario.
 El servicio no esta disponible para el uso de personas menores de 18 anos. usted no podra
autorizar por ningun medio a terceros a utilizar su cuenta, no podra ceder o transferir de otro modo
su cuenta a cualquier otra persona o entidad. usted acuerda cumplir con todas las leyes aplicables
al utilizar los servicios y solo podra utilizar los servicios para los fines previstos y plenamente
estipulados. en algunos casos, se le podra requerir que facilite un documento de identidad para el
acceso o uso de los servicios, y usted acepta que NANNAPP se reserva el derecho de continuar
con el servicio y bajo su propia responsabilidad si se niega a facilitar el documento de identidad.
 De los Mensajes de Texto.
 Al crear un perfil de usuario o una cuenta, usted acepta que los servicios le puedan enviar
mensajes de texto informativos (SMS) como parte de la actividad comercial normal de su uso de los
servicios. usted podra solicitar la no recepcion de mensajes de texto informativos (SMS) de
NANNAPP en cualquier momento enviando un correo electronico a:
contacto@nannapp.com.mx&nbsp;indicando que no desea recibir mas dichos mensajes, junto con
el numero de telefono del dispositivo movil que recibe los mensajes. usted reconoce y acepta que al
solicitar la no recepcion de mensajes de texto informativos (SMS) podria afectar el uso que usted
haga de los servicios.
 Del contenido que proporcione el Usuario.
 NANNAPP podra, a su sola discrecion, permitirle cuando considere oportuno, que envie,
cargue, publique o de otro modo ponga a disposicion de NANNAPP a traves de los servicios
contenido e informacion de texto, audio y/o visual, incluidos comentarios y opiniones relativos a los
servicios, iniciacion de peticiones de apoyo, asi como presentacion de admisiones para
competiciones y promociones ("contenido de usuario"). todo contenido de usuario facilitado por
usted seguira siendo de su propiedad. no obstante, al proporcionar contenido de usuario a
NANNAPP, usted otorga una licencia mundial, perpetua, irrevocable, transferible, libre de regalias,
otorga el derecho a NANNAPP para sublicenciar, usar, copiar, modificar, crear obras derivadas,
distribuir, exhibir publicamente, explotar de cualquier manera, dicho contenido sin importar el
formato y el canal que se utilice para su distribucion, sin requerir permiso o consentimiento de usted
y sin pago alguno a usted o a cualquier otra persona o entidad.
 Usted declara y garantiza lo siguiente: (i) que es el unico y exclusivo propietario de todo el
contenido de usuario o que tiene todos los derechos, licencias, consentimientos y permisos
necesarios, idoneos o suficientes para otorgar a NANNAPP la licencia al contenido de usuario
establecido anteriormente; y (ii) ni el contenido de usuario ni su presentacion, carga, publicacion o
puesta a disposicion dicho de otro modo el contenido de usuario, ni el uso por parte de NANNAPP
del contenido de usuario como esta aqui permitido, infringiran, malversaran o violaran la propiedad
intelectual o los derechos de propiedad de un tercero o los derechos de publicidad o privacidad o
resultaran en la violacion de cualquier ley o reglamento aplicable.
 Usted acuerda a no proporcionar contenido de usuario que sea difamatorio, calumnioso, odioso,
violento, obsceno, pornografico, ilicito, que atente contra a moral o las buenas costumbres o de otro
modo ofensivo, como determine NANNAPP, a su sola discrecion, tanto si dicho material pueda
estar protegido o no por la ley. NANNAPP podra, a su sola discrecion y en cualquier momento y por
cualquier motivo, sin avisarle previamente, revisar, controlar o eliminar el contenido de usuario, pero
sin estar obligada a ello.
 Del Acceso a la Red y del acceso a los Dispositivos.
 Usted es responsable para obtener el acceso a la red y contar con los datos necesarios para
utilizar poder utilizar los servicios. podran aplicarse las tarifas y tasas de datos y mensajes de su
red movil si usted accede o utiliza los servicios desde un dispositivo inalambrico y usted sera
responsable de dichas tarifas y tasas. usted es responsable de adquirir y actualizar el hardware
compatible o los dispositivos necesarios para acceder y utilizar los servicios y aplicaciones y
cualquier actualizacion de estos. NANNAPP no garantiza en ningun momento que los servicios, o
parte de estos, funcionen en cualquier hardware o dispositivo particular. ademas, los servicios
podran ser objeto de disfunciones o retrasos inherentes al uso de internet y al de las
comunicaciones electronicas.
 8.&nbsp;&nbsp;De la forma de pago
 Usted acepta que al hacer uso de los servicios derivara en cargos por los servicios o bienes que
reciba de un tercer proveedor ("cargos") despues de que haya recibido los servicios u obtenido los
bienes mediante el uso de los servicios, NANNAPP facilitara su pago de los cargos aplicables en
nombre del tercero proveedor como agente de cobro limitado del tercero proveedor. el pago de los
cargos de dicha manera se considerara como el pago efectuado directamente por usted al tercero
proveedor. los cargos incluiran los impuestos aplicables que la ley determine. los cargos por
concepto de pago realizados por usted seran definitivos y no reembolsables, a menos que
NANNAPP determine lo contrario. NANNAPP respondera en consecuencia a cualquier solicitud de
un tercero proveedor para modificar los cargos por un servicio o bien en particular.
 Todos los cargos son pagaderos inmediatamente y el pago se facilitara por NANNAPP
utilizando al metodo de pago preferido indicado en su cuenta, y despues de ello NANNAPP le
enviara un recibo por correo electronico. si se determina que el metodo de pago de su cuenta
principal ha caducado, es invalido o de otro modo no sirve para cobrarle, usted acepta que
NANNAPP, como agente de cobro limitado del tercero proveedor, utilice un metodo de pago
secundario en su cuenta, si estuviera disponible.&nbsp;
 NANNAPP, en cualquier momento y a su sola discrecion, se reserva el derecho de establecer,
eliminar y/o revisar los cargos para alguno o todos los servicios o bienes obtenidos a traves del uso
de los servicios. NANNAPP usara los esfuerzos razonables para informarle de los cargos que
podran aplicarse, siempre y cuando usted sea responsable de los cargos incurridos en su cuenta,
independientemente de que usted conozca dichos cargos o los importes de estos. despues de que
haya recibido los bienes o servicios obtenidos a traves del servicio, tendra la oportunidad de
calificar su experiencia y dejar comentarios adicionales sobre el tercero proveedor.
 9. Renuncias; Limitacion de Responsabilidad; Indemnidad.
 De la Renuncia.
 Los servicios se proporcionan "tal cual" y "como disponibles". NANNAPP renuncia a toda
declaracion y garantia, expresa, implicita o estatutaria, no expresamente establecida en estas
condiciones, incluidas las garantias implicitas de comerciabilidad, idoneidad para un fin particular y
no violacion. ademas, NANNAPP no hace declaracion ni presta garantia alguna relativa a la
fiabilidad, puntualidad, calidad, idoneidad o disponibilidad de los servicios o cualquiera de los
servicios o bienes solicitados a traves del uso de los servicios, o que los servicios no seran
interrumpidos o estaran libres de errores. NANNAPP no garantiza la calidad, idoneidad, seguridad o
habilidad de los terceros proveedores. usted acuerda que todo riesgo derivado de su uso de los
servicios y cualquier servicio o bien solicitado en relacion con aquellos sera unicamente suyo, en la
maxima medida permitida por la ley aplicable.
 Acerca de la Limitacion de Responsabilidad.
 NANNAPP no sera responsable de danos indirectos, incidentales, especiales, ejemplares,
punitivos o emergentes, incluidos el lucro cesante, la perdida de datos, la lesion personal o el dano
a la propiedad, ni de perjuicios relativos, o en relacion con, o de otro modo derivados de cualquier
uso de los servicios, incluso aunque NANNAPP haya sido advertido de la posibilidad de dichos
danos. NANNAPP no sera responsable de cualquier dano, responsabilidad o perdida que deriven
de: (i) su uso o dependencia de los servicios o su incapacidad para acceder o utilizar los servicios;
o (ii) cualquier transaccion o relacion entre usted y cualquier tercero proveedor, aunque NANNAPP
hubiera sido advertido de la posibilidad de dichos danos. NANNAPP no sera responsable del
retraso o de la falta de ejecucion resultante de causas que vayan mas alla del control razonable de
NANNAPP. las limitaciones y la renuncia en este apartado no pretenden limitar la responsabilidad o
alterar sus derechos como consumidor que no puedan excluirse segun la ley aplicable.
 Indemnidad.
 Usted acuerda mantener indemnes y responder frente a NANNAPP y sus consejeros,
directores, empleados y agentes por cualquier reclamacion, demanda, perdida, responsabilidad y
gastos (incluidos los honorarios de abogados) que deriven de: (i) su uso de los servicios o servicios
o bienes obtenidos a traves de su uso de los servicios; (ii) su incumplimiento o violacion de
cualquiera de estas condiciones; (iii) el uso por parte de&nbsp;NANNAPP&nbsp;de su contenido de
usuario; o (iv) su infraccion de los derechos de cualquier tercero, incluidos terceros proveedores.
 10.&nbsp;&nbsp;Legislacion aplicable.
 Salvo que aqui se especifique lo contrario, Usted se somete expresamente a las presentes
condiciones las cuales se regiran e interpretaran exclusivamente en virtud de la legislacion,
Jurisdiccion, Leyes aplicables de los Tribunales de la Ciudad de Mexico, para el caso de cualquier
controversia e Interpretacion que del presente contrato resultare; renunciando en este momento al
fuero que les corresponda por sus domicilios presentes y futuros.
 11. Otras Disposiciones
 las reclamaciones por infraccion de derechos de autor deberan enviarse al agente designado de
NANNAPP. visite la pagina web de NANNAPP en www.nannapp.com.mx&nbsp;para obtener las
direcciones designadas e informacion adicional.
 12. Notificaciones.
 NANNAPP podra notificar por medio de una notificacion general en los servicios, mediante un
correo electronico enviado a su direccion electronica en su cuenta o por comunicacion escrita
enviada a su direccion, segun lo dispuesto en su cuenta. Asimismo usted podra notificar a
NANNAPP por comunicacion escrita a la direccion de correo electronico siguiente:
contacto@nannapp.com.mx o a la siguiente direccion: Juan Salvador Agraz 37 suite 903, Col.
Santa Fe, C.P. 05348, Ciudad de Mexico.
 13. Disposiciones Generales.
 Usted no podra ceder ni transferir estas condiciones, en todo o en parte, sin el consentimiento
previo y por escrito de NANNAPP. usted da su aprobacion a NANNAPP para ceder o transferir
estas condiciones, en todo o en parte, incluido a: (i) una subsidiaria o un afiliado; (ii) un adquirente
del capital, del negocio o de los activos de NANNAPP; o (iii) un sucesor por fusion. no existe entre
usted, NANNAPP o cualquier tercer proveedor una empresa conjunta o relacion de socios, empleo
o agencia como resultado del contrato entre usted y NANNAPP o del uso de los servicios.
 Si cualquier disposicion de estas condiciones se considerara ilegal, nula o inexigible, ya sea en
su totalidad o en parte, de conformidad con cualquier legislacion, dicha disposicion o parte de esta
se considerara que no forma parte de estas condiciones, aunque la legalidad, validez y exigibilidad
del resto de las disposiciones de estas condiciones no se vera afectada. en ese caso, las partes
deberan reemplazar dicha disposicion ilegal, nula o inexigible, en todo o en parte por una
disposicion legal, valida y exigible que tenga, en la medida de lo posible, un efecto similar al que
tenia la disposicion ilegal, nula o inexigible, dados los contenidos y el proposito de estas
condiciones. estas condiciones constituyen el contrato integro y el entendimiento entre las partes en
relacion con el objeto y sustituye y reemplaza a todos los contratos o acuerdos anteriores o
contemporaneos en relacion con dicho objeto. en estas condiciones, las palabras "incluido/a/os/as"
e "incluye/n" significan "incluido, de forma meramente enunciativa"
        </section>
        {/*Main Footer*/}
        <footer className="main-footer">
          <div className="anim-icons">
            <span
              className="icon icon-sparrow wow zoomIn"
              data-wow-delay="400ms"
            />
            <span
              className="icon icon-rainbow-2 wow zoomIn"
              data-wow-delay="800ms"
            />
            <span className="icon icon-star-3" />
            <span className="icon icon-star-3 two" />
            <span className="icon icon-sun" />
            <span
              className="icon icon-plane wow zoomIn"
              data-wow-delay="1200ms"
            />
          </div>
          
          {/*footer upper*/}
          <div className="footer-upper">
            <div className="auto-container">
              <div className="row clearfix">
                {/*Big Column*/}
                <div className="big-column col-xl-5 col-lg-12 col-md-12 col-sm-12">
                  <div className="row clearfix">
                    {/*Footer Column*/}
                    <div className="footer-column col-lg-6 col-md-6 col-sm-12">
                      <div className="footer-widget logo-widget">
                        <div className="logo">
                          {/* <a href="index.html"><img src="images/footer-logo.png" alt="" /></a> */}
                        </div>
                        <div className="text">
                          Las mamás y los papás necesitan el apoyo de una nanny
                          que cuide a sus hijos con calidad
                        </div>
                      </div>
                    </div>
                    {/*Footer Column*/}
                    <div className="footer-column col-lg-5 col-md-6 col-sm-12">
                      <div className="footer-widget contact-widget">
                        <h4 className="widget-title">Contact</h4>
                        <div className="widget-content">
                          <ul className="contact-info">
                            <li>
                              <a href="mailto:contacto@nannapp.com.mx">
                                <span className="fa fa-envelope" />
                                contacto@nannapp.com.mx
                              </a>
                            </li>
                            <li>
                              <span className="fa fa-map" /> Santa Fe, Ciudad de
                              México{" "}
                            </li>
                          </ul>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                {/*Big Column*/}
                <div className="big-column col-xl-7 col-lg-12 col-md-12 col-sm-12">
                  <div className="row clearfix">
                    
                    <div className="footer-column col-xl-3 col-lg-3 col-md-6 col-sm-4">
                      <div className="footer-widget links-widget">
                        <h4 className="widget-title">Links</h4>
                        <div className="widget-content">
                        <ul className="list">
                            <li>
                              <Link to="/terms-and-conditions">Términos y Condiciones</Link>
                            </li>
                            <li>
                              <Link to="/privacy-notice">Aviso de Privacidad</Link>
                            </li>
                            <li>
                              <Link to="/family-policies">Políticas Familiares</Link>
                            </li>
                            <li>
                              <Link to="/nanny-policies">Políticas Nanny</Link>
                            </li>
                          </ul>
                        </div>
                      </div>
                    </div>
                    
                    {/*
                    <div className="footer-column col-xl-5 col-lg-6 col-md-12 col-sm-12">
                      <div className="footer-widget newsletter-widget">
                        <h4 className="widget-title">Newsletter</h4>
                        <div className="newsletter-form">
                          <form method="post" action="contact.html">
                            <div className="form-group">
                              <button
                                type="submit"
                                className="theme-btn btn-style-one"
                              >
                                Subscribe
                              </button>
                            </div>
                          </form>
                        </div>
                      </div>
                    </div>
                    */}
                  </div>
                </div>
              </div>
            </div>
          </div>
          {/*Footer Bottom*/}
          <div className="footer-bottom">
            <div className="auto-container">
              <ul className="social-icon-colored">
                <li>
                  <a href="https://www.instagram.com/nannappmexico/">
                    <span className="fab fa-instagram" />
                  </a>
                </li>
                
                  
              </ul>
              <div className="copyright"> Copyrights © Nannapp</div>
            </div>
          </div>
        </footer>
        {/*End Main Footer*/}
      </div>
    </div>
  );
}
