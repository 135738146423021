import { useState } from 'react'
import { auth, messaging } from '../firebase/config'
import { getToken } from 'firebase/messaging'   
import { doc, setDoc } from 'firebase/firestore'
import { db } from '../firebase/config'
import { useAuthContext } from './useAuthContext'
import { signInWithEmailAndPassword } from 'firebase/auth'

export const useLogin = () => {
    const [error, setError] = useState(null)
    const { dispatch } = useAuthContext()

    const login = async (email, password) => {
        setError(null)

        try {
            const res = await signInWithEmailAndPassword(auth, email, password)
            const userClaims = await res.user.getIdTokenResult()

            try {
                // Request permission and get the messaging token
                const currentToken = await getToken(messaging, { vapidKey: 'BO5ZvpYIOT0DoVkzxu_dSpReY8OUmncNFeE0VRSKByhJXfq1GoPMILgmkSEAmAxwUCENFiQkfYaBosmXwLMMW4U' })

                if (currentToken) {
                    console.log('Current token for client: ', currentToken);

                    // Check the role and set the collection accordingly
                    let collection = '';
                    if (userClaims.claims.role === 'user') {
                      collection = 'users';
                    } else if (userClaims.claims.role === 'nanny') {
                      collection = 'nanny';
                    } else {
                      console.error('Invalid role: ', userClaims.claims.role);
                      return;
                    }

                    // Save the token to Firestore database with user.uid
                    await setDoc(doc(db, collection, res.user.uid), { fcmToken: currentToken }, { merge: true });
                    console.log('Token saved to Firestore');
                } else {
                    console.log('No registration token available. Request permission to generate one.');
                }
            } catch(err) {
                console.log('Messaging error', err)
            }

            console.log('User claims', userClaims.claims)
            const user = { ...res.user, claims: userClaims.claims }
            dispatch({ type: 'LOGIN', payload: user })
            console.log('User login success', res.user)
        } catch (err) {
            console.log('User login error', err)
            const friendlyMessage = getFriendlyErrorMessage(err.code);
            setError(friendlyMessage)
        }
    }

    return { error, login }
}

function getFriendlyErrorMessage(errorCode) {
    switch (errorCode) {
        case 'auth/email-already-in-use':
            return 'User already exists!';
        case 'auth/user-not-found':
            return 'No user with this email found!';
        case 'auth/wrong-password':
            return 'Incorrect password!';
        case 'auth/invalid-email':
            return 'Invalid email address!';
        case 'auth/user-disabled':
            return 'User account has been disabled!';
        default:
            return 'An unknown error occurred. Please try again.';
    }
}
