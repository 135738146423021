/* eslint-disable no-unused-vars */
import React, { useState } from "react";
import "./onboardingStyles.css";
import { doc, setDoc } from "firebase/firestore";
import { db } from "../../firebase/config";

import { useAuthContext } from "../../hooks/useAuthContext";

//Functions Firebase
import { functions } from "../../firebase/config";
import { httpsCallable, getFunctions } from "firebase/functions";

import HouseOutlinedIcon from "@mui/icons-material/HouseOutlined";
import AddCircleOutlineIcon from "@mui/icons-material/AddCircleOutline";
import DeleteIcon from "@mui/icons-material/Delete";

import estadosYCiudades from "./estadosYCiudades.json";
import { useNavigate } from "react-router-dom";
import { CircularProgress } from "@mui/material";
//Define https callable function
const functionsInstance = getFunctions();
const completeOnboarding = httpsCallable(
  functionsInstance,
  'completeOnboarding'
);

function Onboarding() {
  const { user, refreshUser } = useAuthContext();

  const [street, setStreet] = useState("");
  const [city, setCity] = useState("");
  const [state, setState] = useState("");
  const [zipCode, setZipCode] = useState("");
  const [zipCodeError, setZipCodeError] = useState("");
  const [neighborhood, setNeighborhood] = useState("");
  const [step, setStep] = useState(1);
  const [peopleError, setPeopleError] = useState("");

  const [selectedState, setSelectedState] = useState("");
  const [selectedCity, setSelectedCity] = useState("");
  const [availableCities, setAvailableCities] = useState([]);
  const [isSubmitting, setIsSubmitting] = useState(false);

  const navigate = useNavigate();

  const handleSelectedStateChange = (e) => {
    const selected = e.target.value;
    setSelectedState(selected);
    setAvailableCities(estadosYCiudades[selected] || []);
    setSelectedCity(""); // Reset city when state changes
  };

  const handleSelectedCityChange = (e) => {
    setSelectedCity(e.target.value);
  };

  const handleStreetChange = (event) => {
    setStreet(event.target.value);
  };

  const handleCityChange = (event) => {
    setCity(event.target.value);
  };

  const handleStateChange = (event) => {
    setState(event.target.value);
  };

  const handleZipCodeChange = (event) => {
    setZipCode(event.target.value);
  };
  const handleNeighborhoodChange = (event) => {
    setNeighborhood(event.target.value);
  };

  const validateZipCode = (value) => {
    const isValid = /^\d{5}$/.test(value);
    setZipCodeError(isValid ? "" : "Porfavor ingresa un código postal válido");
  };

  const [people, setPeople] = useState([{ name: "", birthdate: "" }]);
  const [error, setError] = useState("");

  const handleNameChange = (index, event) => {
    const newPeople = [...people];
    newPeople[index].name = event.target.value;
    setPeople(newPeople);
  };

  const handleBirthdateChange = (index, event) => {
    const newPeople = [...people];
    newPeople[index].birthdate = event.target.value;
    setPeople(newPeople);
  };

  const handleAddPerson = () => {
    if (people.length >= 3) {
      setError("You can only add up to 3 people.");
      return;
    }
    const newPeople = [...people, { name: "", birthdate: "" }];
    setPeople(newPeople);
  };

  const handleRemovePerson = (index) => {
    const newPeople = [...people];
    newPeople.splice(index, 1);
    setPeople(newPeople);
  };

  const handleSubmit = async () => {
    console.log("Submitting onboarding form");
    setIsSubmitting(true);
    const userDocRef = doc(db, "users", user.uid);
    const firstName = user.displayName.split(" ")[0];
    const lastName = user.displayName.split(" ")[1];

    //TODO: Handle lastname edge cases
    //const splitName = user.displayName.split(" ");
    //const firstName = splitName[0];
    //const lastName = splitName.slice(1).join(" ");


    setDoc(userDocRef, {
      firstName: firstName,
      lastName: lastName,
      email: user.email,
      phoneNumber:user.claims?.phoneNumber,
      addresses: [
        {
          street: street,
          city: selectedCity,
          state: selectedState,
          zipCode: zipCode,
          neighborhood: neighborhood,
        },
      ],
      kids: people,
    });
    await completeOnboarding({ uid: user.uid });
    refreshUser();
    console.log("User completed onboarding");
    setIsSubmitting(false);
    navigate("/webapp");
  };

  const handleNext = () => {
    if (
      step === 1 &&
      street !== "" &&
      setCity !== "" &&
      setState !== "" &&
      zipCodeError === ""
    ) {
      setStep(2);
    } else if (step === 3) {
      // Check if at least one person is added
      if (people.length === 0) {
        setPeopleError("Please add at least one person.");
        return;
      }

      // Check if all added people have name and birth date filled
      for (let i = 0; i < people.length; i++) {
        const person = people[i];
        if (!person.name || !person.birthdate) {
          setPeopleError(
            "Porfavor ingresa el nombre y fecha de nacimiento de todos tus pequeños."
          );
          return;
        }
      }

      handleSubmit();
    }
  };

  return (
    <div className="onboard">
      <div className="center-component">
        <div className="sec-title light text-center">
          {" "}
          <h2 style={{ fontSize: "25px" }}>
            Completa tu información para concluir tu registro
          </h2>
        </div>

        {step === 1 && (
          <>
            <div className="circle">
              <HouseOutlinedIcon style={{ fontSize: 50, color: "white" }} />
            </div>
            <p style={{ color: "white" }}>Domicilio</p>
            <div>
              <div className="form-group input-styles">
                <fieldset>
                  <input
                    id="street"
                    placeholder="Calle y Número Ext/Int"
                    value={street}
                    onChange={handleStreetChange}
                    className="form-control"
                  />
                </fieldset>
              </div>
              <div className="form-group input-styles">
                <fieldset id="">
                  <select
                    name="state"
                    value={selectedState}
                    onChange={handleSelectedStateChange}
                    className="form-control"
                    required
                  >
                    <option value="" disabled>
                      Selecciona un estado
                    </option>
                    {Object.keys(estadosYCiudades).map((estado) => (
                      <option key={estado} value={estado}>
                        {estado}
                      </option>
                    ))}
                  </select>
                </fieldset>
              </div>
              <div className="form-group input-styles">
                <fieldset>
                  <select
                    name="city"
                    value={selectedCity}
                    onChange={handleSelectedCityChange}
                    className="form-control"
                    required
                  >
                    <option value="" disabled>
                      Selecciona una municipio
                    </option>
                    {availableCities.map((ciudad) => (
                      <option key={ciudad} value={ciudad}>
                        {ciudad}
                      </option>
                    ))}
                  </select>
                </fieldset>
              </div>
              <div className="form-group input-styles">
                <fieldset>
                  <input
                    id="neighborhood"
                    placeholder="Colonia"
                    value={neighborhood}
                    onChange={handleNeighborhoodChange}
                    className="form-control"
                  />
                </fieldset>
              </div>
              
              <div className="form-group input-styles">
                <fieldset>
                  <input
                    id="zipCode"
                    placeholder="Código Postal"
                    value={zipCode}
                    onChange={handleZipCodeChange}
                    onBlur={() => validateZipCode(zipCode)}
                    className={`form-control ${
                      zipCodeError ? "input-error" : ""
                    }`}
                  />
                  {zipCodeError && (
                    <p className="error-message">{zipCodeError}</p>
                  )}
                </fieldset>
              </div>
              <div className="text-center" style={{ marginTop: "15px" }}>
                <button
                  onClick={handleNext}
                  className="theme-btn btn-style-one login-button"
                  style={{ padding: "10px 20px" }}
                >
                  Siguiente
                </button>
              </div>
            </div>
          </>
        )}
        {step === 2 && (
          <>
            <h3
              style={{
                color: "white",
                width: "80%",
                textAlign: "center",
                fontSize: "20px",
              }}
            >
              Elige para quién solicitas el cuidado:
            </h3>
            <button
              onClick={() => {
                setStep(3);
              }}
              className="theme-btn btn-style-one login-button"
              style={{ padding: "20px 15px", marginTop: "15px" }}
            >
              <span
                className="icon flaticon-teddy-bear-1"
                style={{ paddingRight: "5px", fontSize: "27px" }}
              ></span>
              <span>Bebés/niñ@s</span>
            </button>
            <button
              onClick={() => {
                handleSubmit();
              }}
              className="theme-btn btn-style-one login-button"
              style={{ padding: "20px 15px", marginTop: "15px" }}
            >
              <span
                className="icon flaticon-parents"
                style={{ paddingRight: "5px", fontSize: "30px" }}
              ></span>
              <span>Adulto mayor</span>
            </button>
            <button
              onClick={() => {
                setStep(3);
              }}
              className="theme-btn btn-style-one login-button"
              style={{ padding: "20px 15px", marginTop: "15px" }}
            >
              <span>Ambos</span>
            </button>
          </>
        )}
        {step === 3 && (
          <>
            <div className="circle">
              <i
                className="icon flaticon-teddy-bear-1"
                style={{ fontSize: "400%" }}
              ></i>
            </div>
            <p style={{ color: "white" }}>Bebés/niñ@s</p>
            <p
              style={{
                color: "#ffae00",
                textAlign: "center",
                width: "80%",
                fontSize: "15px",
              }}
            >
              {" "}
              Registra a tu(s) hij@(s) para que estén protegidos con nuestro
              seguro contra accidentes.
            </p>
            <div>
              {people.map((person, index) => (
                <div className="form-group input-styles" key={index}>
                  <div className="form-group input-styles">
                    <fieldset>
                      <input
                        id={`name-${index}`}
                        placeholder="Nombre Completo"
                        value={people.name}
                        onChange={(event) => handleNameChange(index, event)}
                        className="form-control"
                      />
                    </fieldset>
                  </div>
                  <div className="form-group input-styles">
                    <fieldset>
                      <label
                        htmlFor={`birthdate-${index}`}
                        style={{ color: "white" }}
                      >
                        Fecha de Nacimiento
                      </label>
                      <input
                        id={`birthdate-${index}`}
                        label="Fecha de Nacimiento"
                        type="date"
                        value={people.birthdate}
                        onChange={(event) =>
                          handleBirthdateChange(index, event)
                        }
                        InputLabelProps={{
                          shrink: true,
                        }}
                        className="form-control"
                      />
                    </fieldset>
                  </div>
                  {index > 0 && (
                    <div
                      className="form-group input-styles delete-field"
                      onClick={() => handleRemovePerson(index)}
                    >
                      <DeleteIcon />
                    </div>
                  )}
                </div>
              ))}
              {peopleError && (
                <p
                  className="error-message"
                  style={{ color: "red", width: "75%", textAlign: "center" }}
                >
                  {peopleError}
                </p>
              )}

              <div
                className="text-center"
                style={{
                  marginTop: "15px",
                  display: people.length >= 3 ? "none" : "block",
                }}
              >
                <button
                  onClick={handleAddPerson}
                  className="theme-btn btn-style-one login-button"
                  style={{ padding: "10px 20px" }}
                >
                  <AddCircleOutlineIcon />
                  <span>Agregar hij@(s)</span>
                </button>
                <div>
                  {isSubmitting ? (
                    <CircularProgress style={{ color: "pink", marginTop: "10px" }} />
                  ) : (
                    <button
                      onClick={handleNext}
                      className="theme-btn btn-style-one login-button"
                      style={{ padding: "10px 20px", marginTop: "15px" }}
                      disabled={isSubmitting} // Disable the button while submitting
                    >
                      Finalizar
                    </button>
                  )}
                </div>
              </div>
            </div>
          </>
        )}
      </div>
    </div>
  );
}

export default Onboarding;
