/* eslint-disable no-unused-vars */
import {useState} from 'react';
import { useAuthContext } from '../hooks/useAuthContext';

//Auth Firebase
import {auth} from '../firebase/config';
import { createUserWithEmailAndPassword, updateProfile} from 'firebase/auth';

//Functions Firebase
import {functions} from '../firebase/config';
import { httpsCallable , getFunctions} from 'firebase/functions';

//Define https callable function
const functionsInstance = getFunctions();
const setCustomClaim = httpsCallable(functionsInstance, 'addUserRole');

const sendEmail = httpsCallable(functionsInstance, "sendEmails");

export const useSignup = () => {
    const [error, setError] = useState(null);
    const { dispatch } = useAuthContext();
  
    const signup = async (email, password, name, phone) => {
      setError(null);

      try {
          const res = await createUserWithEmailAndPassword(auth, email, password);
          console.log('User signup success', res.user);
  
          await updateProfile(auth.currentUser, { displayName: name });
          console.log('User profile updated');
  
          await setCustomClaim({ uid: res.user.uid , phoneNumber: phone});
          console.log('User role added');
  
          const customClaims = await res.user.getIdTokenResult(true);
          console.log('customClaims', customClaims);
  
          const user = { ...res.user, claims: customClaims.claims };
          dispatch({ type: 'LOGIN', payload: user });
          // Send email
          sendEmail({email:email,templateId:'d-c56d4fcbb4a8432fa0e251bd2d3c0a36'})
          return true;  // Explicitly return true for success
      } catch (err) {
        console.log('User signup error', err);
        const friendlyMessage = getFriendlySignupErrorMessage(err.code);
        setError(friendlyMessage);
        return false;
      }
    };
  
    return { error, signup };
  };
  
  function getFriendlySignupErrorMessage(errorCode) {
    switch (errorCode) {
        case 'auth/email-already-in-use':
            return 'Este correo electrónico ya está registrado. Por favor, inicie sesión en su lugar.';
        case 'auth/invalid-email':
            return 'Por favor, proporcione una dirección de correo electrónico válida.';
        case 'auth/operation-not-allowed':
            return 'El registro no está permitido actualmente. Por favor, inténtelo de nuevo más tarde.';
        case 'auth/weak-password':
            return 'La contraseña es demasiado débil. Por favor, elija una más segura.';
        default:
            return 'Ocurrió un error desconocido durante el registro. Por favor, inténtelo de nuevo.';
    }
}
