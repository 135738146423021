/* eslint-disable no-unused-vars */
/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useEffect, useState } from "react";
import "bootstrap/dist/css/bootstrap.css";
import "bootstrap/dist/js/bootstrap.js";
import "./css/style.css";
import { AppBar, Box, Toolbar, Typography, Button } from "@mui/material";
import EmailIcon from "@mui/icons-material/Email";
import image1 from "./images/image-1.JPG"


import MenuNavbar from "./components/MenuNavbar";
import SignUpDialog from "./components/SignUpDialog";
import LoginDialog from "./components/LoginDialog";
import ResetPasswordDialog from "./components/ResetPasswordDialog";
import { Link } from "react-router-dom";
export default function WantToBeNanny() {
  const [imgUrl, setImgUrl] = useState("");
  const [width, setWidth] = useState(window.innerWidth);

  const [openSignUp, setOpenSignUp] = useState(false);
  const [openLogin, setOpenLogin] = useState(false);

  const [openResetPassword, setOpenResetPassword] = useState(false);
  const handleResetPasswordClick = () => {
    setOpenLogin(false);
    setOpenResetPassword(true);
  };

  useEffect(() => {
    const mediaQuery = window.matchMedia("(max-width: 768px)");
    const handleMediaQueryChange = (mediaQuery) => {
      if (mediaQuery.matches) {
        setImgUrl(require("./images/Banner_Slider_p3.png")); /*Mobile Slider*/
      } else {
        setImgUrl(require("./images/Banner_Slider_p.png")); /*Desktop Slider*/
      }
    };

    handleMediaQueryChange(mediaQuery);
    mediaQuery.addListener(handleMediaQueryChange);

    return () => mediaQuery.removeListener(handleMediaQueryChange);
  }, []);

  useEffect(() => {
    const handleResize = () => setWidth(window.innerWidth);
    window.addEventListener("resize", handleResize);
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  return (
    <div>
      <div className="page-wrapper">
        <header className="main-header header-style-two">
          {/* Header Top */}
          <div className="header-top">
            <Box sx={{ flexGrow: 1 }}>
              <AppBar position="static" sx={{ backgroundColor: "#393d72" }}>
                <Toolbar>
                  <Typography
                    variant="h6"
                    component="div"
                    sx={{ flexGrow: 1, textAlign: "left" }}
                  >
                  
                    <Button
                      color="inherit"
                      startIcon={
                        <EmailIcon style={{ color: "rgb(255,174,0)" }} />
                      }
                      href="mailto:nannappcompany@gmail.com"
                    >
                      
                    </Button>
                  </Typography>
                  <Typography
                    variant="h6"
                    component="div"
                    sx={{ flexGrow: 1, textAlign: "right" }}
                  >
                    <Button color="inherit" onClick={() => setOpenSignUp(true)}>
                      Crear Cuenta
                    </Button>
                    <SignUpDialog
                      open={openSignUp}
                      onClose={() => setOpenSignUp(false)}
                    />
                    <Button color="inherit" onClick={() => setOpenLogin(true)}>
                      Iniciar Sesión
                    </Button>
                    <LoginDialog
                      open={openLogin}
                      onClose={() => setOpenLogin(false)}
                      onResetPasswordClick={handleResetPasswordClick} // Added this line
                    />
                    <ResetPasswordDialog
                      open={openResetPassword}
                      onClose={() => setOpenResetPassword(false)}
                    />
                  </Typography>
                </Toolbar>
              </AppBar>
            </Box>
          </div>
          {/* Header Top */}
          {/* Header Upper */}
          <div className="header-lower">
            <MenuNavbar />
          </div>
          {/*End Header Upper*/}
          {/* Sticky Header  */}
          <div className="sticky-header">
            <div className="auto-container clearfix">
              {/*Logo*/}
              <div className="logo pull-left">
                <a href="index.html" title>
                  <img src="images/logo.png" alt="" title />
                </a>
              </div>
              {/*Right Col*/}
              <div className="pull-right">
                {/* Main Menu */}
                <nav className="main-menu">
                  <div className="navbar-collapse show collapse clearfix">
                    <ul className="navigation clearfix">
                      <li className="current dropdown">
                      <Link to='/landing'>Home</Link>
                        <ul>
                          <li>
                            <a href="index.html">Home page 01</a>
                          </li>
                          <li>
                            <a href="index-2.html">Home page 02</a>
                          </li>
                          <li className="dropdown">
                            <a href="index.html">Header Styles</a>
                            <ul>
                              <li>
                                <a href="index.html">Header Style One</a>
                              </li>
                              <li>
                                <a href="index-2.html">Header Style Two</a>
                              </li>
                            </ul>
                          </li>
                        </ul>
                      </li>
                      {/* <li>
                        <a href="about.html">About Us</a>
                      </li>
                      <li className="dropdown">
                        <a href="events.html">Events</a>
                        <ul>
                          <li>
                            <a href="events.html">Events</a>
                          </li>
                          <li>
                            <a href="event-detail.html">Event Detail</a>
                          </li>
                        </ul>
                      </li>
                      <li className="dropdown">
                        <a href="programs.html">Programs</a>
                        <ul>
                          <li>
                            <a href="numbers-matching.html">Numbers Matching</a>
                          </li>
                          <li>
                            <a href="paper-plates-art.html">Paper Plates Art</a>
                          </li>
                          <li>
                            <a href="create-cartoon-egg.html">
                              Create Egg Cartoon
                            </a>
                          </li>
                          <li>
                            <a href="cooking-fun-cake.html">Cooking Fun Cake</a>
                          </li>
                          <li>
                            <a href="super-colors-game.html">
                              Super Colors Game
                            </a>
                          </li>
                          <li>
                            <a href="learning-disciplines.html">
                              Learning Disciplines
                            </a>
                          </li>
                        </ul>
                      </li>
                      <li>
                        <a href="gallery.html">Gallery</a>
                      </li>
                      <li className="dropdown">
                        <a href="news.html">Our News</a>
                        <ul>
                          <li>
                            <a href="news.html">Our News</a>
                          </li>
                          <li>
                            <a href="news-single.html">News Single</a>
                          </li>
                        </ul>
                      </li>
                      <li>
                        <a href="contact.html">Contact</a>
                      </li> */}
                    </ul>
                  </div>
                </nav>
                {/* Main Menu End*/}
              </div>
            </div>
          </div>
          {/* End Sticky Menu */}
        </header>
        {/* End Main Header */}
        {/*Page Title */}
        <section className="page-title" style={{backgroundImage: 'url(images/background/1.jpg)'}}>
    <div className="auto-container">
        <div className="inner-container clearfix">
            
            <h1>Quiero ser Nanny</h1>
        </div>
    </div>
</section>
{/* End Page Title */}
        {/*Requisitos de Admisión Section */}
        <section className="program-detail">
            <div className="auto-container">
                <div className="row">
                    {/* Content Column */}
                    <div className="content-column col-lg-6 col-md-12 col-sm-12 order-2">
                        <div className="inner-column">
                            <h3>Requisitos de Admisión</h3>
                            <ul className="program-info" style={{ listStyleType: 'none', paddingLeft: 0 }}>
                                {[
                                    'Estudios o experiencia en el cuidado de bebés, niños o adultos mayores.',
                                    'CV y una foto de medio cuerpo',
                                    'INE (foto o copia por ambos lados)',
                                    'Cédula profesional o comprobante de estudios',
                                    'Comprobante de domicilio',
                                    'Dos cartas de recomendación o referencias laborales.',
                                    'Estudio de grafología se realiza una vez que se revisan los documentos enviados y se envía comprobante de pago a nuestra cuenta, junto con el escrito a mano en una hoja en blanco, que debe decir la fecha, por qué quieres ser parte de Nannapp, nombre completo y firma.',
                                    'Talla de filipina para el uniforme, una vez admitida.'
                                ].map(text => (
                                    <li style={{ display: 'flex', alignItems: 'center', marginBottom: '10px' }}>
                                        <figure style={{ width: '10px', height: '10px', backgroundColor: 'yellow', borderRadius: '50%', marginRight: '10px' }}></figure>
                                        <span>{text}</span>
                                    </li>
                                ))}
                            </ul>
                            <div className="text">Enviar a <a href="mailto:contacto@nannapp.com.mx">contacto@nannapp.com.mx</a></div>
                        </div>
                    </div>

                    {/* Image Column */}
                    <div className="image-column col-lg-6 col-md-12 col-sm-12">
                        <div className="image-box wow fadeIn animated" style={{ visibility: "visible", animationName: "fadeIn" }}>
                            <figure className="image"><a href="images/resource/program-detail.jpg" className="lightbox-image"><img src={image1} alt="" /></a></figure>
                        </div>
                    </div>
                </div>
            </div>
        </section>

        {/*Main Footer*/}
        <footer className="main-footer">
          <div className="anim-icons">
            <span
              className="icon icon-sparrow wow zoomIn"
              data-wow-delay="400ms"
            />
            <span
              className="icon icon-rainbow-2 wow zoomIn"
              data-wow-delay="800ms"
            />
            <span className="icon icon-star-3" />
            <span className="icon icon-star-3 two" />
            <span className="icon icon-sun" />
            <span
              className="icon icon-plane wow zoomIn"
              data-wow-delay="1200ms"
            />
          </div>
          
          {/*footer upper*/}
          <div className="footer-upper">
            <div className="auto-container">
              <div className="row clearfix">
                {/*Big Column*/}
                <div className="big-column col-xl-5 col-lg-12 col-md-12 col-sm-12">
                  <div className="row clearfix">
                    {/*Footer Column*/}
                    <div className="footer-column col-lg-6 col-md-6 col-sm-12">
                      <div className="footer-widget logo-widget">
                        <div className="logo">
                          {/* <a href="index.html"><img src="images/footer-logo.png" alt="" /></a> */}
                        </div>
                        <div className="text">
                          Las mamás y los papás necesitan el apoyo de una nanny
                          que cuide a sus hijos con calidad
                        </div>
                      </div>
                    </div>
                    {/*Footer Column*/}
                    <div className="footer-column col-lg-5 col-md-6 col-sm-12">
                      <div className="footer-widget contact-widget">
                        <h4 className="widget-title">Contact</h4>
                        <div className="widget-content">
                          <ul className="contact-info">
                            <li>
                              <a href="mailto:contacto@nannapp.com.mx">
                                <span className="fa fa-envelope" />
                                contacto@nannapp.com.mx
                              </a>
                            </li>
                            <li>
                              <span className="fa fa-map" /> Santa Fe, Ciudad de
                              México{" "}
                            </li>
                          </ul>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                {/*Big Column*/}
                <div className="big-column col-xl-7 col-lg-12 col-md-12 col-sm-12">
                  <div className="row clearfix">
                    
                    <div className="footer-column col-xl-3 col-lg-3 col-md-6 col-sm-4">
                      <div className="footer-widget links-widget">
                        <h4 className="widget-title">Links</h4>
                        <div className="widget-content">
                        <ul className="list">
                            <li>
                              <Link to="/terms-and-conditions">Términos y Condiciones</Link>
                            </li>
                            <li>
                              <Link to="/privacy-notice">Aviso de Privacidad</Link>
                            </li>
                            <li>
                              <Link to="/family-policies">Políticas Familiares</Link>
                            </li>
                            <li>
                              <Link to="/nanny-policies">Políticas Nanny</Link>
                            </li>
                          </ul>
                        </div>
                      </div>
                    </div>
                    
                    {/*
                    <div className="footer-column col-xl-5 col-lg-6 col-md-12 col-sm-12">
                      <div className="footer-widget newsletter-widget">
                        <h4 className="widget-title">Newsletter</h4>
                        <div className="newsletter-form">
                          <form method="post" action="contact.html">
                            <div className="form-group">
                              <button
                                type="submit"
                                className="theme-btn btn-style-one"
                              >
                                Subscribe
                              </button>
                            </div>
                          </form>
                        </div>
                      </div>
                    </div>
                    */}
                  </div>
                </div>
              </div>
            </div>
          </div>
          {/*Footer Bottom*/}
          <div className="footer-bottom">
            <div className="auto-container">
              <ul className="social-icon-colored">
                <li>
                  <a href="https://www.instagram.com/nannappmexico/">
                    <span className="fab fa-instagram" />
                  </a>
                </li>
                
                  
              </ul>
              <div className="copyright"> Copyrights © Nannapp</div>
            </div>
          </div>
        </footer>
        {/*End Main Footer*/}
      </div>
    </div>
  );
}
