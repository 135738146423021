import React, { useState } from "react";
import Grid from "@mui/material/Grid";
import CustomBottomVar from "./components/CustomBottomVar.js";
import TopBar from "./components/TopBar.js";
import NotificationBlock from "./components/NotificationBlock.js";
import { Box , Typography} from "@mui/material";
import MainSearchLogic from "./components/MainSearchLogic.js";
import ServiceList from "./components/ServiceList.js";
import NoChats from '@mui/icons-material/SpeakerNotesOff';

export default function WebApp() {
  // State to keep track of the selected button in CustomBottomVar
  const [selectedButton, setSelectedButton] = useState(1);

  // Function to handle button selection
  const handleButtonClick = (label) => {
    setSelectedButton(label);
  };


  return (
    <Box
      display="block"
      position="absolute"
      top={0}
      left={0}
      bottom={0}
      right={0}
      minHeight="100vh"
      minWidth="100vw"
      style={{ backgroundColor: "#f9f5f0" }}
    >
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <TopBar />
        </Grid>
        {/* Start CustomBottom Var conditional rendering */}
        {selectedButton === 2 && (
          <Grid item xs={11}>
           <Box
            display="flex"
            flexDirection="column"
            alignItems="center"
            justifyContent="center"
            height="80vh"
          >
            <NoChats fontSize="large" />
            <Typography variant="h6" mt={2}>
              Aún no tienes ninguna conversación
            </Typography>
          </Box>
        </Grid>
        )}
        {selectedButton === 1 && (
        <><Grid item xs={11}>
            <div>
              <h1 style={{ marginLeft: "20px", color: "rgb(57, 61, 114)" }}>
                {" "}
                Tu nannie de la guarda{" "}
              </h1>
              <h5 style={{ marginLeft: "20px", color: "rgb(255, 72, 128)" }}>
                {" "}
                Cerca de ti
              </h5>
              <MainSearchLogic />


            </div>
          </Grid><Grid item xs={11} style={{ marginTop: '50px' }}>
              <NotificationBlock />
            </Grid></>
        )}
        {selectedButton === 0 && (
          <Grid item xs={11}>
          <div>
              <h1 style={{ marginLeft: "20px", color: "rgb(57, 61, 114)" }}>
                Tus cuidados
              </h1>
              <ServiceList />
            </div>
        </Grid>
        )}
         {/* End CustomBottom Var conditional rendering */}
        <Grid item xs={12}>
          <CustomBottomVar onButtonClick={handleButtonClick} />
        </Grid>
      </Grid>
    </Box>
  );
}
