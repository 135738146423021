import { BottomNavigation, BottomNavigationAction} from '@mui/material';
import ChatIcon from '@mui/icons-material/Chat';
import CalendarMonthIcon from '@mui/icons-material/CalendarMonth';


import Logo_Bar from '../assets/img/Logo_wl.png'

export default function MyBottomNavigation({onButtonClick}) {
  // Function to handle button clicks
  const handleClick = (event, newValue) => {
      onButtonClick(newValue);
      console.log(newValue);
  };

  return (
      <BottomNavigation style={{ backgroundColor: '#393d72', position: 'fixed', bottom: 0,left: 0, right: 0 , width: '100%' }} onChange={handleClick}>
          <BottomNavigationAction label="services" icon={<CalendarMonthIcon/>}  />
          <BottomNavigationAction label="start" icon={<img src={Logo_Bar} alt="logo" style={{paddingBottom: '30px' }}/>} />
          <BottomNavigationAction label="chat" icon={<ChatIcon />} />
      </BottomNavigation>
  );
}
