import React from "react";
import { useNavigate, useParams } from "react-router-dom";
import {
  AppBar,
  Toolbar,
  Typography,
  Button,
  CardContent,
  Card,
  Grid,
  Divider,
  Accordion,
  AccordionSummary,
  AccordionDetails,
  Avatar,
  Chip,
} from "@mui/material";
import { IconButton } from "@mui/material";
import ArrowBackIosIcon from "@mui/icons-material/ArrowBackIos";
import HeadsetMicIcon from "@mui/icons-material/HeadsetMic";
import { useDocument } from "../../hooks/useDocument";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import { useAuthContext } from "../../hooks/useAuthContext";
const ServiceView = () => {
  const {user} = useAuthContext();
  const navigate = useNavigate();

  const { serviceId, firstView } = useParams();

  const { document: serviceData } = useDocument("private_services", serviceId);
  const { document: profile } = useDocument("nanny", serviceData?.serviceAssignedTo);
  return (
    <div
      style={{ backgroundColor: "#f9f5f0", overflow: "auto",minHeight: "100vh"}}
    >
      <AppBar position="static" sx={{ backgroundColor: "#393d72" }}>
        <Toolbar
          sx={{
            display: "flex",
            justifyContent: "space-between",
          }}
        >
          <IconButton
            color="inherit"
            aria-label="back"
            sx={{
              borderRadius: "50%",
              backgroundColor: "#ffae00",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
            onClick={() => navigate('/')}
          >
            <ArrowBackIosIcon />
          </IconButton>
          <Button
            startIcon={<HeadsetMicIcon />}
            color="inherit"
            aria-label="help"
            sx={{
              backgroundColor: "#ffae00",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <Typography
              variant="body1"
              style={{ color: "white", fontFamily: "Fredoka One" }}
            >
              Ayuda
            </Typography>
          </Button>
        </Toolbar>
      </AppBar>
      <Grid container spacing={2} >
        <Grid
          item
          xs={12}
          justifyContent="center"
          display="flex"
          flexDirection="column"
          alignItems="center"
          sx={{ margin: "10px" }}
        >
          

          {firstView === "true" ? (
            <Card
              sx={{
                width: "100%",
                maxWidth: 600,
                textAlign: "center",
                boxShadow: "none",
                borderRadius: "10px",
                mb: 1,
                backgroundColor: "rgba(255, 192, 203, 0.3)",
              }}
            >
              <CardContent>
                <Typography
                  variant="h5"
                  component="div"
                  style={{ color: "#393d72", fontFamily: "Fredoka One" }}
                >
                  Muchas gracias por tu preferencia
                </Typography>
                <Typography variant="body1" component="div">
                  Por tu seguridad realiza siempre tus reservaciones o pagos a
                  través de este medio.
                </Typography>
              </CardContent>
            </Card>
          ) : null}
          <Typography variant="body1" component="div">
            ID de Cuidado: {serviceId}
          </Typography>
          {serviceData && (
            <div style={{ margin: "10px", marginTop: "15px" }}>
              <div
                style={{
                  borderRadius: "10px",
                  padding: "10px",
                  backgroundColor: "#ffff",
                }}
              >
                <div>
                  <Typography
                    variant="h6"
                    component="h2"
                    style={{
                      overflow: "hidden",
                      textOverflow: "ellipsis",
                      whiteSpace: "nowrap",
                      width: "100%",
                      fontFamily: '"Fredoka One", sans-serif', // Set the font family
                      color: "rgb(57, 61, 114)", // Set the color
                    }}
                  >
                    Tipo de Servicio:
                  </Typography>{" "}
                  {serviceData.serviceType}
                </div>
              </div>
              <div
                style={{
                  borderRadius: "10px",
                  marginTop: "10px",
                  padding: "10px",
                  backgroundColor: "#ffff",
                }}
              >
                <div>
                  <Typography
                    variant="h6"
                    component="h2"
                    style={{
                      overflow: "hidden",
                      textOverflow: "ellipsis",
                      whiteSpace: "nowrap",
                      width: "100%",
                      fontFamily: '"Fredoka One", sans-serif', // Set the font family
                      color: "rgb(57, 61, 114)", // Set the color
                    }}
                  >
                    Detalles de l@s pequeñ@/s:
                  </Typography>{" "}
                </div>
                {serviceData.serviceFor.map((service, index) => (
                  <div
                    key={index}
                    style={{
                      display: "flex",
                      marginBottom: "10px",
                      marginTop: "10px",
                    }}
                  >
                    {/* First column with icon and text */}
                    <div
                      style={{
                        marginRight: "20px",
                        display: "flex",
                        alignItems: "center",
                      }}
                    >
                      {/* Replace with your icon component */}
                      <img
                        loading="lazy"
                        src="https://cdn.builder.io/api/v1/image/assets/TEMP/bdd5a3f8dada3ccb2edecc4816ff901a0a425d44f384be6c75592b78668cbdd6?apiKey=f9ed1825c5104496bd57288a76ba297c&"
                        className="img"
                      />
                      <span style={{ marginLeft: "10px" }}>{service.name}</span>
                    </div>
                    {/* Second column with just text */}
                    <div>{service.age}</div>
                  </div>
                ))}
              </div>
              {/* TODO: Add parent details card */}
              <div
                style={{
                  borderRadius: "10px",
                  marginTop: "10px",
                  padding: "10px",
                  backgroundColor: "#ffff",
                }}
              >
                <div>
                  <Typography
                    variant="h6"
                    component="h2"
                    style={{
                      overflow: "hidden",
                      textOverflow: "ellipsis",
                      whiteSpace: "nowrap",
                      width: "100%",
                      fontFamily: '"Fredoka One", sans-serif', // Set the font family
                      color: "rgb(57, 61, 114)", // Set the color
                    }}
                  >
                    Dirección:
                  </Typography>
                  {serviceData.serviceIsRestaurant
                    ? serviceData.serviceRestaurantAddress
                    : serviceData.serviceAddress}
                </div>
              </div>
              {/* FIXME: Possibly render old version on min_profile card from parent modal */}
              {user.claims.role === "user" && (
                <Card
                  sx={{
                    width: "100%",
                    maxWidth: 600,
                    textAlign: "center",
                    boxShadow: "none",
                    borderRadius: "10px",
                    mb: 1,
                    marginTop: "10px",
                  }}
                >
                  <CardContent>
                    <Typography
                      variant="h6"
                      component="div"
                      style={{ color: "#393d72", fontFamily: "Fredoka One" }}
                    >
                      Nanny Asignada
                    </Typography>
                    {profile && (
                      <>
                        <Avatar
                          alt={profile.name}
                          src={profile.profileImage}
                          sx={{
                            width: 100,
                            height: 100,
                            margin: "auto",
                            border: "5px solid rgb(255, 72, 128)",
                          }}
                        />
                        <Typography variant="h5" component="div" style={{ fontFamily: 'Fredoka One', color: '#393d72' }}>
                          {profile.firstName} {profile.lastName}
                        </Typography>
                        <Typography variant="subtitle1" color="text.secondary">
                          {profile.profession}
                        </Typography>
                      </>
                    )}
                  </CardContent>
                </Card>
              )}

              <Divider
                variant="middle"
                style={{
                  margin: "10px",
                  backgroundColor: "rgb(140, 140, 140)",
                }}
              />
              <div>
                <Accordion elevation={0} sx={{ borderRadius: "10px" }}>
                  <AccordionSummary
                    expandIcon={<ExpandMoreIcon />}
                    aria-controls="panel1a-content"
                    id="panel1a-header"
                  >
                    {/* Adjusted for left alignment */}
                    <div
                      style={{
                        display: "flex",
                        flexDirection: "column",
                        alignItems: "flex-start",
                        width: "100%",
                      }}
                    >
                      <Typography
                        variant="h6"
                        component="h2"
                        style={{
                          overflow: "hidden",
                          textOverflow: "ellipsis",
                          whiteSpace: "nowrap",
                          fontFamily: '"Fredoka One", sans-serif', // Set the font family
                          color: "rgb(57, 61, 114)", // Set the color
                          textAlign: "left", // Align text to the left
                        }}
                      >
                        <strong>Detalles de Horario</strong>
                      </Typography>
                      {serviceData.serviceDays && (
                        <Typography
                          variant="body2"
                          color="text.secondary"
                          style={{ textAlign: "left" }} // Align text to the left
                        >
                          Número de Días: {serviceData.serviceDays.length}
                        </Typography>
                      )}
                    </div>
                  </AccordionSummary>
                  <AccordionDetails style={{ flexDirection: "column" }}>
                    <div
                      style={{
                        maxHeight: "300px",
                        overflowY: "auto",
                        marginTop: "20px",
                      }}
                    >
                      {serviceData.serviceDays &&
                        serviceData.serviceDays.map((serviceDay, index) => {
                          const miliseconds = serviceDay.day * 1000;
                          const formattedDate = new Date(
                            miliseconds
                          ).toLocaleDateString("es-ES", {
                            day: "numeric",
                            month: "long",
                            year: "numeric",
                          });
                          const formattedStartTime = new Date(
                            serviceDay.startTime
                          ).toLocaleTimeString("es-ES", {
                            hour: "2-digit",
                            minute: "2-digit",
                          });
                          const formattedEndTime = new Date(
                            serviceDay.endTime
                          ).toLocaleTimeString("es-ES", {
                            hour: "2-digit",
                            minute: "2-digit",
                          });
                          return (
                            <Card
                              key={index}
                              style={{
                                marginBottom: "10px",
                                backgroundColor: "#f9f5f0",
                                borderColor: "rgb(57, 61, 114)",
                              }}
                            >
                              <CardContent>
                                <Typography variant="h6" component="h2">
                                  {formattedDate}
                                </Typography>
                                <Typography
                                  variant="body2"
                                  color="text.secondary"
                                >
                                  Hora de Inicio: {formattedStartTime}
                                </Typography>
                                <Typography
                                  variant="body2"
                                  color="text.secondary"
                                >
                                  Hora de Finalización: {formattedEndTime}
                                </Typography>
                              </CardContent>
                            </Card>
                          );
                        })}
                    </div>
                  </AccordionDetails>
                </Accordion>
              </div>
              { user.claims.role === "user" && (
              <div style={{marginTop: "10px"}}>
                <Accordion elevation={0} sx={{ borderRadius: "10px" }}>
                  <AccordionSummary
                    expandIcon={<ExpandMoreIcon />}
                    aria-controls="panel1a-content"
                    id="panel1a-header"
                  >
                    {/* Adjusted for left alignment */}
                    <div
                      style={{
                        display: "flex",
                        flexDirection: "column",
                        alignItems: "flex-start",
                        width: "100%",
                      }}
                    >
                      <Typography
                        variant="h6"
                        component="h2"
                        style={{
                          overflow: "hidden",
                          textOverflow: "ellipsis",
                          whiteSpace: "nowrap",
                          fontFamily: '"Fredoka One", sans-serif', // Set the font family
                          color: "rgb(57, 61, 114)", // Set the color
                          textAlign: "left", // Align text to the left
                        }}
                      >
                        <strong>Detalles de Pago</strong>
                      </Typography>
                      <Typography
                          variant="body2"
                          color="text.secondary"
                          style={{ textAlign: "left" }} // Align text to the left
                        >
                          Total: ${serviceData.serviceTotalWithComission.toFixed(2)}
                        </Typography>
                    </div>
                  </AccordionSummary>
                  <AccordionDetails style={{ flexDirection: "column" }}>
                  <div
              style={{
                borderRadius: "10px",
                padding: "10px",
                backgroundColor: "#ffff",
                marginTop: "10px",
              }}
            >
              <Typography
                variant="h5"
                component="h2"
                style={{
                  overflow: "hidden",
                  textOverflow: "ellipsis",
                  whiteSpace: "nowrap",
                  width: "100%",
                  fontFamily: '"Fredoka One", sans-serif', // Set the font family
                  color: "rgb(57, 61, 114)", // Set the color
                }}
              >
                Pago Nannapp:
              </Typography>
              <Typography
                variant="h4"
                component="h1"
                style={{
                  overflow: "hidden",
                  textOverflow: "ellipsis",
                  whiteSpace: "nowrap",
                  width: "100%",
                  fontFamily: '"Fredoka One", sans-serif', // Set the font family
                  color: "#FED557", // Set the color
                }} // Align text to the left
              >
                ${serviceData.serviceTotal.toFixed(2)}
              </Typography>
              <div
                style={{
                  display: "flex",
                  justifyContent: "space-between",
                  alignItems: "center",
                }}
              >
                <Typography
                  variant="h6"
                  component="h2"
                  style={{
                    overflow: "hidden",
                    textOverflow: "ellipsis",
                    whiteSpace: "nowrap",
                    fontFamily: '"Fredoka One", sans-serif', // Set the font family
                    color: "rgb(57, 61, 114)", // Set the color
                  }}
                >
                  Cuota transporte privado:
                  <br />
                  (A partir de las 10 PM)
                </Typography>
                <Typography
                  variant="h5"
                  component="h2"
                  style={{
                    overflow: "hidden",
                    textOverflow: "ellipsis",
                    whiteSpace: "nowrap",
                    fontFamily: '"Fredoka One", sans-serif', // Set the font family
                    color: "#FED557", // Set the color
                  }} // Align text to the left
                >
                  ${serviceData.transportFeeTotal.toFixed(2)}
                </Typography>
              </div>
              <div
                style={{
                  display: "flex",
                  justifyContent: "space-between",
                  alignItems: "center",
                }}
              >
                <Typography
                  variant="h6"
                  component="h2"
                  style={{
                    overflow: "hidden",
                    textOverflow: "ellipsis",
                    whiteSpace: "nowrap",
                    fontFamily: '"Fredoka One", sans-serif', // Set the font family
                    color: "rgb(57, 61, 114)", // Set the color
                  }}
                >
                  Comisión Pasarela de Pago:
                  <br />
                  (3.6%)
                </Typography>
                <Typography
                  variant="h5"
                  component="h2"
                  style={{
                    overflow: "hidden",
                    textOverflow: "ellipsis",
                    whiteSpace: "nowrap",
                    fontFamily: '"Fredoka One", sans-serif', // Set the font family
                    color: "#FED557", // Set the color
                  }} // Align text to the left
                >
                  ${serviceData.serviceTransactionFee.toFixed(2)}
                </Typography>
              </div>
              <div
                style={{
                  display: "flex",
                  justifyContent: "flex-end", // Align items to the right
                  alignItems: "center", // Correctly align items vertically
                }}
              >
                <Typography
                  variant="h6"
                  component="h2"
                  style={{
                    overflow: "hidden",
                    textOverflow: "ellipsis",
                    whiteSpace: "nowrap",
                    fontFamily: '"Fredoka One", sans-serif', // Set the font family
                    color: "rgb(57, 61, 114)", // Set the color
                  }}
                >
                    Subtotal: &nbsp;
                </Typography>
                <Typography
                  variant="h5"
                  component="h2"
                  style={{
                    overflow: "hidden",
                    textOverflow: "ellipsis",
                    whiteSpace: "nowrap",
                    fontFamily: '"Fredoka One", sans-serif', // Set the font family
                    color: "#FED557", // Set the color
                  }} // Correct comment to align with CSS properties
                >
                  ${serviceData.serviceSubtotal.toFixed(2)}
                </Typography>
              </div>
              <div
                style={{
                  display: "flex",
                  justifyContent: "flex-end", // Align items to the right
                  alignItems: "center", // Correctly align items vertically
                }}
              >
                <Typography
                  variant="h6"
                  component="h2"
                  style={{
                    overflow: "hidden",
                    textOverflow: "ellipsis",
                    whiteSpace: "nowrap",
                    fontFamily: '"Fredoka One", sans-serif', // Set the font family
                    color: "rgb(57, 61, 114)", // Set the color
                  }}
                >
                    IVA: &nbsp;
                </Typography>
                <Typography
                  variant="h5"
                  component="h2"
                  style={{
                    overflow: "hidden",
                    textOverflow: "ellipsis",
                    whiteSpace: "nowrap",
                    fontFamily: '"Fredoka One", sans-serif', // Set the font family
                    color: "#FED557", // Set the color
                  }} // Correct comment to align with CSS properties
                >
                  ${serviceData.serviceTax.toFixed(2)}
                </Typography>
              </div>
              <div
                style={{
                  display: "flex",
                  justifyContent: "flex-end", // Align items to the right
                  alignItems: "center", // Correctly align items vertically
                  marginTop: "5px",
                }}
              >
                <Typography
                  variant="h4"
                  component="h2"
                  style={{
                    overflow: "hidden",
                    textOverflow: "ellipsis",
                    whiteSpace: "nowrap",
                    fontFamily: '"Fredoka One", sans-serif', // Set the font family
                    color: "rgb(57, 61, 114)", // Set the color
                  }}
                >
                    Total: &nbsp;
                </Typography>
                <Typography
                  variant="h4"
                  component="h2"
                  style={{
                    overflow: "hidden",
                    textOverflow: "ellipsis",
                    whiteSpace: "nowrap",
                    fontFamily: '"Fredoka One", sans-serif', // Set the font family
                    color: "#FED557", // Set the color
                  }} // Correct comment to align with CSS properties
                >
                  ${serviceData.serviceTotalWithComission.toFixed(2)}
                </Typography>
              </div>
            </div>
                  </AccordionDetails>
                </Accordion>
              </div>
              )}
              {user.claims.role === "nanny" && (
                <div
                  style={{
                    borderRadius: "10px",
                    marginTop: "10px",
                    padding: "10px",
                    backgroundColor: "#ffff",
                  }}
                >
                  <div>
                    <Typography
                      variant="h6"
                      component="h2"
                      style={{
                        overflow: "hidden",
                        textOverflow: "ellipsis",
                        whiteSpace: "nowrap",
                        width: "100%",
                        fontFamily: '"Fredoka One", sans-serif', // Set the font family
                        color: "rgb(57, 61, 114)", // Set the color
                      }}
                    >
                      Detalles de Pago:
                    </Typography>
                    <Chip
                      label={`$${serviceData.serviceWorkingHoursComission} MXN`}
                      sx={{ backgroundColor: "#4caf50", color: "#fff", marginTop: "10px" }}
                    />
                  </div>
                </div>
              )}
            </div>
          )}
        </Grid>
      </Grid>
    </div>
  );
};

export default ServiceView;
