import React from "react";
import Grid from "@mui/material/Grid";
import {
  AppBar,
  Avatar,
  Card,
  CardContent,
  Chip,
  Dialog,
  IconButton,
  Slide,
  Toolbar,
  Typography,
} from "@mui/material";
import LocalHospitalIcon from '@mui/icons-material/LocalHospital';
import ChildCareIcon from '@mui/icons-material/ChildCare';
import ElderlyIcon from '@mui/icons-material/Accessibility';
import CloseIcon from '@mui/icons-material/Close';

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

export default function NannyProfile({ open, onClose, profile}) {
  // Rest of the component code...
  
  return (
    <React.Fragment>
      <Dialog fullScreen open={open} onClose={onClose} TransitionComponent={Transition} PaperProps={{
          style: { backgroundColor: '#f9f5f0' }, // Establecer el color de fondo del diálogo
        }}>
      <AppBar sx={{ position: 'relative', backgroundColor: '#393d72' }}>
          <Toolbar>
            <IconButton
              edge="start"
              color="inherit"
              onClick={onClose}
              aria-label="close"
            >
              <CloseIcon />
            </IconButton>
          </Toolbar>
        </AppBar>
          <Grid container spacing={2}>
            
            <Grid
              item
              xs={12}
              justifyContent="center"
              display="flex"
              flexDirection="column"
              alignItems="center"
              sx={{ margin: "10px" }}
            >
              
              <Card
                sx={{
                  width: "100%",
                  maxWidth: 600,
                  textAlign: "center",
                  boxShadow: "none",
                  borderRadius: "10px",
                  mb: 1,
                }}
              >
                <CardContent>
                  <Avatar
                    alt={profile.name}
                    src={profile.profileImage}
                    sx={{
                      width: 100,
                      height: 100,
                      margin: "auto",
                      border: "5px solid rgb(255, 72, 128)",
                    }}
                  />
                  <Typography variant="h5" component="div" style={{ fontFamily: 'Fredoka One',color: '#393d72' }}>
                    {profile.firstName} {profile.lastName}
                  </Typography>
                  <Typography variant="subtitle1" color="text.secondary">
                    {profile.profession}
                  </Typography>
                </CardContent>
              </Card>
              <Card
                sx={{
                  width: "100%",
                  maxWidth: 600,
                  textAlign: "left",
                  boxShadow: "none",
                  borderRadius: "10px",
                  mb: 1,
                }}
              >
                <CardContent>
                  <Grid container alignItems="center" spacing={2}>
                    <Grid item xs={4}>
                      <Typography variant="h7" component="div" style={{ fontFamily: 'Fredoka One',color: '#393d72' }}>
                        Edad: 20 Años
                      </Typography>
                    </Grid>
                    <Grid item xs={8}>
                      <Typography
                        variant="h7"
                        component="div"
                        style={{ textAlign: "right", fontFamily: 'Fredoka One',color: '#393d72'}}
                      >
                        Edades de Cuidado: {profile.serviceAgeFrom} -{" "}
                        {profile.serviceAgeUntil} años
                      </Typography>
                    </Grid>
                  </Grid>
                  <Typography variant="body1" style={{ marginTop: "8px" }}>
                    {profile.profileDescription}
                  </Typography>
                </CardContent>
              </Card>
              <Card
                sx={{
                  width: "100%",
                  maxWidth: 600,
                  textAlign: "left",
                  boxShadow: "none",
                  borderRadius: "10px",
                  mb: 1,
                }}
              >
                <CardContent>
                  <Typography variant="h7" component="div" style={{ fontFamily: 'Fredoka One',color: '#393d72' }}>
                    Idiomas
                  </Typography>
                  {profile.language.map((language, index) => (
                    <Chip
                      key={index}
                      label={language}
                      style={{ marginTop: "8px", marginRight: "8px" }}
                    />
                  ))}
                </CardContent>
              </Card>
              <Card
                sx={{
                  width: "100%",
                  maxWidth: 600,
                  textAlign: "left",
                  boxShadow: "none",
                  borderRadius: "10px",
                }}
              >
                <CardContent>
                  <Typography variant="h7" component="div" style={{ fontFamily: 'Fredoka One',color: '#393d72' }}>
                    Servicios Adicionales
                  </Typography>
                  {profile.firstAidCertification && (
                    <Chip
                      icon={<LocalHospitalIcon />}
                      label="Certificado de Primeros Auxilios"
                      style={{
                        marginTop: "8px",
                        marginRight: "8px",
                        backgroundColor: "pink",
                      }}
                    />
                  )}
                  {profile.specialNeedsCare && (
                    <Chip
                      icon={<ChildCareIcon />}
                      label="Cuidado a Niños con Necesidades Especiales"
                      style={{
                        marginTop: "8px",
                        marginRight: "8px",
                        backgroundColor: "pink",
                      }}
                    />
                  )}
                  {profile.elderlyCare && (
                    <Chip
                      icon={<ElderlyIcon />}
                      label="Cuidado a Personas Mayores"
                      style={{
                        marginTop: "8px",
                        marginRight: "8px",
                        backgroundColor: "pink",
                      }}
                    />
                  )}
                </CardContent>
              </Card>
            </Grid>
          </Grid>
      </Dialog>
    </React.Fragment>
  );
}
