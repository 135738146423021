import {initializeApp} from 'firebase/app'
import {getFirestore, connectFirestoreEmulator} from 'firebase/firestore'
import {getAuth, connectAuthEmulator} from 'firebase/auth'
import {getFunctions, connectFunctionsEmulator} from 'firebase/functions'
import {getStorage, connectStorageEmulator} from 'firebase/storage'
import { getMessaging } from 'firebase/messaging'

// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfig = {
    apiKey: "AIzaSyDsRNlzXJgHT6J88VN08fe1JUZ7wE4Y3H4",
    authDomain: "nannapp-46e1b.firebaseapp.com",
    databaseURL: "https://nannapp-46e1b-default-rtdb.firebaseio.com",
    projectId: "nannapp-46e1b",
    storageBucket: "nannapp-46e1b.appspot.com",
    messagingSenderId: "995394383905",
    appId: "1:995394383905:web:73c1b352d21e6f3a9e3360",
    measurementId: "G-0EEEZXW2FP"
};

//Init Firebase
const app = initializeApp(firebaseConfig);

const db = getFirestore(app);
const auth = getAuth(app);
const storage = getStorage(app);
const functions = getFunctions(app);

const messaging = getMessaging(app);

if (window.location.hostname === "localhost") {
    //Connect to emulators
    connectFirestoreEmulator(db, 'localhost', 8080);
    connectAuthEmulator(auth, "http://localhost:9099", {
		disableWarnings: true,
	});
    connectFunctionsEmulator(functions, 'localhost', 5001);
    connectStorageEmulator(storage, 'localhost', 9199);
}

export {db, auth, functions,storage, messaging};


