import React, { useState } from "react";
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions
} from "@mui/material";
import "./dialogStyles.css";
import { useLogin } from "../../hooks/useLogin";

const LoginDialog = ({ open, onClose, onResetPasswordClick }) => {
  
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");

  const { error, login } = useLogin();

  const handleSubmit = (e) => {
    e.preventDefault();
    login(email, password);
  };

  return (
    <Dialog
      open={open}
      onClose={onClose}
      PaperProps={{
        style: {
          borderRadius: 15,
        },
      }}
    >
      <DialogTitle className="dialog-title-component">
        <p className="dialog-title-style">Iniciar Sesión</p>
      </DialogTitle>
      <div className="dialog-subtitle-style">
        <p style={{ fontSize: "15px" }}>
            Ingresa tu email y contraseña <br/>para iniciar sesión
        </p>
        </div>
      <form onSubmit={handleSubmit}>
        <DialogContent style={{ fontFamily: "Fredoka One" }}>
          <div className="form-group input-styles">
            <fieldset>
              <input
                type="email"
                className="form-control"
                name="email"
                placeholder="Email"
                required
                onChange={(e) => setEmail(e.target.value)}
                value={email}
              />
            </fieldset>
          </div>
          <div className="form-group" style={{ paddingTop: "10px" }}>
            <fieldset>
              <input
                type="password"
                className="form-control"
                name="password"
                placeholder="Contraseña"
                required
                onChange={(e) => setPassword(e.target.value)}
                value={password}
              />
            </fieldset>
          </div>
        </DialogContent>
        {error && <p className="error">{error}</p>}
        <DialogActions
          style={{
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
          }}
          >
          <button
            onClick={handleSubmit}
            className="theme-btn btn-style-one login-button"
            style={{ padding: "10px 20px" }}
          >
            Iniciar Sesión
          </button>
          <a
            onClick={onResetPasswordClick}
            style={{ padding: "10px 20px", marginTop: "10px", color: 'pink', cursor: 'pointer' }}
          >
            Olvide mi contraseña 
          </a>
        </DialogActions>
      </form>
    </Dialog>
  );
};

export default LoginDialog;
