import { useState, useEffect } from 'react';
import { db } from '../firebase/config';
import { doc, onSnapshot} from 'firebase/firestore';

export const useDocument = (c, docId) => {
    const [document, setDocument] = useState(null);

    useEffect(() => {
        if (docId) {
            let ref = doc(db, c, docId);

            const unsub = onSnapshot(ref, (docSnapshot) => {
                if (docSnapshot.exists()) {
                    setDocument({ ...docSnapshot.data(), id: docSnapshot.id });
                } else {
                    setDocument(null);
                }
            });

            return () => unsub();
        }
    }, [c, docId]);

    return { document };
};