import React from 'react';
import { CircularProgress, Typography } from '@mui/material';

export default function Searching() {
  return (
    <div
      style={{
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'center',
        height: '30vh',
        textAlign: 'center' // added textAlign property
      }}
    >
      <CircularProgress size={65} sx={{color:"#ffae00"}}/>
      <Typography variant="p" sx={{ mt: 2 , fontFamily:'Fredoka One'}}>
        Estamos buscando a tu nanny de la guarda...<br/>
        Esto puede demorar unos minutos
      </Typography>
    </div>
  );
}
