import React, { useState, useEffect } from 'react';
import i18n from '../../i18n';

const LanguageToggle = () => {
  const [language, setLanguage] = useState(i18n.language || 'es');

  useEffect(() => {
    // Sync the state with the i18n language if it changes
    const handleLanguageChange = (lng) => {
      setLanguage(lng);
    };

    i18n.on('languageChanged', handleLanguageChange);

    return () => {
      i18n.off('languageChanged', handleLanguageChange);
    };
  }, []);

  const handleToggle = () => {
    const newLanguage = language === 'en' ? 'es' : 'en';
    setLanguage(newLanguage);
    i18n.changeLanguage(newLanguage);
  };

  return (
    <div>
      <button
        onClick={handleToggle}
        style={{
          border: 'none',
          background: 'none',
          cursor: 'pointer',
          display: 'flex',
          alignItems: 'center',
          fontFamily: 'Fredoka One, cursive',
          fontSize: '18px',
        }}
      >
        {language === 'en' ? (
          <>
            <img
              src="https://upload.wikimedia.org/wikipedia/commons/a/a4/Flag_of_the_United_States.svg"
              alt="English"
              width="40"
              height="30"
              style={{ borderRadius: '4px' }}
            />
            <span style={{ marginLeft: '8px', color: 'white' }}>EN</span>
          </>
        ) : (
          <>
            <img
              src="https://upload.wikimedia.org/wikipedia/commons/9/9a/Flag_of_Spain.svg"
              alt="Español"
              width="40"
              height="30"
              style={{ borderRadius: '4px' }}
            />
            <span style={{ marginLeft: '8px', color: 'white' }}>ES</span>
          </>
        )}
      </button>
    </div>
  );
};

export default LanguageToggle;
