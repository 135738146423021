import React, { useEffect, useState } from "react";
import {
  collection,
  query,
  where,
  getDocs,
  onSnapshot,
  updateDoc,
  doc,
} from "firebase/firestore";

import { db } from "../../firebase/config";
import {
  Box,
  Card,
  CardContent,
  IconButton,
  Typography,
  Modal,
  CardActions,
  Button,
  Chip,
  Stack,
  getBottomNavigationActionUtilityClass,
  Avatar,
} from "@mui/material";
import CheckIcon from "@mui/icons-material/Check";
import dayjs from "dayjs";
import "dayjs/locale/es";
import { useAuthContext } from "../../hooks/useAuthContext";
import { ChildCare, Close, Info, WhatsApp } from "@mui/icons-material";
import Logo_Icon from "../assets/img/Logo_wl.png";
import { useDocument } from "../../hooks/useDocument";
import { useNavigate } from "react-router-dom";

export default function ServiceList() {
  const [services, setServices] = useState([]);
  const [selectedService, setSelectedService] = useState(null);
  const [openModal, setOpenModal] = useState(false);

  const { user } = useAuthContext();

  const roleAttribute =
    user.claims.role === "user" ? "serviceCreatedBy" : "serviceAssignedTo";

  useEffect(() => {
    const q = query(
      collection(db, "private_services"),
      where(roleAttribute, "==", user.uid)
    );

    console.log(q);

    const unsubscribe = onSnapshot(q, (querySnapshot) => {
      const servicesData = querySnapshot.docs.map((doc) => ({
        id: doc.id,
        ...doc.data(),
      }));
      setServices(servicesData);
    });

    return () => {
      // Unsubscribe from updates when the component is unmounted
      unsubscribe();
    };
  }, []);


  return (
    <div
      style={{
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        marginTop: "10px",
      }}
    >
      {services.map((service) => (
        <ServiceCard
          key={service.id}
          serviceId={service.id}
          service={service}
        />
      ))}
    </div>
  );
}

function ServiceCard({ service, handleOpenModal , serviceId}) {
  const { user } = useAuthContext();
  const navigate = useNavigate();
  const { serviceType, serviceDays, startTime, startDate, packageType } =
    service;
  const { document: profile } = useDocument(
    "nanny",
    service?.serviceAssignedTo
  );

  // Handle formatting for "Cuidado Eventual" differently
  let dayInfo, startTimeFormatted, endTimeFormatted, duration;
  if (serviceType === "Cuidado Eventual" && serviceDays.length > 0) {
    const serviceDay = serviceDays[0]; // Only take the first item
    const day = dayjs(serviceDay.day.$d).locale("es");
    const start = dayjs(serviceDay.startTime.$d);
    const end = start.add(serviceDay.duration, "hour");
    dayInfo = day.format("MMMM D, YYYY");
    startTimeFormatted = start.format("HH:mm");
    endTimeFormatted = end.format("HH:mm");
    duration = serviceDay.duration;
  } else if (serviceType !== "Cuidado Eventual" && startTime) {
    // Handle non-eventual care types
    const start = dayjs(startTime.$d);
    const end = start.add(packageType.includes("4") ? 4 : 6, "hour");
    startTimeFormatted = start.format("hh:mm a");
    endTimeFormatted = end.format("hh:mm a");
    dayInfo = dayjs(startDate.$d).format("YYYY-MM-DD");
  }

  return (
    <Card
    sx={{
      display: "flex",
      flexDirection: ["column", "row"], // Stack elements vertically on small screens, horizontally on larger screens
      justifyContent: "space-between",
      alignItems: "center",
      padding: "16px",
      margin: "8px 0",
      borderRadius: "8px",
      boxShadow: "0 2px 4px rgba(0,0,0,0.1)",
      "&:hover": {
        boxShadow: "0 4px 8px rgba(0,0,0,0.2)",
      },
      backgroundColor: "#fff",
      maxWidth: "100%",
      // Use a media query to adjust padding and layout for smaller screens
      '@media (max-width: 600px)': {
        flexDirection: "column", // Stack content vertically on small screens
        padding: "12px", // Slightly reduce padding to save space
        alignItems: "flex-start", // Align items to the start for a cleaner look
      },
    }}
    >
      {/* TODO: Check if how a package will render the information */}

      <div style={{ flex: 4 }}>
        {serviceType === "Cuidado Eventual" && dayInfo && (
          <div>
            <Typography
              variant="subtitle1"
              sx={{
                color: "#333",
                marginBottom: "4px",
                fontFamily: "'Fredoka One', cursive",
              }}
            >
              Fecha:{" "}
              <Typography
                variant="body1"
                component="span"
                sx={{ color: "#666" }}
              >
                {dayInfo}
              </Typography>
            </Typography>
            <Typography
              variant="subtitle1"
              sx={{ color: "#333", fontFamily: "'Fredoka One', cursive" }}
            >
              Hora:{" "}
              <Typography
                variant="body1"
                component="span"
                sx={{ color: "#666" }}
              >
                {startTimeFormatted} - {endTimeFormatted}{" "}
                <span style={{ fontWeight: "normal" }}>
                  (Duración: {duration} horas)
                </span>
              </Typography>
            </Typography>
          </div>
        )}
        {serviceType !== "Cuidado Eventual" && dayInfo && (
          <div>
            <Typography
              variant="subtitle1"
              sx={{
                color: "#333",
                marginBottom: "4px",
                fontFamily: "'Fredoka One', cursive",
              }}
            >
              Horario:{" "}
              <Typography
                variant="body1"
                component="span"
                sx={{ color: "#666" }}
              >
                {startTimeFormatted} - {endTimeFormatted}
              </Typography>
            </Typography>
            <Typography
              variant="subtitle1"
              sx={{ color: "#333", fontFamily: "'Fredoka One', cursive" }}
            >
              Inicio:{" "}
              <Typography
                variant="body1"
                component="span"
                sx={{ color: "#666" }}
              >
                {dayInfo}
              </Typography>
            </Typography>
          </div>
        )}
      </div>

      {profile && user.claims === "user" && (
        <div
          style={{ flex: 2, display: "flex", alignItems: "center", gap: "8px" }}
        >
          <Avatar
            alt={profile.firstName}
            src={profile.profileImage}
            sx={{ width: 56, height: 56 }}
          />
          <div>
            <Typography
              variant="subtitle1"
              sx={{ color: "#666", fontFamily: "'Fredoka One', cursive" }}
            >
              {profile.firstName}
            </Typography>
            {/* Additional profile info could go here */}
          </div>
        </div>
      )}
      {/* TODO: Go to SignUp an save first and lastname on user document so it can render above the "avatar"(name) of user for nanny view */}
      <Button
        onClick={() => {
          navigate(`service/${serviceId}/false`);
        }}
        startIcon={<Info sx={{ color: "white" }} />}
        sx={{
          marginLeft: "auto",
          backgroundColor: "#f06292", // A shade of pink from Material UI color palette
          "&:hover": {
            backgroundColor: "#ec407a", // Darken on hover
          },
          color: "white",
          fontFamily: "'Fredoka One', cursive",
          padding: "6px 16px",
          borderRadius: "20px", // More pronounced rounded corners
        }}
      >
        Detalles
      </Button>
    </Card>
  );
}
