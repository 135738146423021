import React, { useState, useEffect } from 'react';
import {
  AppBar,
  Toolbar,
  Typography,
  IconButton,
  Card,
  CardContent,
  Grid,
  TextField,
  Button,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Select,
  MenuItem,
  InputLabel,
} from '@mui/material';
import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos';
import AddCircleOutlineIcon from '@mui/icons-material/AddCircleOutline';
import DeleteIcon from '@mui/icons-material/Delete';
import { useNavigate } from 'react-router-dom';
import { useAuthContext } from '../../hooks/useAuthContext';
import { doc, updateDoc, arrayUnion, arrayRemove, getDoc } from 'firebase/firestore';
import { db } from '../../firebase/config';
import estadosYCiudades from '../components/estadosYCiudades.json';

const ManageAddressesScreen = () => {
  const { user } = useAuthContext();
  const navigate = useNavigate();
  const [addresses, setAddresses] = useState([]);
  const [openDialog, setOpenDialog] = useState(false);
  const [newAddress, setNewAddress] = useState({
    street: '',
    neighborhood: '',
    city: '',
    state: '',
    zipCode: '',
  });
  const [deleteDialogOpen, setDeleteDialogOpen] = useState(false);
  const [addressToDelete, setAddressToDelete] = useState(null);
  const [availableCities, setAvailableCities] = useState([]);

  useEffect(() => {
    const fetchAddresses = async () => {
      const userDoc = doc(db, 'users', user.uid);
      const userData = await getDoc(userDoc);
      if (userData.exists()) {
        setAddresses(userData.data().addresses || []);
      }
    };
    fetchAddresses();
  }, [user.uid]);

  const handleOpenDialog = () => {
    setOpenDialog(true);
  };

  const handleCloseDialog = () => {
    setOpenDialog(false);
    setNewAddress({
      street: '',
      neighborhood: '',
      city: '',
      state: '',
      zipCode: '',
    });
  };

  const handleAddAddress = async () => {
    if (isValidForm()) {
      const userDoc = doc(db, 'users', user.uid);
      await updateDoc(userDoc, { addresses: arrayUnion(newAddress) });
      setAddresses([...addresses, newAddress]);
      handleCloseDialog();
    }
  };

  const handleDeleteAddress = async (addressToDelete) => {
    const userDoc = doc(db, 'users', user.uid);
    await updateDoc(userDoc, { addresses: arrayRemove(addressToDelete) });
    setAddresses(addresses.filter(address => address.street !== addressToDelete.street));
  };

  const handleOpenDeleteDialog = (address) => {
    setAddressToDelete(address);
    setDeleteDialogOpen(true);
  };

  const handleCloseDeleteDialog = () => {
    setDeleteDialogOpen(false);
    setAddressToDelete(null);
  };

  const handleConfirmDelete = async () => {
    if (addressToDelete) {
      await handleDeleteAddress(addressToDelete);
      handleCloseDeleteDialog();
    }
  };

  const isValidZipCode = (zipCode) => {
    return /^\d{5}$/.test(zipCode);
  };

  const isValidForm = () => {
    return (
      newAddress.street.trim() !== '' &&
      newAddress.neighborhood.trim() !== '' &&
      newAddress.city.trim() !== '' &&
      newAddress.state.trim() !== '' &&
      isValidZipCode(newAddress.zipCode)
    );
  };

  const handleStateChange = (event) => {
    const selectedState = event.target.value;
    setNewAddress({ ...newAddress, state: selectedState, city: '' });
    setAvailableCities(estadosYCiudades[selectedState] || []);
  };

  return (
    <div style={{ backgroundColor: '#f9f5f0', minHeight: '100vh', overflow: 'auto' }}>
      <AppBar position="static" sx={{ backgroundColor: '#393d72' }}>
        <Toolbar sx={{ display: 'flex', justifyContent: 'space-between' }}>
          <IconButton
            color="inherit"
            aria-label="back"
            onClick={() => navigate('/')}
            sx={{
              borderRadius: '50%',
              backgroundColor: '#ffae00',
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
            }}
          >
            <ArrowBackIosIcon />
          </IconButton>
          <Typography variant="h6" style={{ fontFamily: 'Fredoka One', color: 'white' }}>
            Tus Direcciones
          </Typography>
          <IconButton
            color="inherit"
            aria-label="add"
            onClick={handleOpenDialog}
            sx={{
              borderRadius: '50%',
              backgroundColor: '#ffae00',
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
            }}
          >
            <AddCircleOutlineIcon />
          </IconButton>
        </Toolbar>
      </AppBar>

      <Grid container spacing={2} sx={{ padding: 2 }}>
        {addresses.map((address, index) => (
          <Grid item xs={12} key={index}>
            <Card sx={{ borderRadius: '10px', backgroundColor: '#fff' }}>
              <CardContent>
                <Grid container alignItems="center" spacing={2}>
                  <Grid item xs={10}>
                    <Typography variant="h6" style={{ fontFamily: 'Fredoka One', color: '#393d72' }}>
                      {address.street}
                    </Typography>
                    <Typography variant="body2" color="text.secondary">
                      {address.neighborhood}, {address.city}, {address.state}, {address.zipCode}
                    </Typography>
                  </Grid>
                  <Grid item xs={2}>
                    <IconButton
                      onClick={() => handleOpenDeleteDialog(address)}
                      sx={{
                        borderRadius: '50%',
                        backgroundColor: '#ffae00',
                        color: 'white',
                        '&:hover': { backgroundColor: '#e69b00' },
                      }}
                    >
                      <DeleteIcon />
                    </IconButton>
                  </Grid>
                </Grid>
              </CardContent>
            </Card>
          </Grid>
        ))}
      </Grid>

      <Dialog 
        open={openDialog} 
        onClose={handleCloseDialog}
        PaperProps={{
          style: {
            borderRadius: 15,
            backgroundColor: '#f9f5f0',
          },
        }}
      >
        <DialogTitle style={{ 
          textAlign: 'center', 
          fontFamily: 'Fredoka One', 
          color: '#393d72',
          fontSize: '24px'
        }}>
          Agregar Dirección
        </DialogTitle>
        <DialogContent>
          <TextField
            autoFocus
            margin="dense"
            label="Calle y Número"
            type="text"
            fullWidth
            value={newAddress.street}
            onChange={(e) => setNewAddress({ ...newAddress, street: e.target.value })}
            error={newAddress.street.trim() === ''}
            helperText={newAddress.street.trim() === '' ? 'La calle es requerida' : ''}
          />
          <TextField
            margin="dense"
            label="Colonia"
            type="text"
            fullWidth
            value={newAddress.neighborhood}
            onChange={(e) => setNewAddress({ ...newAddress, neighborhood: e.target.value })}
            error={newAddress.neighborhood.trim() === ''}
            helperText={newAddress.neighborhood.trim() === '' ? 'La colonia es requerida' : ''}
          />
          <InputLabel id="state-select-label">Estado</InputLabel>
          <Select
            labelId="state-select-label"
            id="state-select"
            value={newAddress.state}
            onChange={handleStateChange}
            fullWidth
            margin="dense"
          >
            {Object.keys(estadosYCiudades).map((state) => (
              <MenuItem key={state} value={state}>{state}</MenuItem>
            ))}
          </Select>
          <InputLabel id="city-select-label">Ciudad</InputLabel>
          <Select
            labelId="city-select-label"
            id="city-select"
            value={newAddress.city}
            onChange={(e) => setNewAddress({ ...newAddress, city: e.target.value })}
            fullWidth
            margin="dense"
            disabled={!newAddress.state}
          >
            {availableCities.map((city) => (
              <MenuItem key={city} value={city}>{city}</MenuItem>
            ))}
          </Select>
          <TextField
            margin="dense"
            label="Código Postal"
            type="text"
            fullWidth
            value={newAddress.zipCode}
            onChange={(e) => setNewAddress({ ...newAddress, zipCode: e.target.value })}
            error={!isValidZipCode(newAddress.zipCode)}
            helperText={!isValidZipCode(newAddress.zipCode) ? 'Código postal inválido' : ''}
          />
        </DialogContent>
        <DialogActions style={{
          justifyContent: 'center',
          padding: '20px'
        }}>
          <Button 
            onClick={handleCloseDialog}
            style={{
              fontFamily: 'Fredoka One',
              color: '#393d72',
              borderColor: '#393d72',
              borderRadius: '20px',
              padding: '5px 20px'
            }}
            variant="outlined"
          >
            Cancelar
          </Button>
          <Button 
            onClick={handleAddAddress}
            style={{
              fontFamily: 'Fredoka One',
              backgroundColor: '#ffae00',
              color: 'white',
              borderRadius: '20px',
              padding: '5px 20px',
              marginLeft: '10px'
            }}
            variant="contained"
            disabled={!isValidForm()}
          >
            Agregar
          </Button>
        </DialogActions>
      </Dialog>

      <Dialog 
        open={deleteDialogOpen} 
        onClose={handleCloseDeleteDialog}
        PaperProps={{
          style: {
            borderRadius: 15,
            backgroundColor: '#f9f5f0',
          },
        }}
      >
        <DialogTitle style={{ 
          textAlign: 'center', 
          fontFamily: 'Fredoka One', 
          color: '#ffae00',
          fontSize: '20px',
        }}>
          Confirmar Eliminación
        </DialogTitle>
        <DialogContent>
          <Typography style={{ 
            textAlign: 'center', 
            fontFamily: 'Fredoka One', 
            color: '#393d72',
            fontSize: '16px',
            marginTop: '10px',
            marginBottom: '10px'
          }}>
            ¿Estás seguro de que quieres eliminar esta dirección?
          </Typography>
        </DialogContent>
        <DialogActions style={{
          justifyContent: 'center',
          padding: '20px'
        }}>
          <Button 
            onClick={handleCloseDeleteDialog}
            style={{
              fontFamily: 'Fredoka One',
              color: '#393d72',
              borderColor: '#393d72',
              borderRadius: '20px',
              padding: '5px 20px'
            }}
            variant="outlined"
          >
            Cancelar
          </Button>
          <Button 
            onClick={handleConfirmDelete} 
            style={{
              fontFamily: 'Fredoka One',
              backgroundColor: '#ff4880',
              color: 'white',
              borderRadius: '20px',
              padding: '5px 20px',
              marginLeft: '10px'
            }}
            variant="contained"
          >
            Eliminar
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
};

export default ManageAddressesScreen;

