import React from 'react'

import { Typography, Button } from '@mui/material';
export default function NannyNotFound() {
    const handleReload = () => {
        window.location.reload();
    };

    return (
        <div
            style={{
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'center',
                justifyContent: 'center',
                height: '30vh',
                textAlign: 'center' // added textAlign property
            }}
        >
            <Typography variant="p" sx={{ mt: 2 , fontFamily:'Fredoka One'}}>
                Una disculpa, no hemos podido encontrar a tu nanny...<br/>
                Contactanos en <a href="https://api.whatsapp.com/send?phone=5215521296063&text=Hola%20!%20No%20pude%20encontrar%20una%20nanny%20con%20la%20app%20%F0%9F%93%B1" style={{color: '#25D366'}}>WhatsApp</a> para ayudarte ó intenta de nuevo más tarde
            </Typography>
            <Button variant="contained" sx={{ mt: 2 }} onClick={handleReload}>Intentar de Nuevo</Button>
        </div>
    )
}
