import React, { useState } from "react";
import {
  MenuItem,
  Select,
  OutlinedInput,
  InputLabel,
  TextField,
  Typography,
  Chip,
  Button,
  FormControl,
} from "@mui/material";

import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import dayjs from "dayjs";
import { DatePicker, MobileTimePicker } from "@mui/x-date-pickers";
import { Box } from "@mui/system";
import utc from "dayjs-plugin-utc";

dayjs.extend(utc);

const adapter = new AdapterDayjs();

function calculateAge(birthdate) {
  const today = new Date();
  const birthDate = new Date(birthdate);
  const oneDay = 24 * 60 * 60 * 1000; // Number of milliseconds in one day
  const diffInDays = Math.round(
    Math.abs((today.getTime() - birthDate.getTime()) / oneDay)
  );
  return diffInDays;
}

export default function EventualCareForm({
  userData,
  eventualCareData,
  handleEventualCareData,
  addServiceDay,
  removeServiceDay,
}) {
  const kids = (userData?.kids || []).map((kid) => ({
    ...kid,
    age: calculateAge(kid.birthdate),
  }));

  const addresses = (userData?.addresses || []).map((address) => ({
    ...address,
  }));

  const now = new Date();

  //
  const ITEM_HEIGHT = 48;
  const ITEM_PADDING_TOP = 8;
  const MenuProps = {
    PaperProps: {
      style: {
        maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
        width: 250,
      },
    },
  };
  //
  //

  const handleChangeChild = (event) => {
    const {
      target: { value },
    } = event;
    handleEventualCareData({
      target: {
        name: "serviceFor",
        value: typeof value === "string" ? value.split(",") : value,
      },
    });
  };

  //Addresses

  const handleChangeAddress = (event) => {
    handleEventualCareData({
      target: {
        name: "serviceAddress",
        value: event.target.value,
      },
    });
  };

  //Nota

  const handleNoteChange = (event) => {
    handleEventualCareData({
      target: {
        name: "serviceNotes",
        value: event.target.value,
      },
    });
  };
  //Direcciones
  const [displayAddress, setDisplayAddress] = useState(false);
  const [displayRestaurant, setDisplayRestaurant] = useState(false);

  const handleToggleDisplayAddress = () => {
    setDisplayAddress(!displayAddress);
    setDisplayRestaurant(false);
    handleEventualCareData({
      target: {
        name: "serviceIsRestaurant",
        value: false,
      },
    });
  };

  const handleToggleDisplayRestaurant = () => {
    setDisplayRestaurant(!displayRestaurant);
    setDisplayAddress(false);
    handleEventualCareData({
      target: {
        name: "serviceIsRestaurant",
        value: true,
      },
    });
  };

  const handleChangeRestaurant = (event) => {
    handleEventualCareData({
      target: {
        name: "serviceRestaurantAddressName",
        value: event.target.value,
      },
    });
  };

  const handleChangeRestaurantAddress = (event) => {
    handleEventualCareData({
      target: {
        name: "serviceRestaurantAddress",
        value: event.target.value,
      },
    });
  };

  const calculateDuration = (startTime, endTime) => {
    const start = new Date(startTime);
    const end = new Date(endTime);

    const differenceInHours = Math.abs(Math.round((end - start) / (1000 * 60 * 60)));
    return differenceInHours;
  };

  function validateTimes(startTime, endTime) {

    // Function to convert 12-hour format to 24-hour format
    const convertTo24HourFormat = (timeObj) => {
      let time12h;

      // Check if the input is a string or an object (like Moment.js object)
      if (typeof timeObj === "string") {
        time12h = timeObj;
      } else if (timeObj.format) {
        // Assuming it's a Moment.js object or similar
        time12h = timeObj.format("hh:mm A"); // Format to 12-hour string with AM/PM
      } else {
        // Handle other potential cases or throw an error
        console.error("Unsupported time format");
        return null;
      }

      const [time, modifier] = time12h.split(" ");
      let [hours, minutes] = time.split(":");

      if (hours === "12") {
        hours = "00";
      }

      if (modifier.toUpperCase() === "PM" && hours !== "12") {
        hours = parseInt(hours, 10) + 12;
      }

      return `${hours}:${minutes}`;
    };

    // Convert the 12-hour time format to 24-hour format
    const formattedStartTime = convertTo24HourFormat(startTime);
    const formattedEndTime = convertTo24HourFormat(endTime);

    // Check for null return from conversion function
    if (formattedStartTime === null || formattedEndTime === null) {
      return false;
    }

    // Parse the times
    const start = new Date(`1970-01-01T${formattedStartTime}`);
    const end = new Date(`1970-01-01T${formattedEndTime}`);


    // Calculate the time difference in hours
    let timeDifference = (end - start) / (1000 * 60 * 60);

    // Adjust for next day scenario
    if (timeDifference < 0) {
      timeDifference += 24;
    }

    if (timeDifference > 12) {
      return { isValid: false, errorType: "moreThan12Hours" };
    }

    if (timeDifference < 2) {
      return { isValid: false, errorType: "lessThan2Hours" };
    }

    return { isValid: true, errorType: null };
  }

  return (
    <div>
      <div className="sectionCard" style={{ marginTop: "10px" }}>
        {eventualCareData.serviceDays.map((serviceDay, index) => (
          <div
            key={index}
            className="sectionCard"
            style={{ marginTop: "10px" }}
          >
            <Typography
              sx={{
                ml: 2,
                flex: 1,
                fontFamily: "Fredoka One",
                textAlign: "left",
                color: "#393d72",
              }}
              variant="h6"
              component="div"
            >
              {`Elige la fecha, hora y duración del cuidado (Día ${
                index + 1
              }):`}
            </Typography>
            <div style={{ display: "flex", marginTop: "10px" }}>
              <div>
                <InputLabel id="day-label">Día</InputLabel>
                <DatePicker
                  value={serviceDay.day}
                  onChange={(date) =>
                    handleEventualCareData(
                      { target: { name: "day", value: date } },
                      index
                    )
                  }
                  format="DD/MM/YYYY"
                  inputFormat="DD/MM/YYYY"
                  minDate={adapter.date(new Date())}
                />
              </div>
              <div style={{ marginLeft: "10px" }}>
                <InputLabel id="startTime-label">Hora de inicio</InputLabel>
                <MobileTimePicker
                  value={serviceDay.startTime}
                  onChange={(time) => {
                    // Only perform validation if there is an existing endTime
                    handleEventualCareData({ target: { name: "error", value: "" } }, index);

                    if (serviceDay.endTime) {
                      const validationResult = validateTimes(time, serviceDay.endTime);
                      if (validationResult.isValid) {
                        // If valid, update the state
                        handleEventualCareData({ target: { name: "startTime", value: time } }, index);
                        handleEventualCareData({ target: { name: "duration",  value: calculateDuration(time,serviceDay.endTime)}}, index)
                        console.log(serviceDay.duration)
                      } else {
                        // Handle different types of validation errors
                        handleEventualCareData({ target: { name: "startTime", value: null } }, index);
                        handleEventualCareData({ target: { name: "error", value: validationResult.errorType } }, index);
                      }
                    } else {
                      // If startTime is not set, you may choose to handle this differently
                      // For example, you can update endTime, or show an error, etc.
                      // This example just updates endTime
                      handleEventualCareData(
                        { target: { name: "startTime", value: time } },
                        index
                      );
                    }
                  }}
                  minutesStep={10}
                  ampm={true}
                />
              </div>
              <div style={{ marginLeft: "10px" }}>
                <InputLabel id="endTime-label">Hora de fin</InputLabel>
                <MobileTimePicker
                  value={serviceDay.endTime}
                  onChange={(time) => {
                    handleEventualCareData({ target: { name: "error", value: "" } }, index);

                    // Validation is only necessary if startTime is already set
                    if (serviceDay.startTime) {
                      const validationResult = validateTimes(serviceDay.startTime, time);
                      if (validationResult.isValid) {
                        // If valid, update the state
                        handleEventualCareData({ target: { name: "endTime", value: time } }, index);
                        handleEventualCareData({ target: { name: "duration",  value: calculateDuration(serviceDay.startTime,time)}}, index)

                      } else {
                        handleEventualCareData({ target: { name: "endTime", value: null } }, index);
                        // Handle different types of validation errors
                        handleEventualCareData({ target: { name: "error", value: validationResult.errorType } }, index);
                      }
                    } else {
                      // If startTime is not set, you may choose to handle this differently
                      // For example, you can update endTime, or show an error, etc.
                      // This example just updates endTime
                      handleEventualCareData(
                        { target: { name: "endTime", value: time } },
                        index
                      );
                    }
                  }}
                  minutesStep={10}
                  ampm={true}
                  disabled={!serviceDay.startTime}
                />
              </div>
            </div>
            <div>
              <p>Selecciona un horario con al menos 3 horas de anticipación</p>
              {serviceDay.error === "moreThan12Hours" && (
                  <p style={{ color: "red" }}>El horario seleccionado no puede ser mayor a 12 horas</p>
                )}{serviceDay.error === "lessThan2Hours" && (
                  <p style={{ color: "red" }}>El horario seleccionado no puede ser menor a 2 horas</p>
              )}
            </div>
            {/* <div style={{ marginTop: "10px" }}>
              <FormControl variant="outlined" style={{ minWidth: 200 }}>
                <InputLabel htmlFor="duration-label">Duración</InputLabel>
                <Select
                  labelId="duration-label"
                  id="duration-select"
                  value={serviceDay.duration || ""}
                  onChange={(event) =>
                    handleEventualCareData(
                      {
                        target: { name: "duration", value: event.target.value },
                      },
                      index
                    )
                  }
                  label="Duración"
                  disabled={true}
                >
                  <MenuItem value={2}>2 horas</MenuItem>
                  <MenuItem value={3}>3 horas</MenuItem>
                  <MenuItem value={4}>4 horas</MenuItem>
                  <MenuItem value={5}>5 horas</MenuItem>
                  <MenuItem value={6}>6 horas</MenuItem>
                  <MenuItem value={7}>7 horas</MenuItem>
                  <MenuItem value={8}>8 horas</MenuItem>
                  <MenuItem value={9}>9 horas</MenuItem>
                  <MenuItem value={10}>10 horas</MenuItem>
                  <MenuItem value={11}>11 horas</MenuItem>
                  <MenuItem value={12}>12 horas</MenuItem>
                </Select>
              </FormControl>
            </div> */}
            {eventualCareData.serviceDays.length > 1 && (
              <Button
                onClick={() => removeServiceDay(index)}
                variant="contained"
                color="secondary"
                style={{
                  marginTop: "15px",
                }}
              >
                Eliminar día
              </Button>
            )}
          </div>
        ))}
        {eventualCareData.serviceDays.length < 7 && (
          <Button
            onClick={addServiceDay}
            variant="contained"
            style={{
              backgroundColor: "#6ac9d6",
              color: "white",
              marginTop: "15px",
            }}
          >
            Agregar día
          </Button>
        )}
      </div>

      <div className="sectionCard" style={{ marginTop: "10px" }}>
        <Typography
          sx={{
            ml: 2,
            flex: 1,
            fontFamily: "Fredoka One",
            textAlign: "left",
            color: "#393d72",
          }}
          variant="h6"
          component="div"
        >
          Selecciona para quien es el cuidado:
        </Typography>
        <div style={{ marginTop: "10px" }}>
          <InputLabel id="select-multiple-chip">Nombres:</InputLabel>
          <Select
            labelId="select-mutiple-chip"
            id="child-select"
            multiple
            value={eventualCareData.serviceFor || []}
            onChange={handleChangeChild}
            style={{ width: "50%" }}
            input={<OutlinedInput id="select-multiple-chip" label="Nombres:" />}
            renderValue={(selected) => (
              <Box sx={{ display: "flex", flexWrap: "wrap", gap: 0.5 }}>
                {selected.map((value) => (
                  <Chip
                    key={value}
                    label={value}
                    sx={{ backgroundColor: "#ffae00" }}
                  />
                ))}
              </Box>
            )}
            MenuProps={MenuProps}
          >
            {kids.map((kid) => (
              <MenuItem key={kid.name} value={kid.name}>
                {kid.name}
              </MenuItem>
            ))}
          </Select>
        </div>
      </div>
      <div className="sectionCard" style={{ marginTop: "10px" }}>
        <Typography
          sx={{
            ml: 2,
            flex: 1,
            fontFamily: "Fredoka One",
            textAlign: "left",
            color: "#393d72",
          }}
          variant="h6"
          component="div"
        >
          Selecciona la dirección del cuidado:
        </Typography>
        <div
          style={{
            alignItems: "center",
            marginTop: "10px",
          }}
        >
          <Chip
            label="Hogar"
            clickable
            color="primary"
            variant={displayAddress ? "filled" : "outlined"}
            onClick={handleToggleDisplayAddress}
            style={{ marginRight: "10px" }}
          />
          <Chip
            label="Restaurante/Salón de Fiestas"
            clickable
            color="primary"
            variant={displayRestaurant ? "filled" : "outlined"}
            onClick={handleToggleDisplayRestaurant}
          />
        </div>
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            marginTop: "10px",
          }}
        >
          {displayAddress && (
            <div>
              <InputLabel id="select-addresses">Direcciones:</InputLabel>
              <Select
                labelId="select-addresses"
                id="address-select"
                value={eventualCareData.serviceAddress}
                onChange={handleChangeAddress}
                label="Direcciones"
                style={{ width: "80%" }}
              >
                {addresses.map((address) => (
                    <MenuItem key={address.street} value={`${address.street}, ${address.neighborhood}, ${address.city}, ${address.state}`}>
                    {address.street}, {address.city}, {address.state}
                  </MenuItem>
                ))}
              </Select>
            </div>
          )}
          {displayRestaurant && (
            <div
              style={{
                display: "flex",
                flexDirection: "column",
                marginTop: "10px",
              }}
            >
              <TextField
                label="Nombre Salón/Restaurante"
                variant="outlined"
                style={{ marginBottom: "10px" }}
                value={eventualCareData.serviceRestaurantAddressName}
                onChange={handleChangeRestaurant}
              />
              <TextField
                label="Dirección"
                multiline
                rows={4}
                variant="outlined"
                value={eventualCareData.serviceRestaurantAddress}
                onChange={handleChangeRestaurantAddress}
              />
            </div>
          )}
        </div>
      </div>
      <div className="sectionCard" style={{ marginTop: "10px" }}>
        <Typography
          sx={{
            ml: 2,
            flex: 1,
            fontFamily: "Fredoka One",
            textAlign: "left",
            color: "#393d72",
          }}
          variant="h6"
          component="div"
        >
          Información adicional:
        </Typography>
        <div style={{ marginTop: "10px" }}>
          <TextField
            id="outlined-multiline-static"
            label="Información adicional"
            multiline
            rows={4}
            value={eventualCareData.serviceNotes || ""}
            variant="outlined"
            style={{ width: "80%" }}
            onChange={handleNoteChange}
            placeholder="Ej. Alergias, medicamentos, rutinas, etc."
          />
        </div>
      </div>
    </div>
  );
}
