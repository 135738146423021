/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useState } from "react";
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  CircularProgress,
} from "@mui/material";
import PhoneInput from "react-phone-input-2";
import "react-phone-input-2/lib/style.css";
import { parsePhoneNumberFromString } from 'libphonenumber-js';

import "./dialogStyles.css";
import { useSignup } from "../../hooks/useSignup";
import { Link, useNavigate } from "react-router-dom";

const SignUpDialog = ({ open, onClose }) => {
  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [phone, setPhone] = useState("");
  const [phoneError, setPhoneError] = useState("");

  const { error, signup } = useSignup();
  const [isLoading, setIsLoading] = useState(false); // Set loading state to false by default	
  const navigate = useNavigate();

  const handleSubmit = async (e) => {
    e.preventDefault();
    setIsLoading(true);

    // Validate phone number
    const phoneNumber = parsePhoneNumberFromString(phone, 'MX');
    if (!phoneNumber || !phoneNumber.isValid()) {
      setPhoneError("Número de teléfono no válido");
      setIsLoading(false);
      return;
    } else {
      setPhoneError("");
    }

    const success = await signup(email, password, `${firstName} ${lastName}`, phone);
    if (success) {
      navigate("/onboarding");
    } else {
      setIsLoading(false);
    }
  };

  return (
    <Dialog
      open={open}
      onClose={onClose}
      PaperProps={{
        style: {
          borderRadius: 15,
        },
      }}
    >
      <DialogTitle className="dialog-title-component">
        <p className="dialog-title-style">Crear Cuenta</p>
      </DialogTitle>
      <div className="dialog-subtitle-style">
        <p style={{ fontSize: "15px" }}>
          Completa los siguientes campos para crear tu cuenta
        </p>
      </div>

      <form onSubmit={handleSubmit}>
        <DialogContent style={{ fontFamily: "Fredoka One" }}>
          <div className="row form-group">
            <div className="col">
              <input
                type="text"
                className="form-control"
                placeholder="Nombre"
                required
                onChange={(e) => setFirstName(e.target.value)}
                value={firstName}
              />
            </div>
            <div className="col">
              <input
                type="text"
                className="form-control"
                placeholder="Apellido"
                required
                onChange={(e) => setLastName(e.target.value)}
                value={lastName}
              />
            </div>
          </div>
          <div className="form-group input-styles">
            <fieldset>
              <input
                type="email"
                className="form-control"
                name="email"
                placeholder="Email"
                required
                onChange={(e) => setEmail(e.target.value)}
                value={email}
              />
            </fieldset>
          </div>
          <div className="form-group input-styles">
            <PhoneInput
              country={"mx"} // Pre-select Mexico as the default country code
              value={phone}
              onChange={(phone) => setPhone(phone)}
              inputProps={{
                name: "phone",
                required: true,
                autoFocus: false,
              }}
              containerStyle={{
                width: "100%",
                height: "100%",
                border: "1px solid #ced4da",
                borderRadius: ".25rem",
                paddingLeft: "0",
              }}
              inputStyle={{
                width: "100%",
                height: "38px",
                border: "1px solid #ced4da",
                borderRadius: ".25rem",
                padding: ".375rem .75rem .375rem 3rem", // Adjusted padding to prevent hiding initial numbers
                fontSize: "1rem",
                fontFamily: "Fredoka One",
              }}
              buttonStyle={{
                border: "none",
                background: "transparent",
                paddingLeft: "0.75rem", // Adjusted padding to align with input
              }}
            />
            {phoneError && <p className="error" style={{ color: "red" }}>{phoneError}</p>}
          </div>
          <div className="form-group" style={{ paddingTop: "10px" }}>
            <fieldset>
              <input
                type="password"
                className="form-control"
                name="password"
                placeholder="Contraseña"
                required
                onChange={(e) => setPassword(e.target.value)}
                value={password}
              />
            </fieldset>
          </div>
          <div className="form-group" style={{ paddingTop: "20px" }}>
            <fieldset>
              <div className="form-check">
                <input
                  className="form-check-input"
                  type="checkbox"
                  id="gridCheck"
                  required
                />
                <label className="form-check-label" htmlFor="gridCheck">
                  Acepto los{" "}
                  <Link to="/terms-and-conditions" target="_blank">términos y condiciones</Link>
                </label>
              </div>
            </fieldset>
          </div>
          <div className="form-group">
            <fieldset>
              <div className="form-check">
                <input
                  className="form-check-input"
                  type="checkbox"
                  id="privacidadCheck"
                  required
                />
                <label className="form-check-label" htmlFor="privacidadCheck">
                  Acepto el <Link to="/privacy-notice" target="_blank">Aviso de privacidad</Link>
                </label>
              </div>
            </fieldset>
          </div>
          <div className="form-group">
            <fieldset>
              <div className="form-check">
                <input
                  className="form-check-input"
                  type="checkbox"
                  id="familyPoliciesCheck"
                  required
                />
                <label className="form-check-label" htmlFor="familyPoliciesCheck">
                  Acepto las <Link to="/family-policies" target="_blank">Políticas Familiares</Link>
                </label>
              </div>
            </fieldset>
          </div>
          

          {error && <p className="error" style={{ color: "red" }}>{error}</p>}
        </DialogContent>
        <DialogActions
          style={{
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
          }}
        >
          {isLoading ? (
            <CircularProgress color="secondary" /> // Pink spinner
          ) : (
            <button
              type="submit"
              id="signup-button"
              className="theme-btn btn-style-one login-button"
              style={{ padding: "10px 20px" }}
              disabled={isLoading} // Disable the button when loading
            >
              Crear Cuenta
            </button>
          )}
        </DialogActions>
      </form>
    </Dialog>
  );
};

export default SignUpDialog;
