/* eslint-disable no-unused-vars */
/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useEffect, useState } from "react";
import "bootstrap/dist/css/bootstrap.css";
import "bootstrap/dist/js/bootstrap.js";
import "./css/style.css";
import { AppBar, Box, Toolbar, Typography, Button } from "@mui/material";
import PhoneIcon from "@mui/icons-material/Phone";
import EmailIcon from "@mui/icons-material/Email";

import image1 from "./images/image-1.JPG";
import thumb1 from "./images/thumb-1.jpg";
import thumb2 from "./images/thumb-2.jpg";

import thumb_1 from "./images/thumb_1.JPG";
import thumb_2 from "./images/thumb_2.JPG";
import thumb_3 from "./images/thumb_3.JPG";

import tarifs from "./images/tarifas.png";

import WhatsAppIcon from "@mui/icons-material/WhatsApp";

import MenuNavbar from "./components/MenuNavbar";
import SignUpDialog from "./components/SignUpDialog";
import LoginDialog from "./components/LoginDialog";
import ImageCard from "./components/ImageCard";
import ResetPasswordDialog from "./components/ResetPasswordDialog";
import { Link } from "react-router-dom";
import LanguageToggle from "../WebApp/components/LanguageToggle";
import { useTranslation } from "react-i18next";
export default function Landing() {
  const [imgUrl, setImgUrl] = useState("");
  const [width, setWidth] = useState(window.innerWidth);

  const [openSignUp, setOpenSignUp] = useState(false);
  const [openLogin, setOpenLogin] = useState(false);

  const [openResetPassword, setOpenResetPassword] = useState(false);
  const handleResetPasswordClick = () => {
    setOpenLogin(false);
    setOpenResetPassword(true);
  };
  const {t} = useTranslation();

  useEffect(() => {
    const mediaQuery = window.matchMedia("(max-width: 768px)");
    const handleMediaQueryChange = (mediaQuery) => {
      if (mediaQuery.matches) {
        setImgUrl(require("./images/Banner_Slider_p3.png")); /*Mobile Slider*/
      } else {
        setImgUrl(require("./images/Banner_Slider_p.png")); /*Desktop Slider*/
      }
    };

    handleMediaQueryChange(mediaQuery);
    mediaQuery.addListener(handleMediaQueryChange);

    return () => mediaQuery.removeListener(handleMediaQueryChange);
  }, []);

  useEffect(() => {
    const handleResize = () => setWidth(window.innerWidth);
    window.addEventListener("resize", handleResize);
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  return (
    <div>
      <div className="page-wrapper">
        <header className="main-header header-style-two">
          {/* Header Top */}
          <div className="header-top">
            <Box sx={{ flexGrow: 1 }}>
              <AppBar position="static" sx={{ backgroundColor: "#393d72" }}>
                <Toolbar>
                  <Box sx={{ display: 'flex', alignItems: 'left', flexGrow: 1 }}>
                    <LanguageToggle />
                  </Box>
                  {/* <Typography
                    variant="h6"
                    component="div"
                    sx={{ flexGrow: 1, textAlign: "left" }}
                  >
                  
                    <Button
                      color="inherit"
                      startIcon={
                        <EmailIcon style={{ color: "rgb(255,174,0)" }} />
                      }
                      href="mailto:contacto@nannapp.com.mx"
                    >
                      
                    </Button>
                  </Typography> */}
                  <Typography
                    variant="h6"
                    component="div"
                    sx={{ flexGrow: 1, textAlign: "right" }}
                  >
                    <Button color="inherit" onClick={() => setOpenSignUp(true)}>
                      {t('header.createAccount')}
                    </Button>
                    <SignUpDialog
                      open={openSignUp}
                      onClose={() => setOpenSignUp(false)}
                    />
                    <Button color="inherit" onClick={() => setOpenLogin(true)}>
                      {t('header.logIn')}
                    </Button>
                    <LoginDialog
                      open={openLogin}
                      onClose={() => setOpenLogin(false)}
                      onResetPasswordClick={handleResetPasswordClick} // Added this line
                    />
                    <ResetPasswordDialog
                      open={openResetPassword}
                      onClose={() => setOpenResetPassword(false)}
                    />
                  </Typography>
                </Toolbar>
              </AppBar>
            </Box>
          </div>
          {/* Header Top */}
          {/* Header Upper */}
          <div className="header-lower">
            <MenuNavbar />
          </div>
          {/*End Header Upper*/}
          {/* Sticky Header  */}
          <div className="sticky-header">
            <div className="auto-container clearfix">
              {/*Logo*/}
              <div className="logo pull-left">
                <a href="index.html" title>
                  <img src="images/logo.png" alt="" title />
                </a>
              </div>
              {/*Right Col*/}
              <div className="pull-right">
                {/* Main Menu */}
                <nav className="main-menu">
                  <div className="navbar-collapse show collapse clearfix">
                    <ul className="navigation clearfix">
                      <li className="current dropdown">
                        <Link to='/landing'>Home</Link>
                        <ul>
                          <li>
                            <a href="index.html">Home page 01</a>
                          </li>
                          <li>
                            <a href="index-2.html">Home page 02</a>
                          </li>
                          <li className="dropdown">
                            <a href="index.html">Header Styles</a>
                            <ul>
                              <li>
                                <a href="index.html">Header Style One</a>
                              </li>
                              <li>
                                <a href="index-2.html">Header Style Two</a>
                              </li>
                            </ul>
                          </li>
                        </ul>
                      </li>
                      {/* <li>
                        <a href="about.html">About Us</a>
                      </li>
                      <li className="dropdown">
                        <a href="events.html">Events</a>
                        <ul>
                          <li>
                            <a href="events.html">Events</a>
                          </li>
                          <li>
                            <a href="event-detail.html">Event Detail</a>
                          </li>
                        </ul>
                      </li>
                      <li className="dropdown">
                        <a href="programs.html">Programs</a>
                        <ul>
                          <li>
                            <a href="numbers-matching.html">Numbers Matching</a>
                          </li>
                          <li>
                            <a href="paper-plates-art.html">Paper Plates Art</a>
                          </li>
                          <li>
                            <a href="create-cartoon-egg.html">
                              Create Egg Cartoon
                            </a>
                          </li>
                          <li>
                            <a href="cooking-fun-cake.html">Cooking Fun Cake</a>
                          </li>
                          <li>
                            <a href="super-colors-game.html">
                              Super Colors Game
                            </a>
                          </li>
                          <li>
                            <a href="learning-disciplines.html">
                              Learning Disciplines
                            </a>
                          </li>
                        </ul>
                      </li>
                      <li>
                        <a href="gallery.html">Gallery</a>
                      </li>
                      <li className="dropdown">
                        <a href="news.html">Our News</a>
                        <ul>
                          <li>
                            <a href="news.html">Our News</a>
                          </li>
                          <li>
                            <a href="news-single.html">News Single</a>
                          </li>
                        </ul>
                      </li>
                      <li>
                        <a href="contact.html">Contact</a>
                      </li> */}
                    </ul>
                  </div>
                </nav>
                {/* Main Menu End*/}
              </div>
            </div>
          </div>
          {/* End Sticky Menu */}
        </header>
        {/* End Main Header */}
        {/*Main Slider*/}
        <section className="main-slider style-two">
          <div
            style={{
              width: "100%",
              height: "700px",
              backgroundImage: `url(${imgUrl})`,
              backgroundPosition: "center",
              backgroundRepeat: "no-repeat",
              backgroundSize: "cover",
              display: "flex",
              flexDirection: "column",
              justifyContent: "center",
              alignItems: "center",
              textAlign: "center",
            }}
          >
            <div>
              <h2
                className="title"
                style={{
                  fontSize: width <= 425 ? "25px" : "30px",
                  color: "rgb(255, 72, 109)",
                  marginBottom: "16px",
                }}
              >
                {t('mainSlider.title')}
              </h2>
            </div>
            <h1
              style={{
                fontSize: width <= 425 ? "45px" : "55px",
                color: "#393d72",
                marginBottom: "32px",
                fontWeight: "400",
              }}
            >
              {t('mainSlider.subtitle')}
            </h1>
            <button
              className="theme-btn btn-style-one"
              onClick={() => window.location.href = "https://api.whatsapp.com/send?phone=5215521296063&text=Hola!%20Quisiera%20consultar%20las%20tarifas%20de%20los%20servicios%20%20%F0%9F%98%80"}
              // onClick={() => setOpenSignUp(true)} Remove when publish
            >
              {t('services.contactUs')}
            </button>
          </div>
        </section>
        {/*End Main Slider*/}

        <section className="about-section">
          <div
            className="parallax-scene parallax-scene-1 anim-icons"
            style={{
              transform: "translate3d(0px, 0px, 0px) rotate(0.0001deg)",
              transformStyle: "preserve-3d",
              backfaceVisibility: "hidden",
              pointerEvents: "none",
            }}
          >
            <span
              data-depth="0.50"
              className="parallax-layer icon icon-star"
              style={{
                transform: "translate3d(0px, 0px, 0px)",
                transformStyle: "preserve-3d",
                backfaceVisibility: "hidden",
                position: "relative",
                display: "block",
                left: "0px",
                top: "0px",
              }}
            />
            <span
              data-depth="0.40"
              className="parallax-layer icon icon-balloon"
              style={{
                transform: "translate3d(0px, 0px, 0px)",
                transformStyle: "preserve-3d",
                backfaceVisibility: "hidden",
                position: "absolute",
                display: "block",
                left: "0px",
                top: "0px",
              }}
            />
            <span
              data-depth="0.50"
              className="parallax-layer icon icon-cloud"
              style={{
                transform: "translate3d(0px, 0px, 0px)",
                transformStyle: "preserve-3d",
                backfaceVisibility: "hidden",
                position: "absolute",
                display: "block",
                left: "0px",
                top: "0px",
              }}
            />
            <span
              data-depth="0.40"
              className="parallax-layer icon icon-star-2"
              style={{
                transform: "translate3d(0px, 0px, 0px)",
                transformStyle: "preserve-3d",
                backfaceVisibility: "hidden",
                position: "absolute",
                display: "block",
                left: "0px",
                top: "0px",
              }}
            />
            <span
              data-depth="0.50"
              className="parallax-layer icon icon-paper-plane"
              style={{
                transform: "translate3d(0px, 0px, 0px)",
                transformStyle: "preserve-3d",
                backfaceVisibility: "hidden",
                position: "absolute",
                display: "block",
                left: "0px",
                top: "0px",
              }}
            />
          </div>
          <div className="auto-container">
            <div className="row">
              {/* Content Column */}
              <div className="content-column col-lg-6 col-md-12 col-sm-12">
                <div className="sec-title">
                  <span className="title"></span>
                  <h2>{t('aboutSection.motto')}</h2>
                </div>
                <div className="text">
                  {t('aboutSection.description')}
                </div>
                <div className="btn-box">
                  <a
                    className="theme-btn btn-style-one"
                    data-toggle="modal"
                    data-target="#createModal"
                    style={{ color: "white" }}
                    href="https://api.whatsapp.com/send?phone=5215521296063&text=Hola!%20Quisiera%20consultar%20las%20tarifas%20de%20los%20servicios%20%20%F0%9F%98%80"                    // onClick={() => setOpenSignUp(true)} remove when publish
                  >
                    {t('services.contactUs')}
                  </a>
                </div>
              </div>
              <div className="image-column col-lg-6 col-md-12 col-sm-12">
                <div className="inner-column">
                  <figure
                    className="image wow fadeInRight"
                    style={{ visibility: "visible" }}
                  >
                    <img src={image1} alt="" />
                  </figure>
                  <div
                    className="count-outer count-box wow zoomIn"
                    data-wow-delay="600ms"
                    style={{ visibility: "visible" }}
                  >
                    <h4 className="counter-title">Trusted by</h4>
                    <span
                      className="count-text"
                      data-speed={5000}
                      data-stop={4890}
                    >
                      +1000
                    </span>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
        {/*End About Section Two */}

        {/*YouTube Video Section */}
        <section style={{
          padding: '120px 0',
          backgroundColor: '#f9f9f9'
        }}>
          <div className="auto-container">
            <div className="sec-title text-center">
              <span className="title">{t('video.title')}</span>
              <h2>{t('video.subtitle')}</h2>
            </div>
            <div style={{
              position: 'relative',
              paddingBottom: '56.25%', // 16:9 aspect ratio
              height: 0,
              overflow: 'hidden',
              maxWidth: '100%',
              borderRadius: '20px', // Added border radius
              boxShadow: '0 4px 8px rgba(0, 0, 0, 0.1)' // Optional: adds a subtle shadow
            }}>
              <iframe
                style={{
                  position: 'absolute',
                  top: 0,
                  left: 0,
                  width: '100%',
                  height: '100%',
                  borderRadius: '20px' // Added border radius to iframe as well
                }}
                src="https://www.youtube.com/embed/te8TcGeN-8Q?si=XDwWucYFyu7elchI"
                title="NANNAPP Tu Nanny de la Guarda"
                frameBorder="0"
                allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                allowFullScreen
              ></iframe>
            </div>
          </div>
        </section>

        {/*Cards Section */}
        <section className="program-section">
          <div className="auto-container">
            <div className="sec-title text-center">
              <span className="title">
              {t('programSection.title')}
              </span>
              <h2>{t('programSection.subtitle')}</h2>
            </div>
            <div className="row">
              {/* Program Block */}
              <div className="program-block col-lg-4 col-md-6 col-sm-12 wow fadeInUp">
                <div className="inner-box">
                  <div className="image-box">
                    <figure className="image">
                      <a>
                        <img src={thumb_1} alt="" />
                      </a>
                    </figure>
                  </div>
                  <div className="lower-content">
                    <h4>
                      <a>
                      {t('programSection.A1')}
                      </a>
                    </h4>
                    <div className="text">
                    {t('programSection.A2')}
                    </div>
                    <ul className="program-info">
                      {/* <li><span>Age:</span> 2-3 years</li>
                                <li><span>Time:</span> 9:00-11:00</li> */}
                    </ul>
                  </div>
                </div>
              </div>
              {/* Program Block */}
              <div
                className="program-block col-lg-4 col-md-6 col-sm-12 wow fadeInUp"
                data-wow-delay="400ms"
              >
                <div className="inner-box">
                  <div className="image-box">
                    <figure className="image">
                      <a>
                        <img src={thumb_2} alt="" />
                      </a>
                    </figure>
                  </div>
                  <div className="lower-content">
                    <h4>
                      <a>{t('programSection.B1')}</a>
                    </h4>
                    <div className="text">
                      {t('programSection.B2')}
                    </div>
                    <ul className="program-info">
                      {/* <li><span>Age:</span> 2-3 years</li>
                                <li><span>Time:</span> 9:00-11:00</li> */}
                    </ul>
                  </div>
                </div>
              </div>
              {/* Program Block */}
              <div
                className="program-block col-lg-4 col-md-6 col-sm-12 wow fadeInUp"
                data-wow-delay="800ms"
              >
                <div className="inner-box">
                  <div className="image-box">
                    <figure className="image">
                      <a>
                        <img src={thumb_3} alt="" />
                      </a>
                    </figure>
                  </div>
                  <div className="lower-content">
                    <h4>
                      <a>{t('programSection.C1')}</a>
                    </h4>
                    <div className="text">
                      {t('programSection.C2')}
                    </div>
                    <ul className="program-info">
                      {/* <li><span>Age:</span> 2-3 years</li>
                                <li><span>Time:</span> 9:00-11:00</li> */}
                    </ul>
                  </div>
                </div>
              </div>
            </div>
            <div className="btn-box">
              <a
                className="theme-btn btn-style-one"
                href="https://api.whatsapp.com/send?phone=5215521296063&text=Hola!%20Quisiera%20consultar%20las%20tarifas%20de%20los%20servicios%20%20%F0%9F%98%80"
                // onClick={() => setOpenSignUp(true)}
              >
                {t('services.contactUs')}
              </a>
            </div>
          </div>
        </section>
        {/*EndCards Section */}
        {/* Circles Section */}
        <section className="coming-soon-section">
          <div className="parallax-scene parallax-scene-2 anim-icons">
            <span
              data-depth="0.40"
              className="parallax-layer icon icon-rainbow-2"
            />
            <span
              data-depth="0.50"
              className="parallax-layer icon icon-sparrow-2"
            />
            <span
              data-depth="0.30"
              className="parallax-layer icon icon-star-3"
            />
            <span
              data-depth="0.40"
              className="parallax-layer icon icon-balloon-2"
            />
          </div>
          <div className="auto-container">
            <div className="sec-title light text-center">
              <span className="title">{t('ourServices.our')}</span>
              <h2>{t('ourServices.ourServices')}</h2>
            </div>
            <div className="row justify-content-center">
              {/* Offer Block */}
              <div className="offer-block col-xl-2 col-lg-3 col-md-6 col-sm-12">
                <div className="inner-box">
                  <div
                    className="icon-box wow rollIn"
                    style={{ visibility: "visible" }}
                  >
                    <span className="icon flaticon-teddy-bear-1" />
                  </div>
                  <h5>
                    <a style={{ color: "aliceblue" }}>
                      {t('ourServices.service1')}
                    </a>
                  </h5>
                </div>
              </div>
              {/* Offer Block */}
              <div className="offer-block col-xl-2 col-lg-3 col-md-6 col-sm-12">
                <div className="inner-box">
                  <div
                    className="icon-box wow rollIn"
                    data-wow-delay="300ms"
                    style={{ visibility: "visible" }}
                  >
                    <span className="icon flaticon-small-calendar" />
                  </div>
                  <h5>
                    <a style={{ color: "aliceblue" }}>
                    {t('ourServices.service2')}
                    </a>
                  </h5>
                </div>
              </div>
              {/* Offer Block */}
              <div className="offer-block col-xl-2 col-lg-3 col-md-6 col-sm-12">
                <div className="inner-box">
                  <div
                    className="icon-box wow rollIn"
                    data-wow-delay="600ms"
                    style={{ visibility: "visible" }}
                  >
                    <span className="icon flaticon-board" />
                  </div>
                  <h5>
                    <a style={{ color: "aliceblue" }}>
                    {t('ourServices.service3')} <br/>
                    {t('ourServices.service3A')}
                    </a>
                  </h5>
                </div>
              </div>
              {/* Offer Block */}
              <div className="offer-block col-xl-2 col-lg-3 col-md-6 col-sm-12">
                <div className="inner-box">
                  <div
                    className="icon-box wow rollIn"
                    data-wow-delay="0ms"
                    style={{ visibility: "visible" }}
                  >
                    <span className="icon flaticon-horse" />
                  </div>
                  <h5>
                    <a style={{ color: "aliceblue" }} tyle="color: aliceblue;">
                    {t('ourServices.service4')}
                    </a>
                  </h5>
                </div>
              </div>
              {/* Offer Block */}
              <div className="offer-block col-xl-2 col-lg-3 col-md-6 col-sm-12">
                <div className="inner-box">
                  <div
                    className="icon-box wow rollIn"
                    data-wow-delay="300ms"
                    style={{ visibility: "visible" }}
                  >
                    <span className="icon flaticon-paper-plane" />
                  </div>
                  <h5>
                    <a style={{ color: "aliceblue" }} tyle="color: aliceblue;">
                    {t('ourServices.service5')}
                    </a>
                  </h5>
                </div>
              </div>
              {/* Offer Block */}
              <div className="offer-block col-xl-2 col-lg-3 col-md-6 col-sm-12">
                <div className="inner-box">
                  <div
                    className="icon-box wow rollIn"
                    data-wow-delay="600ms"
                    style={{ visibility: "visible" }}
                  >
                    <span className="icon   flaticon-parents" />
                  </div>
                  <h5>
                    <a style={{ color: "aliceblue" }}>
                    {t('ourServices.service6')}
                    </a>
                  </h5>
                </div>
              </div>
              {/* Offer Block */}
              <div className="offer-block col-xl-2 col-lg-3 col-md-6 col-sm-12">
                <div className="inner-box">
                  <div
                    className="icon-box wow rollIn"
                    data-wow-delay="600ms"
                    style={{ visibility: "visible" }}
                  >
                    <span className="icon   flaticon-star-2" />
                  </div>
                  <h5>
                    <a style={{ color: "aliceblue" }}>
                    {t('ourServices.service7')}
                    </a>
                  </h5>
                </div>
              </div>
            </div>
          </div>
        </section>
        
        {/* Testimonial Section */}
        <section className="testimonial-section alternate">
          <div className="auto-container">
            <div className="sec-title text-center">
              <div className="title">
              {t('testimonial.protectionTitle')}
              </div>
              <h3>
              {t('testimonial.insurancePolicy')}
              </h3>
            </div>
            <div className="testimonial-carousel owl-carousel owl-theme">
              {/* Testimonial Block */}
              <div className="testimonial-block">
                <div className="inner-box">
                  <div className="content">
                    <div className="text">
                      Excelente servicio! Es la primera vez que lo contrato y la
                      verdad estuvo todo perfecto! Edna, la Nanny que estuvo con
                      nosotros, fue puntual, súper amable, atenta y estuvo
                      siempre al pendiente de mi bebé.
                    </div>
                  </div>
                  <div className="info-box">
                    <div className="thumb">
                      <img src={thumb1} alt="" />
                    </div>
                    <h6 className="name">Paola Covarrubias</h6>
                    <span className="designation">Madre</span>
                  </div>
                </div>
              </div>
              {/* Testimonial Block */}
              <div className="testimonial-block style-two">
                <div className="inner-box">
                  <div className="content">
                    <div className="text">
                      Me gusto mucho el cuidado de Dani con mi bebé de 3 meses,
                      es muy profesional y cariñosa, contrate el servicio
                      nocturno y dormí tranquila gracias a la experiencia que
                      Dani demostró.
                    </div>
                  </div>
                  <div className="info-box">
                    <div className="thumb">
                      <img src={thumb2} alt="" />
                    </div>
                    <h6 className="name">Naye Bezares</h6>
                    <span className="designation">Madre</span>
                  </div>
                </div>
              </div>
              {/* Testimonial Block */}
              <div className="testimonial-block">
                <div className="inner-box">
                  <div className="content">
                    <div className="text">
                      Muchas gracias. Servicio extremadamente profesional y
                      cariñoso de Jackie, a quien no dudaría en solicitar de
                      nuevo. Pero también me siento muy feliz de confiar en
                      cualquiera del equipo Nannapp. Recomiendo al 100 % esta
                      aplicación, que brinda un excelente servicio con
                      tranquilidad. Gracias, súper profesionales y un servicio
                      fantástico!
                    </div>
                  </div>
                  <div className="info-box">
                    <div className="thumb">
                      <img src={thumb1} alt="" />
                    </div>
                    <h6 className="name">Natasha Burgess</h6>
                    <span className="designation">Madre</span>
                  </div>
                </div>
              </div>
              {/* Testimonial Block */}
              <div className="testimonial-block style-two">
                <div className="inner-box">
                  <div className="content">
                    <div className="text">
                      Muchas gracias a mi querida Nadja por su gran apoyo para
                      cuidar a mis nietos, es una chica cariñosa, paciente y muy
                      confiable. Quedé encantada ! Gracias Nannapp
                    </div>
                  </div>
                  <div className="info-box">
                    <div className="thumb">
                      <img src={thumb2} alt="" />
                    </div>
                    <h6 className="name">Jacky Ramo</h6>
                    <span className="designation">Madre</span>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
        {/*End Testimonial Section */}

        {/*Cards Section */}
        <section className="program-section">
          <div className="auto-container">
            <div className="sec-title text-center">
              <span className="title">{t('contact.packages')}</span>
              <h2>{t('contact.ps')}</h2>
            </div>

            <div
              style={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <div
                style={{
                  width: "200px",
                  padding: "10px",
                  boxShadow: "0 0 10px rgba(0,0,0,0.1)",
                  borderRadius: "5px",
                  textAlign: "center",
                  backgroundColor: "white", // WhatsApp gray
                }}
              >
                <a
                  href="https://api.whatsapp.com/send?phone=5215521296063&text=Hola!%20Quisiera%20consultar%20las%20tarifas%20de%20los%20servicios%20%20%F0%9F%98%80"
                  target="_blank"
                  rel="noopener noreferrer"
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    alignItems: "center",
                    textDecoration: "none",
                    color: "inherit",
                  }}
                >
                  <WhatsAppIcon
                    style={{ color: "#25D366", fontSize: "32px" }}
                  />
                  <div style={{ marginTop: "10px" }}>
                  {t('contact.contactWa')}
                  </div>
                </a>
              </div>
            </div>
            <div className="btn-box">
              <a
                className="theme-btn btn-style-one"
                href="https://api.whatsapp.com/send?phone=5215521296063&text=Hola!%20Quisiera%20consultar%20las%20tarifas%20de%20los%20servicios%20%20%F0%9F%98%80"
                // onClick={() => setOpenSignUp(true)}
              >
                {t('contact.contact')}
              </a>
            </div>
          </div>
        </section>

        {/*EndCards Section */}
        {/* Testimonial Section */}
        <section className="testimonial-section alternate">
          <div className="auto-container">
            <div className="sec-title text-center">
              <div className="title">
              {t('experience.weHaveCaredFor')}
              </div>
              <h2>{t('experience.yearsMakingFamiliesHappy')}</h2>
            </div>
            <div className="testimonial-carousel owl-carousel owl-theme">
              {/* Testimonial Block */}
              <div className="testimonial-block">
                <div className="inner-box">
                  <div className="content">
                    <div className="text">
                      Excelente servicio! Es la primera vez que lo contrato y la
                      verdad estuvo todo perfecto! Edna, la Nanny que estuvo con
                      nosotros, fue puntual, súper amable, atenta y estuvo
                      siempre al pendiente de mi bebé.
                    </div>
                  </div>
                  <div className="info-box">
                    <div className="thumb">
                      <img src={thumb1} alt="" />
                    </div>
                    <h6 className="name">Paola Covarrubias</h6>
                    <span className="designation">Madre</span>
                  </div>
                </div>
              </div>
              {/* Testimonial Block */}
              <div className="testimonial-block style-two">
                <div className="inner-box">
                  <div className="content">
                    <div className="text">
                      Me gusto mucho el cuidado de Dani con mi bebé de 3 meses,
                      es muy profesional y cariñosa, contrate el servicio
                      nocturno y dormí tranquila gracias a la experiencia que
                      Dani demostró.
                    </div>
                  </div>
                  <div className="info-box">
                    <div className="thumb">
                      <img src={thumb2} alt="" />
                    </div>
                    <h6 className="name">Naye Bezares</h6>
                    <span className="designation">Madre</span>
                  </div>
                </div>
              </div>
              {/* Testimonial Block */}
              <div className="testimonial-block">
                <div className="inner-box">
                  <div className="content">
                    <div className="text">
                      Muchas gracias. Servicio extremadamente profesional y
                      cariñoso de Jackie, a quien no dudaría en solicitar de
                      nuevo. Pero también me siento muy feliz de confiar en
                      cualquiera del equipo Nannapp. Recomiendo al 100 % esta
                      aplicación, que brinda un excelente servicio con
                      tranquilidad. Gracias, súper profesionales y un servicio
                      fantástico!
                    </div>
                  </div>
                  <div className="info-box">
                    <div className="thumb">
                      <img src={thumb1} alt="" />
                    </div>
                    <h6 className="name">Natasha Burgess</h6>
                    <span className="designation">Madre</span>
                  </div>
                </div>
              </div>
              {/* Testimonial Block */}
              <div className="testimonial-block style-two">
                <div className="inner-box">
                  <div className="content">
                    <div className="text">
                      Muchas gracias a mi querida Nadja por su gran apoyo para
                      cuidar a mis nietos, es una chica cariñosa, paciente y muy
                      confiable. Quedé encantada ! Gracias Nannapp
                    </div>
                  </div>
                  <div className="info-box">
                    <div className="thumb">
                      <img src={thumb2} alt="" />
                    </div>
                    <h6 className="name">Jacky Ramo</h6>
                    <span className="designation">Madre</span>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
        {/*End Testimonial Section */}
        {/*Main Footer*/}
        <footer className="main-footer">
          <div className="anim-icons">
            <span
              className="icon icon-sparrow wow zoomIn"
              data-wow-delay="400ms"
            />
            <span
              className="icon icon-rainbow-2 wow zoomIn"
              data-wow-delay="800ms"
            />
            <span className="icon icon-star-3" />
            <span className="icon icon-star-3 two" />
            <span className="icon icon-sun" />
            <span
              className="icon icon-plane wow zoomIn"
              data-wow-delay="1200ms"
            />
          </div>
          {/*Scroll to top*/}
          <div className="scroll-to-top scroll-to-target" data-target="html">
            <span className="icon icon-arrow-up" />
          </div>
          {/*footer upper*/}
          <div className="footer-upper">
            <div className="auto-container">
              <div className="row clearfix">
                {/*Big Column*/}
                <div className="big-column col-xl-5 col-lg-12 col-md-12 col-sm-12">
                  <div className="row clearfix">
                    {/*Footer Column*/}
                    <div className="footer-column col-lg-6 col-md-6 col-sm-12">
                      <div className="footer-widget logo-widget">
                        <div className="logo">
                          {/* <a href="index.html"><img src="images/footer-logo.png" alt="" /></a> */}
                        </div>
                        <div className="text">
                        {t('footer.footerText')}
                        </div>
                      </div>
                    </div>
                    {/*Footer Column*/}
                    <div className="footer-column col-lg-5 col-md-6 col-sm-12">
                      <div className="footer-widget contact-widget">
                        <h4 className="widget-title">{t('footer.contact')}</h4>
                        <div className="widget-content">
                          <ul className="contact-info">
                            <li>
                              <a href="mailto:contacto@nannapp.com.mx">
                                <span className="fa fa-envelope" />
                                contacto@nannapp.com.mx
                              </a>
                            </li>
                            <li>
                              <span className="fa fa-map" /> Santa Fe, Ciudad de
                              México {" "}
                            </li>
                          </ul>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                {/*Big Column*/}
                <div className="big-column col-xl-7 col-lg-12 col-md-12 col-sm-12">
                  <div className="row clearfix">
                    
                    <div className="footer-column col-xl-3 col-lg-3 col-md-6 col-sm-4">
                      <div className="footer-widget links-widget">
                        <h4 className="widget-title">Links</h4>
                        <div className="widget-content">
                          <ul className="list">
                            <li>
                              <Link to="/terms-and-conditions">{t('footer.termsAndConditions')}</Link>
                            </li>
                            <li>
                              <Link to="/privacy-notice">{t('footer.privacyNotice')}</Link>
                            </li>
                            <li>
                              <Link to="/family-policies">{t('footer.familyPolicies')}</Link>
                            </li>
                            <li>
                              <Link to="/nanny-policies">{t('footer.nannyPolicies')}</Link>
                            </li>
                          </ul>
                        </div>
                      </div>
                    </div>
                    
                    {/*
                    <div className="footer-column col-xl-5 col-lg-6 col-md-12 col-sm-12">
                      <div className="footer-widget newsletter-widget">
                        <h4 className="widget-title">Newsletter</h4>
                        <div className="newsletter-form">
                          <form method="post" action="contact.html">
                            <div className="form-group">
                              <button
                                type="submit"
                                className="theme-btn btn-style-one"
                              >
                                Subscribe
                              </button>
                            </div>
                          </form>
                        </div>
                      </div>
                    </div>
                    */}
                  </div>
                </div>
              </div>
            </div>
          </div>
          {/*Footer Bottom*/}
          <div className="footer-bottom">
            <div className="auto-container">
              <ul className="social-icon-colored">
                <li>
                  <a href="https://www.instagram.com/nannappmexico/">
                    <span className="fab fa-instagram" />
                  </a>
                </li>
              </ul>
              <div className="copyright"> {t('footer.copyright')}</div>
            </div>
          </div>
        </footer>
        {/*End Main Footer*/}
      </div>
    </div>
  );
}
