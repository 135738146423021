import React, { useState, useEffect } from 'react';
import {
  AppBar,
  Toolbar,
  Typography,
  IconButton,
  Card,
  CardContent,
  Grid,
  TextField,
  Button,
  Avatar,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
} from '@mui/material';
import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos';
import AddCircleOutlineIcon from '@mui/icons-material/AddCircleOutline';
import DeleteIcon from '@mui/icons-material/Delete';
import { useNavigate } from 'react-router-dom';
import { useAuthContext } from '../../hooks/useAuthContext';
import { doc, updateDoc, arrayUnion, arrayRemove, getDoc } from 'firebase/firestore';
import { db } from '../../firebase/config';

const ManageChildrenScreen = () => {
  const { user } = useAuthContext();
  const navigate = useNavigate();
  const [children, setChildren] = useState([]);
  const [openDialog, setOpenDialog] = useState(false);
  const [newChild, setNewChild] = useState({ name: '', birthdate: '' });
  const [deleteDialogOpen, setDeleteDialogOpen] = useState(false);
  const [childToDelete, setChildToDelete] = useState(null);

  useEffect(() => {
    const fetchChildren = async () => {
      const userDoc = doc(db, 'users', user.uid);
      const userData = await getDoc(userDoc);
      if (userData.exists()) {
        setChildren(userData.data().kids || []);
      }
    };
    fetchChildren();
  }, [user.uid]);

  const handleOpenDialog = () => {
    setOpenDialog(true);
  };

  const handleCloseDialog = () => {
    setOpenDialog(false);
    setNewChild({ name: '', birthdate: '' });
  };

  const handleAddChild = async () => {
    if (isValidForm()) {
      const userDoc = doc(db, 'users', user.uid);
      await updateDoc(userDoc, { kids: arrayUnion(newChild) });
      setChildren([...children, newChild]);
      handleCloseDialog();
    }
  };

  const handleDeleteChild = async (childToDelete) => {
    const userDoc = doc(db, 'users', user.uid);
    await updateDoc(userDoc, { kids: arrayRemove(childToDelete) });
    setChildren(children.filter(child => child.name !== childToDelete.name));
  };

  const handleOpenDeleteDialog = (child) => {
    setChildToDelete(child);
    setDeleteDialogOpen(true);
  };

  const handleCloseDeleteDialog = () => {
    setDeleteDialogOpen(false);
    setChildToDelete(null);
  };

  const handleConfirmDelete = async () => {
    if (childToDelete) {
      await handleDeleteChild(childToDelete);
      handleCloseDeleteDialog();
    }
  };

  const isValidDate = (dateString) => {
    const regex = /^\d{4}-\d{2}-\d{2}$/;
    if (!regex.test(dateString)) return false;
    const date = new Date(dateString);
    const timestamp = date.getTime();
    if (typeof timestamp !== 'number' || Number.isNaN(timestamp)) {
      return false;
    }
    return date.toISOString().startsWith(dateString);
  };

  const isValidForm = () => {
    return newChild.name.trim() !== '' && isValidDate(newChild.birthdate);
  };

  return (
    <div style={{ backgroundColor: '#f9f5f0', minHeight: '100vh', overflow: 'auto' }}>
      <AppBar position="static" sx={{ backgroundColor: '#393d72' }}>
        <Toolbar sx={{ display: 'flex', justifyContent: 'space-between' }}>
          <IconButton
            color="inherit"
            aria-label="back"
            onClick={() => navigate('/')}
            sx={{
              borderRadius: '50%',
              backgroundColor: '#ffae00',
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
            }}
          >
            <ArrowBackIosIcon />
          </IconButton>
          <Typography variant="h6" style={{ fontFamily: 'Fredoka One', color: 'white' }}>
            Tus Pequeñ@s
          </Typography>
          <IconButton
            color="inherit"
            aria-label="add"
            onClick={handleOpenDialog}
            sx={{
              borderRadius: '50%',
              backgroundColor: '#ffae00',
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
            }}
          >
            <AddCircleOutlineIcon />
          </IconButton>
        </Toolbar>
      </AppBar>

      <Grid container spacing={2} sx={{ padding: 2 }}>
        {children.map((child, index) => (
          <Grid item xs={12} key={index}>
            <Card sx={{ borderRadius: '10px', backgroundColor: '#fff' }}>
              <CardContent>
                <Grid container alignItems="center" spacing={2}>
                  <Grid item xs={2}>
                    <Avatar sx={{ bgcolor: '#ffae00', width: 56, height: 56 }}>
                      {child.name.charAt(0)}
                    </Avatar>
                  </Grid>
                  <Grid item xs={8}>
                    <Typography variant="h6" style={{ fontFamily: 'Fredoka One', color: '#393d72' }}>
                      {child.name}
                    </Typography>
                    <Typography variant="body2" color="text.secondary">
                      Fecha de nacimiento: {child.birthdate}
                    </Typography>
                  </Grid>
                  <Grid item xs={2}>
                    <IconButton
                      onClick={() => handleOpenDeleteDialog(child)}
                      sx={{
                        borderRadius: '50%',
                        backgroundColor: '#ffae00',
                        color: 'white',
                        '&:hover': { backgroundColor: '#e69b00' },
                      }}
                    >
                      <DeleteIcon />
                    </IconButton>
                  </Grid>
                </Grid>
              </CardContent>
            </Card>
          </Grid>
        ))}
      </Grid>

      <Dialog 
        open={openDialog} 
        onClose={handleCloseDialog}
        PaperProps={{
          style: {
            borderRadius: 15,
            backgroundColor: '#f9f5f0',
          },
        }}
      >
        <DialogTitle style={{ 
          textAlign: 'center', 
          fontFamily: 'Fredoka One', 
          color: '#393d72',
          fontSize: '24px'
        }}>
          Agregar Niño
        </DialogTitle>
        <DialogContent>
          <TextField
            autoFocus
            margin="dense"
            label="Nombre Completo"
            type="text"
            fullWidth
            value={newChild.name}
            onChange={(e) => setNewChild({ ...newChild, name: e.target.value })}
            error={newChild.name.trim() === ''}
            helperText={newChild.name.trim() === '' ? 'El nombre es requerido' : ''}
          />
          <TextField
            margin="dense"
            label="Fecha de Nacimiento"
            type="date"
            fullWidth
            InputLabelProps={{ 
              shrink: true,
            }}
            value={newChild.birthdate}
            onChange={(e) => setNewChild({ ...newChild, birthdate: e.target.value })}
            error={!isValidDate(newChild.birthdate)}
            helperText={!isValidDate(newChild.birthdate) ? 'Fecha inválida' : ''}
          />
        </DialogContent>
        <DialogActions style={{
          justifyContent: 'center',
          padding: '20px'
        }}>
          <Button 
            onClick={handleCloseDialog}
            style={{
              fontFamily: 'Fredoka One',
              color: '#393d72',
              borderColor: '#393d72',
              borderRadius: '20px',
              padding: '5px 20px'
            }}
            variant="outlined"
          >
            Cancelar
          </Button>
          <Button 
            onClick={handleAddChild}
            style={{
              fontFamily: 'Fredoka One',
              backgroundColor: '#ffae00',
              color: 'white',
              borderRadius: '20px',
              padding: '5px 20px',
              marginLeft: '10px'
            }}
            variant="contained"
            disabled={!isValidForm()}
          >
            Agregar
          </Button>
        </DialogActions>
      </Dialog>

      <Dialog 
        open={deleteDialogOpen} 
        onClose={handleCloseDeleteDialog}
        PaperProps={{
          style: {
            borderRadius: 15,
            backgroundColor: '#f9f5f0',
          },
        }}
      >
        <DialogTitle style={{ 
          textAlign: 'center', 
          fontFamily: 'Fredoka One', 
          color: '#ffae00',
          fontSize: '20px',
        }}>
          Confirmar Eliminación
        </DialogTitle>
        <DialogContent>
          <Typography style={{ 
            textAlign: 'center', 
            fontFamily: 'Fredoka One', 
            color: '#393d72',
            fontSize: '16px',
            marginTop: '10px',
            marginBottom: '10px'
          }}>
            ¿Estás seguro de que quieres eliminar a {childToDelete?.name}?
          </Typography>
        </DialogContent>
        <DialogActions style={{
          justifyContent: 'center',
          padding: '20px'
        }}>
          <Button 
            onClick={handleCloseDeleteDialog}
            style={{
              fontFamily: 'Fredoka One',
              color: '#393d72',
              borderColor: '#393d72',
              borderRadius: '20px',
              padding: '5px 20px'
            }}
            variant="outlined"
          >
            Cancelar
          </Button>
          <Button 
            onClick={handleConfirmDelete} 
            style={{
              fontFamily: 'Fredoka One',
              backgroundColor: '#ff4880',
              color: 'white',
              borderRadius: '20px',
              padding: '5px 20px',
              marginLeft: '10px'
            }}
            variant="contained"
          >
            Eliminar
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
};

export default ManageChildrenScreen;
