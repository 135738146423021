import React, { useEffect, useState } from "react";
import {
  collection,
  query,
  where,
  getDocs,
  onSnapshot,
  updateDoc,
  doc,
  Timestamp,
} from "firebase/firestore";
import { useAuthContext } from "../hooks/useAuthContext";
import Grid from "@mui/material/Grid";
import { db } from "../firebase/config";
import CustomBottomVar from "./components/CustomBottomVar.js";
import TopBar from "./components/TopBar.js";
import NotificationBlock from "./components/NotificationBlock.js";
import {
  Box,
  Card,
  CardContent,
  Typography,
  Modal,
  CardActions,
  Button,
  Chip,
  CardHeader,
  Divider,
} from "@mui/material";
import dayjs from "dayjs";
import "dayjs/locale/es";
import ServiceList from "./components/ServiceList";
import NoChats from '@mui/icons-material/SpeakerNotesOff';

export default function WebApp() {
  // State to keep track of the selected button in CustomBottomVar
  const [selectedButton, setSelectedButton] = useState(1);

  // Function to handle button selection
  const handleButtonClick = (label) => {
    setSelectedButton(label);
  };

  return (
    <Box
      display="block"
      position="absolute"
      top={0}
      left={0}
      bottom={0}
      right={0}
      minHeight="100vh"
      minWidth="100vw"
      style={{ backgroundColor: "#f9f5f0" }}
    >
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <TopBar />
        </Grid>
        {/* Start CustomBottom Var conditional rendering */}
        {selectedButton === 2 && (
          <Grid item xs={11}>
           <Box
            display="flex"
            flexDirection="column"
            alignItems="center"
            justifyContent="center"
            height="80vh"
          >
            <NoChats fontSize="large" />
            <Typography variant="h6" mt={2}>
              Aún no tienes ninguna conversación
            </Typography>
          </Box>
        </Grid>
        )}
        {selectedButton === 1 && (
          <>
            <Grid item xs={11}>
              <div>
                <h1 style={{ marginLeft: "20px", color: "rgb(57, 61, 114)" }}>
                  Cuidados Disponibles
                </h1>
                <h5 style={{ marginLeft: "20px", color: "rgb(255, 72, 128)" }}>
                  Para Ti
                </h5>
                <NannyServiceCard />
              </div>
            </Grid>
            {/* <Grid item xs={11} style={{ marginTop: "50px" }}>
              <NotificationBlock />
            </Grid> */}
          </>
        )}
        {selectedButton === 0 && (
          <Grid item xs={11}>
            <div>
              <h1 style={{ marginLeft: "20px", color: "rgb(57, 61, 114)" }}>
                Tus cuidados
              </h1>
              <ServiceList />
            </div>
          </Grid>
        )}
        {/* End CustomBottom Var conditional rendering */}
        <Grid item xs={12}>
          <CustomBottomVar onButtonClick={handleButtonClick} />
        </Grid>
      </Grid>
    </Box>
  );
}

function NannyServiceCard() {
  const [services, setServices] = useState([]);
  const [selectedService, setSelectedService] = useState(null);
  const [openModal, setOpenModal] = useState(false);

  useEffect(() => {
    const q = query(
      collection(db, "public_services"),
      where("serviceStatus", "==", "pending")
    );

    const unsubscribe = onSnapshot(q, (querySnapshot) => {
      const servicesData = querySnapshot.docs.map((doc) => ({
        id: doc.id,
        ...doc.data(),
      }));
      setServices(servicesData);
    });

    return () => {
      // Unsubscribe from updates when the component is unmounted
      unsubscribe();
    };
  }, []);

  const handleOpenModal = (service) => {
    setSelectedService(service);
    setOpenModal(true);
  };

  const handleCloseModal = () => {
    setOpenModal(false);
  };

  return (
    <div
      style={{
        display: "flex",
    justifyContent: "center",
    alignItems: "flex-start", // Adjusted from "center" to "flex-start"
    marginTop: "10px",
    overflow: "auto",
    maxHeight: "60vh", // Set a maximum height for the scrollable div
      }}
    >
      <Grid container spacing={2} style={{ margin: "5px" }}>
        {services.map((service) => (
          <Grid item xs={12} key={service.id}>
            <ServiceCard
              service={service}
              handleOpenModal={handleOpenModal}
            />
          </Grid>
        ))}
      </Grid>
      {selectedService && (
        <ServiceModal
          service={selectedService}
          open={openModal}
          handleCloseModal={handleCloseModal}
        />
      )}
    </div>
  );
}

function ServiceCard({ service, handleOpenModal }) {
  const { serviceType, serviceFor, serviceAddress, serviceIsRestaurant, serviceRestaurantAddress, serviceWorkingHoursComission} = service;

  return (
    <Card style={{
      borderRadius: "1rem",
      padding: "20px",
      boxShadow: "0 3px 5px 2px rgba(0, 0, 0, .1)",
      backgroundColor: "#fff",
    }}>
      <Grid container spacing={2} alignItems="center">
        {/* Service Type */}
        <Grid item xs={12} sm={4} md={3}>
          <h2 style={{
            fontSize: "16px",
            fontWeight: "bold",
            margin: 0,
            color: "#333",
          }}>{serviceType}</h2>
        </Grid>

        {/* Address */}
        <Grid item xs={12} sm={5} md={5}>
          <p style={{
            fontSize: "14px",
            color: "#555",
            margin: 0,
          }}>
            <strong>Dirección:</strong> {serviceIsRestaurant ? serviceRestaurantAddress : serviceAddress}
          </p>
        </Grid>

        {/* Age */}
        <Grid item xs={12} sm={3} md={3}>
          <p style={{
            fontSize: "14px",
            color: "#555",
            margin: 0,
          }}>
            <strong>Edad:</strong> {serviceFor && serviceFor[0].age}
            {/* // TODO: Handle render of multiple children */}
          </p>
        </Grid>

        {/* Action Button */}
        <Grid item xs={12} style={{ textAlign: "right" }}>
          <Chip
            label={`$${serviceWorkingHoursComission}`}
            color="primary"
            size="medium"
            style={{
              marginTop: "10px",
              backgroundColor: "green",
              marginRight: "5px"
            }}
            disabled={true}
          />
          
          <Button variant="contained" color="primary" style={{
            marginTop: "10px",
            backgroundColor: "rgb(255, 72, 128)",
          }} onClick={() => handleOpenModal(service)}>
            Detalles
          </Button>
        </Grid>
      </Grid>
    </Card>
  );
}

function ServiceModal({ service, open, handleCloseModal }) {
  const { user } = useAuthContext();

  if (!service) {
    return null;
  }

  const {
    serviceType,
    serviceFor,
    packageType,
    serviceVisibleAddress,
    serviceAddress,
    serviceCreatedAt,
    serviceCreatedBy,
    serviceDays,
    duration,
    startTime,
    serviceIsRestaurant,
    serviceNotes,
    serviceRestaurantAddress,
    serviceRestaurantAddressName,
    serviceStatus,
    serviceTotal,
    startDate,
    serviceWorkingHoursComission,
  } = service;

  const handleDecline = () => {
    handleCloseModal();
  };

  const handleAccept = async () => {
    const serviceRef = doc(db, "public_services", service.id);
    await updateDoc(serviceRef, {
      serviceStatus: "found",
      serviceAssignedTo: user.uid, // update to the desired status
    });

    handleCloseModal();
  };

  return (
    <Modal
      open={open}
      onClose={handleCloseModal}
      style={{
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        borderRadius: "10px",
      }}
    >
      <Box
        display="flex"
        justifyContent="center"
        alignItems="center"
        style={{ backgroundColor: "rgba(249, 245, 240)", borderRadius: "10px" }}
      >
        <Card
          style={{
            maxWidth: "50vh",
            overflow: "auto", // Permite el desplazamiento
            padding: "20px",
            borderRadius: "10px",
            backgroundColor: "rgba(249, 245, 240)",
          }}
        >
          <CardHeader
            title={
              <div style={{ textAlign: "center" }}>
                <h2 style={{ color: "rgb(57, 61, 114)" }}>
                  Detalles del Cuidado
                </h2>
              </div>
            }
          />
          <CardContent
            style={{
              maxHeight: "50vh", // Añade una altura máxima
              overflow: "auto", // Permite el desplazamiento
              scrollbarWidth: "thin", // Para navegadores Firefox
              scrollbarColor: "#888 #f5f5f5", // Para navegadores Firefox
              "&::-webkit-scrollbar": {
                // Para navegadores WebKit
                width: "6px",
              },
              "&::-webkit-scrollbar-thumb": {
                // Para navegadores WebKit
                backgroundColor: "#888",
                borderRadius: "10px",
              },
              "&::-webkit-scrollbar-thumb:hover": {
                // Para navegadores WebKit
                backgroundColor: "#555",
              },
            }}
          >
            <div
              style={{
                borderRadius: "10px",
                padding: "10px",
                backgroundColor: "#ffff",
              }}
            >
              <div>
                <Typography variant="body2">Tipo de Servicio:</Typography>{" "}
                {serviceType}
              </div>
            </div>
            <div
              style={{
                borderRadius: "10px",
                marginTop: "10px",
                padding: "10px",
                backgroundColor: "#ffff",
              }}
            >
              <div>
                <Typography variant="body2">
                  Detalles de l@s pequeñ@/s:
                </Typography>{" "}
              </div>
              {serviceFor.map((service, index) => (
                <div
                  key={index}
                  style={{
                    display: "flex",
                    marginBottom: "10px",
                    marginTop: "10px",
                  }}
                >
                  {/* First column with icon and text */}

                  <div
                    style={{
                      marginRight: "20px",
                      display: "flex",
                      alignItems: "center",
                    }}
                  >
                    {/* Replace with your icon component */}
                    <img
                      loading="lazy"
                      src="https://cdn.builder.io/api/v1/image/assets/TEMP/bdd5a3f8dada3ccb2edecc4816ff901a0a425d44f384be6c75592b78668cbdd6?apiKey=f9ed1825c5104496bd57288a76ba297c&"
                      className="img"
                    />
                    <span style={{ marginLeft: "10px" }}>{service.name}</span>
                  </div>

                  {/* Second column with just text */}
                  <div>{service.age}</div>
                </div>
              ))}
            </div>
            <div
              style={{
                borderRadius: "10px",
                marginTop: "10px",
                padding: "10px",
                backgroundColor: "#ffff",
              }}
            >
              <div>
                <Typography variant="body2">Dirección:</Typography>
                {serviceIsRestaurant
                  ? serviceRestaurantAddress
                  : serviceAddress}
              </div>
            </div>

            <div
              style={{
                borderRadius: "10px",
                marginTop: "10px",
                padding: "10px",
                backgroundColor: "#ffff",
              }}
            >
              <Typography variant="body2" color="text.secondary" gutterBottom>
                Pago:{" "}
                <Chip
                  label={`$${serviceWorkingHoursComission}`}
                  style={{ backgroundColor: "green", color: "white" }}
                />
              </Typography>
            </div>

            <Divider
              variant="middle"
              style={{ margin: "10px", backgroundColor: "rgb(140, 140, 140)" }}
            />

            <div style={{ textAlign: "center" }}>
              <Typography variant="body2">
                <strong>Detalles de Horario</strong>
              </Typography>
            </div>

            {serviceDays && (
              <>
                <Typography
                  variant="body2"
                  color="text.secondary"
                  gutterBottom
                  style={{ textAlign: "center" }}
                >
                  Número de Días: {serviceDays.length}
                </Typography>
              </>
            )}

            <div
              style={{
                maxHeight: "300px",
                overflowY: "auto",
                marginTop: "20px",
              }}
            >
              {serviceDays &&
                serviceDays.map((serviceDay, index) => {
                  const miliseconds = serviceDay.day * 1000; // Convertir a milisegundos
                  
                  let formattedDate = new Date(serviceDay.day);
                  
                  const options = { 
                    year: 'numeric', 
                    month: 'long', 
                    day: 'numeric', 
                    weekday: 'long'
                  };
                  formattedDate = formattedDate.toLocaleDateString('es-ES', options);
                  
                  const formattedStartTime = new Date(serviceDay.startTime).toLocaleTimeString("es-ES", {
                    hour: "2-digit",
                    minute: "2-digit",
                  });
                  const formattedEndTime = new Date(serviceDay.endTime).toLocaleTimeString("es-ES", {
                    hour: "2-digit",
                    minute: "2-digit",
                  });

                  return (
                    <Card
                      key={index}
                      style={{
                        marginBottom: "10px",
                        boxShadow: "none",
                        backgroundColor: "#fffff",
                      }}
                    >
                      <CardContent>
                        <Typography variant="h6" component="h2">
                          {formattedDate}
                        </Typography>
                        <Typography variant="body2" color="text.secondary">
                          Hora de Inicio: {formattedStartTime}
                        </Typography>
                        <Typography variant="body2" color="text.secondary">
                          Hora de Finalización: {formattedEndTime}
                        </Typography>
                        <Typography variant="body2" color="text.secondary">
                          Duración: {serviceDay.duration} horas
                        </Typography>
                      </CardContent>
                    </Card>
                  );
                })}
              {/* {serviceType !== "Cuidado Eventual" && (
                <>
                  <Typography
                    variant="body2"
                    color="text.secondary"
                    gutterBottom
                    style={{ textAlign: "center" }}
                  >
                    Horario: {dayjs(startTime).format("hh:mm a")} -{" "}
                    {dayjs(startTime)
                      .add(packageType.includes("4") ? 4 : 6, "hour")
                      .format("hh:mm a")}
                  </Typography>

                  <Typography
                    variant="body2"
                    color="text.secondary"
                    gutterBottom
                    style={{ textAlign: "center" }}
                  >
                    Dia de Inicio: {dayjs(startDate.$d).format("YYYY-MM-DD")}
                  </Typography>
                  <Typography
                    variant="body2"
                    color="text.secondary"
                    gutterBottom
                    style={{ textAlign: "center" }}
                  >
                    Dia Final:{" "}
                    {dayjs(startDate.$d).add(6, "day").format("YYYY-MM-DD")}
                  </Typography>
                </>
              )} */}
            </div>
            {/* Add more service details as needed */}
          </CardContent>
          <CardActions style={{ justifyContent: "center" }}>
            <Button variant="contained" color="primary" onClick={handleAccept}>
              Postularme
            </Button>
            <Button
              variant="contained"
              color="secondary"
              onClick={handleDecline}
            >
              Declinar
            </Button>
          </CardActions>
          <div
            style={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <div style={{ width: "250px" }}>
              Al momento de aceptar el servicio aparecerá en la sección de
              servicios.
            </div>
          </div>
        </Card>
      </Box>
    </Modal>
  );
}
