import React, { useEffect, useState } from "react";
import { doc, getDoc, setDoc } from "firebase/firestore";
import { db } from "../../firebase/config";
import {
  Card,
  CardMedia,
  CardContent,
  Typography,
  Stack,
  Dialog,
  IconButton,
  Button,
} from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import PaymentDialog from "./PaymentDialog";
import NannyProfile from "./NannyProfile";
import StarIcon from '@mui/icons-material/Star';

// Profile description modal component
const ProfileModal = ({ open, onClose, service }) => {
  return (
    <Dialog open={open} onClose={onClose}>
      <div style={{ padding: "20px", textAlign: "center" }}>
        <h2>
          {service.nickName} ({service.name})
        </h2>
        <h3 style={{ color: "rgb(255, 72, 128)" }}>{service.profession}</h3>
        <p>{service.description}</p>
        <p>Pet friendly: {service.petFriendly}</p>
      </div>
    </Dialog>
  );
};

export default function FoundServiceCard({ service }) {
  const { serviceAssignedTo } = service;
  // State to control the open/close state of the dialog
  const [paymentOpen, setPaymentOpen] = useState(false);
  const [profileOpen, setProfileOpen] = useState(false);
  const [nanny, setNanny] = useState(null);

  // Function to open the dialog
  const handlePaymentOpen = () => setPaymentOpen(true);
  const handleProfileOpen = () => setProfileOpen(true);

  // Function to close the dialog
  const handlePaymentClose = () => setPaymentOpen(false);
  const handleProfileClose = () => setProfileOpen(false);

  // Fetch the nanny data when the serviceAssignedTo value changes
  useEffect(() => {
    const fetchData = async () => {
      if (serviceAssignedTo) {
        const docRef = doc(db, "nanny", serviceAssignedTo);
        const docSnap = await getDoc(docRef);

        if (docSnap.exists()) {
          setNanny(docSnap.data());
        } else {
          console.log("No such document!");
        }
      }
    };

    fetchData();
  }, [serviceAssignedTo]);
  // Function to dismiss the service
  const handleDismiss = async () => {
    const serviceRef = doc(db, "public_services", service.id); // assuming service.id contains the ID of the service document

    // Update the serviceStatus field of the document
    await setDoc(
      serviceRef,
      {
        serviceStatus: "dismissed_by_user",
      },
      { merge: true }
    ); // Use the merge option to only update serviceStatus field and leave the rest of the document intact

    window.location.reload();
  };

  // Extract the description and image from the nanny data if it's available
  const description = nanny?.profileDescription || "";
  const image = nanny?.profileImage || "";
  const name = nanny?.firstName || "";
  const profession = nanny?.profession || "";
  const petFriendly = nanny?.petFriendly || "No information";
  const nickName = nanny?.nickName || "";

  return (
    <Card
      style={{
        borderRadius: "1rem",
        width: "200px",
        height: "320px",
        position: "relative",
      }}
    >
      <CardMedia
        style={{ height: 0, paddingTop: "120%", position: "relative" }}
        image={image}
        title={name}
        onClick={handleProfileOpen}
      >
        <div
          style={{
            position: "absolute",
            bottom: 0,
            left: 0,
            padding: "10px",
            color: "white",
            textShadow: "0px 0px 8px rgba(0, 0, 0, 0.8)",
          }}
        >
          <Typography
            variant="h6"
            component="h2"
            style={{
              overflow: "hidden",
              textOverflow: "ellipsis",
              whiteSpace: "nowrap",
              width: "100%",
              fontFamily: '"Fredoka One", sans-serif', // Set the font family
              color: "black", // Set the color to black
            }}
          >
            {name}
          </Typography>
          <Typography
            variant="body2"
            style={{
              fontSize: "0.75rem", // Smaller text for nickname
              fontFamily: '"Fredoka One", sans-serif', // Set the font family
              color: "white", // Set the color to white
            }}
          >
            {profession}
          </Typography>
        </div>

        {/* Icon overlay */}
        <div
          style={{
            position: "absolute",
            bottom: 10,
            right: 10,
          }}
        >
          <StarIcon style={{ color: "rgb(255, 72, 128)" }} fontSize="large" />
        </div>
      </CardMedia>
      <CardContent>
        <Stack direction="row" spacing={1}>
          <Button
            onClick={handlePaymentOpen}
            style={{
              backgroundColor: "pink",
              color: "white",
              borderRadius: "10px",
              justifyContent: "right",
              alignItems: "right",
            }}
          >
            Reservar
          </Button>
          <IconButton
            disableRipple={false}
            style={{
              backgroundColor: "pink",
              color: "white",
              width: "40px",
              height: "40px",
              borderRadius: "20px",
              justifyContent: "right",
              alignItems: "right",
            }}
            onClick={handleDismiss}
          >
            <CloseIcon />
          </IconButton>
        </Stack>
      </CardContent>

      {paymentOpen && (
        <PaymentDialog
          handleClose={handlePaymentClose}
          paymentDetails={service.serviceTotalWithComission}
          serviceID={service.id}
          nannyUID={serviceAssignedTo}
        />
      )}
      {profileOpen && (
        <NannyProfile
          open={profileOpen}
          onClose={handleProfileClose}
          profile={nanny}
        />
      )}
    </Card>
  );
}
