import { Grid } from '@mui/material'
import React from 'react'

import image from './Recurso_2.png'

export default function NotificationBlock() {
  return (
    <div>
        <div style={{marginBottom:'15px'}}>
        
        <h1 style={{marginLeft:'20px', color:'rgb(57, 61, 114)'}}> Notificaciones</h1>
        <h5 style={{marginLeft:'20px', color:'rgb(255, 72, 128)'}}> </h5>
        
        </div>
        <Grid container spacing={2}>
            <Grid item xs={12} >
                <img src={image} alt="No Notifications" style={{maxHeight:'50px', display:'block',marginTop:"10px", marginLeft:'auto', marginRight:'auto'}}/>
                <p style={{textAlign:'center'}}>No tienes notificaciones</p>
            </Grid>
        
        </Grid>
    </div>
    )
}
