/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useRef, useState } from "react";
import {
  Dialog,
  IconButton,
  Toolbar,
  Typography,
  Button,
  Slide,
  AppBar,
  Chip,
  CircularProgress,
} from "@mui/material";
import "./ConfigureServiceDialog.css";

import CloseIcon from "@mui/icons-material/Close";
import SearchIcon from "@mui/icons-material/Search";
import { useAuthContext } from "../../hooks/useAuthContext";
import { useDocument } from "../../hooks/useDocument";
import EventualCareForm from "./EventualCareForm";
import PackageCareForm from "./PackageCareForm";

//Functions Firebase
import { functions } from "../../firebase/config";
import { httpsCallable, getFunctions } from "firebase/functions";

//Define https callable function
const functionsInstance = getFunctions();
const getZipcodeData = httpsCallable(functionsInstance, "getZipcodeData");
const publishServiceRequest = httpsCallable(
  functionsInstance,
  "PostServiceRequest"
);

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

export default function ConfigureServiceDialog({ onConfigureService }) {
  const { user } = useAuthContext();
  const { document: userData } = useDocument("users", user?.uid);
  const { document: tariffs } = useDocument("app_attributes", "tariffs");
  const [validationEror, setValidationEror] = useState(false);

  // ** Dialog state and logic **
  const [open, setOpen] = useState(false);
  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };
  // ** End Dialog state and logic **

  // ** Selected Package Logic **
  const [selected, setSelected] = useState("Cuidado Eventual");
  const handleSelect = (value) => {
    setSelected(value);
  };
  // ** End Selected Package Logic **

  // ** Render Selected Package Logic **
  const renderForm = () => {
    if (selected === "Cuidado Eventual") {
      return (
        <EventualCareForm
          userData={userData}
          eventualCareData={eventualCareData}
          handleEventualCareData={handleEventualCareData}
          addServiceDay={addServiceDay}
          removeServiceDay={removeServiceDay}
        />
      );
    } else {
      return (
        <PackageCareForm
          userData={userData}
          packageCareData={packageCareData}
          handlePackageCareData={handlePackageCareData}
        />
      );
    }
  };
  // ** End Render Selected Package Logic **

  // ** Data handle functions for Packages **
  const handlePackageCareData = (event) => {
    const { name, value } = event.target;
    setPackageCareData((prevPackageCareData) => {
      const updatedPackageCareData = { ...prevPackageCareData, [name]: value };
      return updatedPackageCareData;
    });
  };

  const handleEventualCareData = (event, index) => {
    const { name, value } = event.target;
    //console.log(`Handling ${name} with value: ${value} at index: ${index}`);
    if (["day", "startTime", "duration", "endTime", "error"].includes(name)) {
      setEventualCareData((prevState) => {
        const newServiceDays = [...prevState.serviceDays];
        newServiceDays[index] = { ...newServiceDays[index], [name]: value };
        return { ...prevState, serviceDays: newServiceDays };
      });
    } else {
      setEventualCareData({ ...eventualCareData, [name]: value });
    }
  };
  // ** End Data handle functions for Packages **

  // ** Begining State Data Structures **

  //Eventual Care State's and Logic
  const [eventualCareData, setEventualCareData] = useState({
    serviceDays: [
      {
        day: null,
        startTime: null,
        duration: null,
        endTime : null,
        error: null,
      },
    ],
    serviceFor: null,
    serviceAddress: null,
    serviceIsRestaurant: null,
    serviceRestaurantAddressName: null,
    serviceRestaurantAddress: null,
    serviceNotes: null,
  });

  //Package Care State's and Logic
  const [packageCareData, setPackageCareData] = useState({
    startDate: null,
    endDate: null,
    startTime: null,
    endTime: null,
    packageType: "Medio Tiempo (4 horas)",
    serviceFor: null,
    serviceAddress: null,
    serviceIsRestaurant: null,
    serviceRestaurantAddressName: null,
    serviceRestaurantAddress: null,
    serviceNotes: null,
    serviceDays: null,
  });

  // ** End State Data Structures **

  const addServiceDay = () => {
    if (eventualCareData.serviceDays.length < 7) {
      setEventualCareData((prevState) => ({
        ...prevState,
        serviceDays: [
          ...prevState.serviceDays,
          {
            day: null,
            startTime: null,
            duration: null,
            endTime: null,
            error: null,
          },
        ],
      }));
    }
  };

  const removeServiceDay = (index) => {
    setEventualCareData((prevData) => {
      const newServiceDays = [...prevData.serviceDays];
      newServiceDays.splice(index, 1);
      return { ...prevData, serviceDays: newServiceDays };
    });
  };

  const updateServiceTotal = (selectedOption, data) => {};

  // ** Form Validation Logic **
  const validateForm = () => {
    const validateAddress = (serviceData) => {
      if (serviceData.serviceIsRestaurant) {
        return (
          serviceData.serviceRestaurantAddressName !== null &&
          serviceData.serviceRestaurantAddress !== null &&
          serviceData.serviceRestaurantAddressName.trim() !== "" &&
          serviceData.serviceRestaurantAddress.trim() !== ""
        );
      } else {
        return (
          serviceData.serviceAddress !== null &&
          serviceData.serviceAddress.trim() !== ""
        );
      }
    };

    if (selected === "Cuidado Eventual") {
      const validateDays = eventualCareData.serviceDays.every((day) => {
        return day.day && day.startTime && day.duration && day.endTime;
      });

      return (
        validateDays &&
        eventualCareData.serviceFor != null &&
        validateAddress(eventualCareData)
      );
    } else {
      return (
        packageCareData.packageType != null &&
        packageCareData.startDate != null &&
        packageCareData.serviceFor != null &&
        validateAddress(packageCareData)
      );
    }
  };
  // ** End Form Validation Logic **

  const [buttonText, setButtonText] = useState("Buscar Nanny");
  const [buttonColor, setButtonColor] = useState("#ffae00");

  //Handle search button
  const handleSearch = () => {
    if (validateForm()) {
      setValidationEror(false);
      console.log("Validated");

      // Si el botón dice "Obtener Total", cambiamos el texto a "Realizar Búsqueda" y el color.
      // Si no, asumimos que ya dice "Realizar Búsqueda", y llamamos a la función publishBet.

      
        handlePublishServiceRequest();
        setButtonText("Buscar Nanny");
        setButtonColor("#ffae00"); // Cambiar el color a lo que necesites aquí
      
    } else {
      setValidationEror(true);
      console.log("Not Validated");
    }
  };

  const [isLoading, setIsLoading] = useState(false);

  const handlePublishServiceRequest = async () => {
    try {
      setIsLoading(true);
      const getCurrentAgeByName = (names) => {
        // Assuming userData is the object containing the kids array
        // Replace it with the actual object name if different

        const ages = [];

        names.forEach((name) => {
          // Find the kid with the specified name
          const kid = userData.kids.find((child) => child.name === name);

          if (kid) {
            // Calculate the current age based on the birthdate
            const birthdate = new Date(kid.birthdate);
            const today = new Date();
            const ageInMilliseconds = today - birthdate;
            const ageInYears = Math.floor(
              ageInMilliseconds / (365.25 * 24 * 60 * 60 * 1000)
            );
            const ageInMonths = Math.floor(
              ageInMilliseconds / (30.4375 * 24 * 60 * 60 * 1000)
            );

            if (ageInYears < 1) {
              ages.push({ name: kid.name, age: `${ageInMonths} Months` });
            } else {
              ages.push({ name: kid.name, age: `${ageInYears} Years` });
            }
          } else {
            ages.push({ name: name, age: "Kid not found." });
          }
        });

        return ages;
      };
      const getVisibleAddress = async (zipcode) => {
        // <-- make function async
        try {
          const result = await getZipcodeData({ zipcode: zipcode }); // <-- use await
          const address = result.data;
          console.log(address);
          return address; // <-- return the result
        } catch (error) {
          console.error("Error getting address:", error);
          return "Address not available"; // return an empty string or some default value in case of an error
        }
      };

      const {
        serviceTotal,
        transportFeeTotal,
        serviceTransactionFee,
        serviceSubtotal,
        serviceTax,
        serviceTotalWithComission,
        workingHours,
        workingHoursComission,
      } = getTotal();

      if (selected === "Cuidado Eventual") {
        const serviceRequest = {
          serviceType: selected,
          serviceDays: eventualCareData.serviceDays,
          serviceFor: getCurrentAgeByName(eventualCareData.serviceFor),
          serviceAddress: eventualCareData.serviceAddress,
          serviceIsRestaurant: eventualCareData.serviceIsRestaurant,
          serviceRestaurantAddressName:eventualCareData.serviceRestaurantAddressName,
          serviceRestaurantAddress: eventualCareData.serviceRestaurantAddress,
          serviceNotes: eventualCareData.serviceNotes,
          serviceTotal: serviceTotal,
          serviceTransactionFee: serviceTransactionFee,
          serviceTax: serviceTax,
          serviceSubtotal: serviceSubtotal,
          serviceTotalWithComission: serviceTotalWithComission,
          serviceWorkingHours: workingHours,
          serviceWorkingHoursComission: workingHoursComission,
          transportFeeTotal: transportFeeTotal,
          serviceCreatedAt: new Date(),
          serviceCreatedBy: userData.id,
        };
        publishServiceRequest(serviceRequest);
        setOpen(false);
        onConfigureService();
      } else {
        const serviceRequest = {
          //FIXME: Tax and rates are passing as NaN
          serviceType: selected,
          packageType: packageCareData.packageType,
          startDate: packageCareData.startDate,
          serviceDays: packageCareData.serviceDays,
          startTime: packageCareData.startTime,
          endTime: packageCareData.endTime,
          serviceFor: getCurrentAgeByName(packageCareData.serviceFor),
          serviceAddress: packageCareData.serviceAddress,
          serviceIsRestaurant: packageCareData.serviceIsRestaurant,
          serviceRestaurantAddressName:packageCareData.serviceRestaurantAddressName,
          serviceRestaurantAddress: packageCareData.serviceRestaurantAddress,
          serviceNotes: packageCareData.serviceNotes,
          serviceTotal: serviceTotal,
          serviceTransactionFee: serviceTransactionFee,
          serviceTax: serviceTax,
          serviceSubtotal: serviceSubtotal,
          serviceTotalWithComission: serviceTotalWithComission,
          serviceWorkingHours: workingHours,
          serviceWorkingHoursComission: workingHoursComission,
          transportFeeTotal: transportFeeTotal,
          serviceCreatedAt: new Date(),
          serviceCreatedBy: userData.id,
        };
        publishServiceRequest(serviceRequest);
        setOpen(false);
        onConfigureService();
      }
    } catch (error) {
      console.log(error);
    } finally {
      setIsLoading(false);
    }
  };

  const getTotal = () => {
    let total = 0;
    let transportFee = 0;
    let totalDuration = 0;

    if (selected === "Cuidado Eventual") {
      eventualCareData.serviceDays.forEach((serviceDay) => {
        // Add the cost for the duration
        const durationCost = tariffs.eventual[serviceDay.duration];
        total += durationCost;

        // Calculate the end time
        const startTime = new Date(serviceDay.startTime);
        const endTime = new Date(
          startTime.getTime() + parseInt(serviceDay.duration) * 60 * 60 * 1000
        );

        // Check if endTime surpasses 10 PM of the startTime and add transport fee if needed
        if (
          endTime >
          new Date(
            startTime.getFullYear(),
            startTime.getMonth(),
            startTime.getDate(),
            22,
            0,
            0
          )
        ) {
          total += tariffs.transport_fee;
          transportFee += tariffs.transport_fee;
        }

        // Update the total duration counter
        totalDuration += parseInt(serviceDay.duration);
      });
    } else if (selected === "Paquete con Horario Fijo") {
      // Determine the package type (4 or 8 hours)
      const packageType = packageCareData.packageType.includes("4hrs") ? 4 : 8;
      //Calculate the total duration
      totalDuration = packageType * 5;
      //ADD: Month Package should be updated HERE 
      // Add the cost for the package type
      total += tariffs.weekly[packageType];
      // Check if endTime surpasses or is 10 PM and add transport fee if needed
      const startTime = new Date(packageCareData.startTime);
      const endTime = new Date(packageCareData.endTime.getTime());

      if (
        endTime >
        new Date(
          startTime.getFullYear(),
          startTime.getMonth(),
          startTime.getDate(),
          22,
          0,
          0
        )
      ) {
        total += tariffs.transport_fee;
        transportFee += tariffs.transport_fee;
      }
    }

    // Update Service Totals states, tax and comission
    const transportFeeTotal = transportFee;
    console.log("Transport Fee: " + transportFee);

    const stripeFee = (total + transportFee) * 0.036;
    const serviceTransactionFee = stripeFee;

    const subTotal = total + transportFee + stripeFee;
    const serviceSubtotal = subTotal;

    const taxCalculation = (total + transportFee) * 0.16; // Tax is not considering stripe fee (This may change)
    const serviceTax = taxCalculation;

    const clientCost = total + transportFee + stripeFee + taxCalculation;
    const serviceTotalWithComission = clientCost;

    const workingHours = totalDuration;

    //Calculate nanny comission based on rate located on firebase
    const workingHoursComission =
      (totalDuration - 2) * tariffs.nanny_rate.increase_rate +
      tariffs.nanny_rate.base_rate;

    const serviceTotal = total;
    return {
      serviceTotal,
      transportFeeTotal,
      stripeFee,
      serviceTransactionFee,
      serviceSubtotal,
      serviceTax,
      clientCost,
      serviceTotalWithComission,
      workingHours,
      workingHoursComission,
    };
  };

  useEffect(() => {
    const getCompleteServiceDays = () => {
      return eventualCareData.serviceDays.filter(
        (serviceDay) =>
          serviceDay.day !== null &&
          serviceDay.startTime !== null &&
          serviceDay.duration !== null
      );
    };

    const completeServiceDays = getCompleteServiceDays();

    if (selected === "Cuidado Eventual" && completeServiceDays.length > 0) {
      updateServiceTotal("Cuidado Eventual", completeServiceDays);
    } else if (
      selected === "Paquete con Horario Fijo" &&
      packageCareData.packageType !== null &&
      packageCareData.packageType !== "" &&
      packageCareData.startTime !== null &&
      packageCareData.startTime !== ""
    ) {
      updateServiceTotal("Paquete con Horario Fijo", packageCareData);
    } else {
      // Reset serviceTotal if the conditions are not met
      // Replace with your logic to reset serviceTotal
      // resetServiceTotal();
    }
  }, [eventualCareData.serviceDays, packageCareData, selected]);

  useEffect(() => {
    // Cuando eventualCareData o packageCareData cambien, el texto y color del botón regresará a "Obtener Total".
    setButtonText("Buscar Nanny");
    setButtonColor("#ffae00"); // Asegúrate de cambiar este color al color que quieres para "Obtener Total".
  }, [eventualCareData, packageCareData]); // Dependencias del useEffect

  //Idiot proof scrolling (If overflow Y does not work , its because of this, i know , client is an idiot)
  const scrollRef = useRef(null);
  useEffect(() => {
    function handleKeyDown(e) {
      if (scrollRef.current && (e.key === "ArrowDown" || e.key === "ArrowUp")) {
        const scrollAmount = 30; // Adjust the scroll amount as needed
        const direction = e.key === "ArrowDown" ? 1 : -1;
        scrollRef.current.scrollBy(0, direction * scrollAmount);
      }
    }
  
    // Attach the event listener
    window.addEventListener('keydown', handleKeyDown);
  
    // Ensure the dialog content is focused when the dialog is open
    if(open && scrollRef.current) {
      scrollRef.current.focus();
    }
  
    // Clean up event listener
    return () => {
      window.removeEventListener('keydown', handleKeyDown);
    };
  }, [open]);
  return (
    <>
      <div style={{ textAlign: "center" }}>
        <button
          id="searchService"
          className="theme-btn btn-style-one login-button"
          style={{ marginTop: "100px", padding: "20px 30px" }}
          onClick={handleClickOpen}
        >
          <SearchIcon style={{ fontSize: "30px", marginRight: "5px" }} />
          Solicitar Nanny
        </button>
      </div>
      <Dialog
        fullScreen
        open={open}
        onClose={handleClose}
        TransitionComponent={Transition}
      >
        <AppBar sx={{ position: "relative", backgroundColor: "#393d72" }}>
          <Toolbar>
            <IconButton
              edge="start"
              color="inherit"
              onClick={handleClose}
              aria-label="close"
            >
              <CloseIcon color="#ffae00" />
            </IconButton>
            <Typography
              sx={{
                ml: 2,
                flex: 1,
                fontFamily: "Fredoka One",
                textAlign: "center",
                textColor: "#ffae00",
              }}
              variant="h7"
              component="div"
            >
              Seleciona las opciones de busqueda para el cuidado de tu bebé/hij@
            </Typography>
            {/* <Button autoFocus color="inherit" onClick={handleClose}>
              save
            </Button> */}
          </Toolbar>
        </AppBar>
        <div
          style={{
            textAlign: "center",
            marginRight: "10 px",
            marginLeft: "10px",
            overflowY: "auto",
            scrollBehavior: "smooth",
          }}
          className="dialog-content" 
          tabIndex={0} 
          ref={scrollRef}
        >
          <div style={{ marginTop: "25px" }}>
            <Typography
              sx={{
                ml: 2,
                flex: 1,
                fontFamily: "Fredoka One",
                textAlign: "left",
                color: "#393d72",
              }}
              variant="h6"
              component="div"
            >
              Elige el tipo de cuidado que necesitas:
            </Typography>
            <div style={{ marginTop: "10px" }}>
              <Chip
                label="Cuidado Eventual"
                style={{
                  backgroundColor:
                    selected === "Cuidado Eventual" ? "#ffae00" : "",
                  color: selected === "Cuidado Eventual" ? "#fff" : "",
                }}
                onClick={() => handleSelect("Cuidado Eventual")}
                sx={{ marginRight: 1 }}
              />
              <Chip
                label="Paquete con Horario Fijo"
                style={{
                  backgroundColor:
                    selected === "Paquete con Horario Fijo" ? "#ffae00" : "",
                  color: selected === "Paquete con Horario Fijo" ? "#fff" : "",
                }}
                onClick={() => handleSelect("Paquete con Horario Fijo")}
              />
            </div>
          </div>
          {renderForm()}
          {/* <div className="sectionCard" style={{ padding: "10px" }}>
            <Typography
              variant="h5"
              sx={{
                fontFamily: "Fredoka One",
                color: "#393d72",
                textAlign: "left",
              }}
            >
              Pago Nannapp:
            </Typography>
            <div style={{ marginTop: "10px" }}>
              <Typography
                variant="h4"
                sx={{
                  fontFamily: "Fredoka One",
                  color: "#393d72",
                  textAlign: "left",
                }}
              >
                ${serviceTotal}
              </Typography>
            </div>
            {true && (
              <div style={{ marginTop: "10px" }}>
                <Typography
                  variant="h6"
                  sx={{
                    fontFamily: "Fredoka One",
                    color: "#393d72",
                    textAlign: "left",
                  }}
                >
                  Cuota de transporte privado: ${transportFeeTotal}
                  <br />
                  (A partir de las 10 PM)
                </Typography>
              </div>
            )}
            <div style={{ marginTop: "10px" }}>
              <Typography
                variant="h6"
                sx={{
                  fontFamily: "Fredoka One",
                  color: "#393d72",
                  textAlign: "left",
                }}
              >
                Comisión pago con tarjeta: ${serviceTransactionFee.toFixed(2)}
                <br />
                (3.6%)
              </Typography>
            </div>
            <div style={{ marginTop: "20px", textAlign: "center" }}>
              <Typography
                variant="body1"
                sx={{ fontFamily: "Montserrat", color: "#393d72" }}
              >
                Una vez que elijas a una nanny disponible, la reservación
                quedará confirmada hasta que se realice el pago.
              </Typography>
            </div>
            <div style={{ marginTop: "20px" }}>
              <Typography
                variant="h6"
                sx={{
                  fontFamily: "Fredoka One",
                  color: "#393d72",
                  textAlign: "right",
                }}
              >
                Subtotal: ${serviceSubtotal.toFixed(2)}
              </Typography>
              <Typography
                variant="h6"
                sx={{
                  fontFamily: "Fredoka One",
                  color: "#393d72",
                  textAlign: "right",
                }}
              >
                IVA: ${serviceTax.toFixed(2)}
              </Typography>
              <Typography
                variant="h6"
                sx={{
                  fontFamily: "Fredoka One",
                  color: "#393d72",
                  textAlign: "right",
                }}
              >
                Total a pagar: ${serviceTotalWithComission.toFixed(2)}
              </Typography>
            </div>
          </div> */}
          {validationEror && (
            <div style={{ marginTop: "10px" }}>
              <Typography
                variant="body1"
                sx={{ fontFamily: "Montserrat", color: "red" }}
              >
                Por favor llena todos los campos correctamente.
              </Typography>
            </div>
          )}
          <div style={{ marginTop: "10px", marginBottom: "20px" }}>
            <Button
              variant="contained"
              style={{ backgroundColor: buttonColor, color: "#fff" }}
              onClick={handleSearch}
              disabled={isLoading}
            >
              {isLoading ? (
                <CircularProgress size={24} color="inherit" />
              ) : (
                buttonText
              )}
            </Button>
          </div>
        </div>
      </Dialog>
    </>
  );
}
