import React from "react";
import ReactDOM from "react-dom/client";
import App from "./App";
import reportWebVitals from "./reportWebVitals";

import { AuthContextProvider } from "./context/AuthContext";
import { LocalizationProvider } from "@mui/x-date-pickers";
import {AdapterDayjs} from "@mui/x-date-pickers/AdapterDayjs";
import LogRocket from "logrocket";
import './i18n.js';

LogRocket.init("ttb6uh/nannapp");
const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  <React.StrictMode>
    <AuthContextProvider>
      <LocalizationProvider dateAdapter={AdapterDayjs}>
        <App />
      </LocalizationProvider>
    </AuthContextProvider>
  </React.StrictMode>
);

// Check if the service worker API is available
if ('serviceWorker' in navigator) {
  // Register your service worker
  navigator.serviceWorker
    .register('./firebase-messaging-sw.js') // Assuming your service worker file is named `firebase-messaging-sw.js` and located at the root of the `public` folder
    .then((registration) => {
      console.log('Service Worker registered with scope: ', registration.scope);
    })
    .catch((err) => {
      console.error('Service Worker registration failed: ', err);
    });
}

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
