/* eslint-disable no-unused-vars */
/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useEffect, useState } from 'react';
import "bootstrap/dist/css/bootstrap.css";
import "bootstrap/dist/js/bootstrap.js";
import "./css/style.css";
import { AppBar, Box, Toolbar, Typography, Button } from "@mui/material";
import EmailIcon from "@mui/icons-material/Email";


import MenuNavbar from "./components/MenuNavbar";
import SignUpDialog from "./components/SignUpDialog";
import LoginDialog from "./components/LoginDialog";
import ResetPasswordDialog from "./components/ResetPasswordDialog";
import { Link } from "react-router-dom";
export default function NannyPolicies() {
  const [imgUrl, setImgUrl] = useState("");
  const [width, setWidth] = useState(window.innerWidth);

  const [openSignUp, setOpenSignUp] = useState(false);
  const [openLogin, setOpenLogin] = useState(false);

  const [openResetPassword, setOpenResetPassword] = useState(false);
  const handleResetPasswordClick = () => {
    setOpenLogin(false);
    setOpenResetPassword(true);
  };

  useEffect(() => {
    const mediaQuery = window.matchMedia("(max-width: 768px)");
    const handleMediaQueryChange = (mediaQuery) => {
      if (mediaQuery.matches) {
        setImgUrl(require("./images/Banner_Slider_p3.png")); /*Mobile Slider*/
      } else {
        setImgUrl(require("./images/Banner_Slider_p.png")); /*Desktop Slider*/
      }
    };

    handleMediaQueryChange(mediaQuery);
    mediaQuery.addListener(handleMediaQueryChange);

    return () => mediaQuery.removeListener(handleMediaQueryChange);
  }, []);

  useEffect(() => {
    const handleResize = () => setWidth(window.innerWidth);
    window.addEventListener("resize", handleResize);
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  return (
    <div>
      <div className="page-wrapper">
        <header className="main-header header-style-two">
          {/* Header Top */}
          <div className="header-top">
            <Box sx={{ flexGrow: 1 }}>
              <AppBar position="static" sx={{ backgroundColor: "#393d72" }}>
                <Toolbar>
                  <Typography
                    variant="h6"
                    component="div"
                    sx={{ flexGrow: 1, textAlign: "left" }}
                  >
                  
                    <Button
                      color="inherit"
                      startIcon={
                        <EmailIcon style={{ color: "rgb(255,174,0)" }} />
                      }
                      href="mailto:contacto@nannapp.com.mx"
                    >
                      
                    </Button>
                  </Typography>
                  <Typography
                    variant="h6"
                    component="div"
                    sx={{ flexGrow: 1, textAlign: "right" }}
                  >
                    <Button color="inherit" onClick={() => setOpenSignUp(true)}>
                      Crear Cuenta
                    </Button>
                    <SignUpDialog
                      open={openSignUp}
                      onClose={() => setOpenSignUp(false)}
                    />
                    <Button color="inherit" onClick={() => setOpenLogin(true)}>
                      Iniciar Sesión
                    </Button>
                    <LoginDialog
                      open={openLogin}
                      onClose={() => setOpenLogin(false)}
                      onResetPasswordClick={handleResetPasswordClick} // Added this line
                    />
                    <ResetPasswordDialog
                      open={openResetPassword}
                      onClose={() => setOpenResetPassword(false)}
                    />
                  </Typography>
                </Toolbar>
              </AppBar>
            </Box>
          </div>
          {/* Header Top */}
          {/* Header Upper */}
          <div className="header-lower">
            <MenuNavbar />
          </div>
          {/*End Header Upper*/}
          {/* Sticky Header  */}
          <div className="sticky-header">
            <div className="auto-container clearfix">
              {/*Logo*/}
              <div className="logo pull-left">
                <a href="index.html" title>
                  <img src="images/logo.png" alt="" title />
                </a>
              </div>
              {/*Right Col*/}
              <div className="pull-right">
                {/* Main Menu */}
                <nav className="main-menu">
                  <div className="navbar-collapse show collapse clearfix">
                    <ul className="navigation clearfix">
                      <li className="current dropdown">
                      <Link to='/landing'>Home</Link>
                        <ul>
                          <li>
                            <a href="index.html">Home page 01</a>
                          </li>
                          <li>
                            <a href="index-2.html">Home page 02</a>
                          </li>
                          <li className="dropdown">
                            <a href="index.html">Header Styles</a>
                            <ul>
                              <li>
                                <a href="index.html">Header Style One</a>
                              </li>
                              <li>
                                <a href="index-2.html">Header Style Two</a>
                              </li>
                            </ul>
                          </li>
                        </ul>
                      </li>
                      {/* <li>
                        <a href="about.html">About Us</a>
                      </li>
                      <li className="dropdown">
                        <a href="events.html">Events</a>
                        <ul>
                          <li>
                            <a href="events.html">Events</a>
                          </li>
                          <li>
                            <a href="event-detail.html">Event Detail</a>
                          </li>
                        </ul>
                      </li>
                      <li className="dropdown">
                        <a href="programs.html">Programs</a>
                        <ul>
                          <li>
                            <a href="numbers-matching.html">Numbers Matching</a>
                          </li>
                          <li>
                            <a href="paper-plates-art.html">Paper Plates Art</a>
                          </li>
                          <li>
                            <a href="create-cartoon-egg.html">
                              Create Egg Cartoon
                            </a>
                          </li>
                          <li>
                            <a href="cooking-fun-cake.html">Cooking Fun Cake</a>
                          </li>
                          <li>
                            <a href="super-colors-game.html">
                              Super Colors Game
                            </a>
                          </li>
                          <li>
                            <a href="learning-disciplines.html">
                              Learning Disciplines
                            </a>
                          </li>
                        </ul>
                      </li>
                      <li>
                        <a href="gallery.html">Gallery</a>
                      </li>
                      <li className="dropdown">
                        <a href="news.html">Our News</a>
                        <ul>
                          <li>
                            <a href="news.html">Our News</a>
                          </li>
                          <li>
                            <a href="news-single.html">News Single</a>
                          </li>
                        </ul>
                      </li>
                      <li>
                        <a href="contact.html">Contact</a>
                      </li> */}
                    </ul>
                  </div>
                </nav>
                {/* Main Menu End*/}
              </div>
            </div>
          </div>
          {/* End Sticky Menu */}
        </header>
        {/* End Main Header */}
        {/*Page Title */}
        <section className="page-title" style={{backgroundImage: 'url(images/background/1.jpg)'}}>
    <div className="auto-container">
        <div className="inner-container clearfix">
            
            <h1>Políticas Familiares</h1>
        </div>
    </div>
</section>
{/* End Page Title */}
        <section style={{padding: '50px', backgroundColor: '#f2f2f2', color: '#333', textAlign: 'justify', fontFamily: 'Arial, sans-serif', paddingLeft: "40px" , paddingRight:"40px"}}>
          <h2>NUESTRAS POLÍTICAS. NANNY DE LA GUARDA</h2>
          
          <h3>PAGOS:</h3>
          <p>Los pagos se realizan todos los lunes de la semana siguiente a los días trabajados en la anterior, por medio de transferencia electrónica.</p>

          <h3>HORAS EXTRA:</h3>
          <p>Las horas extra se pagan en $50 pesos, si pasan las 12 horas de un cuidado. Siempre que la familia lo solicite, debe notificarse a NANNAPP.</p>

          <h3>TRANSPORTE:</h3>
          <p>A partir de las 10pm, la familia deberá brindar Uber / Didi de regreso a su domicilio.</p>

          <h3>UNIFORME:</h3>
          <p>Llevar la filipina con el logotipo de NANNAPP y ponérsela al entrar al domicilio. Si la familia reporta que no se lleva el uniforme, se sancionará con $100 pesos que se descuentan del pago del cuidado. Si es un cuidado de emergencia, y no da tiempo de ir por el uniforme se hará una excepción.</p>

          <h3>PUNTUALIDAD:</h3>
          <p>Se les solicita a las nannies que lleguen 15 minutos antes para que no se retrasen en el acceso. En casos de fuerza mayor, tienen 15 minutos de tolerancia. Al minuto 16 después de la hora de entrada, se cuenta como retraso y se penaliza con $50 pesos del pago y a la familia se le da una hora de cortesía para resarcir la falla, además de recuperarles el tiempo al final, si aún les es útil. Si la nanny termina el cuidado después de su hora, a partir del minuto 16 cuenta como media hora extra.</p>

          <h3>ALIMENTOS:</h3>
          <p>Se brindan cuando el cuidado es fijo, y se solicita a la familia mínimo 30 min. para comer.</p>

          <h3>VIAJES O VACACIONES:</h3>
          <p>Si la familia va a salir de vacaciones, debe notificar con mínimo una semana de anticipación, para que la nanny pueda disponer de ese tiempo para realizar otros cuidados o aprovechar ese tiempo para asuntos o trámites personales. Si la nanny viaja con la familia, se le cubren sus gastos: hospedaje, comidas, transporte.</p>

          <h3>DÍAS FESTIVOS:</h3>
          <p>Se da descanso en días festivos oficiales y si se requiere que asista, se pagan doble.</p>

          <h3>CANCELACIONES:</h3>
          <p>Si la nanny cancela un cuidado confirmado con menos de 24 horas, se le penalizará con 2 horas de su pago, equivalente a $250 pesos, los cuales se usarán para compensar a la familia.</p>

          <h3>SEGURO CONTRA ACCIDENTES:</h3>
          <p>Tanto las nannies como los bebés cuentan con una póliza de seguro contra accidentes, como medida de prevención.</p>

          <h3>BONOS:</h3>
          <p>Se pagan $200 por cada nanny recomendada que sea aceptada y cubra su primer cuidado. También por recomendar los servicios de NANNAPP a nuevas familias, si nos contratan.</p>

          <h3>LEALTAD:</h3>
          <p>Si se descubre que la nanny se contrata por fuera de la plataforma, será sancionada económica y legalmente. Si la nanny comprueba que la familia le ofreció que realice cuidados por su cuenta, se le dará un bono de lealtad. Evitemos malas prácticas y cuidemos nuestra comunidad.</p>

          <p><strong>ATENTAMENTE:</strong><br />
          Claudia Cervantes<br />
          Fundadora y Directora</p>
        </section>
        {/*Main Footer*/}
        <footer className="main-footer">
          <div className="anim-icons">
            <span
              className="icon icon-sparrow wow zoomIn"
              data-wow-delay="400ms"
            />
            <span
              className="icon icon-rainbow-2 wow zoomIn"
              data-wow-delay="800ms"
            />
            <span className="icon icon-star-3" />
            <span className="icon icon-star-3 two" />
            <span className="icon icon-sun" />
            <span
              className="icon icon-plane wow zoomIn"
              data-wow-delay="1200ms"
            />
          </div>
          
          {/*footer upper*/}
          <div className="footer-upper">
            <div className="auto-container">
              <div className="row clearfix">
                {/*Big Column*/}
                <div className="big-column col-xl-5 col-lg-12 col-md-12 col-sm-12">
                  <div className="row clearfix">
                    {/*Footer Column*/}
                    <div className="footer-column col-lg-6 col-md-6 col-sm-12">
                      <div className="footer-widget logo-widget">
                        <div className="logo">
                          {/* <a href="index.html"><img src="images/footer-logo.png" alt="" /></a> */}
                        </div>
                        <div className="text">
                          Las mamás y los papás necesitan el apoyo de una nanny
                          que cuide a sus hijos con calidad
                        </div>
                      </div>
                    </div>
                    {/*Footer Column*/}
                    <div className="footer-column col-lg-5 col-md-6 col-sm-12">
                      <div className="footer-widget contact-widget">
                        <h4 className="widget-title">Contact</h4>
                        <div className="widget-content">
                          <ul className="contact-info">
                            <li>
                              <a href="mailto:contacto@nannapp.com.mx">
                                <span className="fa fa-envelope" />
                                contacto@nannapp.com.mx
                              </a>
                            </li>
                            <li>
                              <span className="fa fa-map" /> Santa Fe, Ciudad de
                              México{" "}
                            </li>
                          </ul>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                {/*Big Column*/}
                <div className="big-column col-xl-7 col-lg-12 col-md-12 col-sm-12">
                  <div className="row clearfix">
                    
                    <div className="footer-column col-xl-3 col-lg-3 col-md-6 col-sm-4">
                      <div className="footer-widget links-widget">
                        <h4 className="widget-title">Links</h4>
                        <div className="widget-content">
                        <ul className="list">
                            <li>
                              <Link to="/terms-and-conditions">Términos y Condiciones</Link>
                            </li>
                            <li>
                              <Link to="/privacy-notice">Aviso de Privacidad</Link>
                            </li>
                            <li>
                              <Link to="/family-policies">Políticas Familiares</Link>
                            </li>
                            <li>
                              <Link to="/nanny-policies">Políticas Nanny</Link>
                            </li>
                          </ul>
                        </div>
                      </div>
                    </div>
                    
                    {/*
                    <div className="footer-column col-xl-5 col-lg-6 col-md-12 col-sm-12">
                      <div className="footer-widget newsletter-widget">
                        <h4 className="widget-title">Newsletter</h4>
                        <div className="newsletter-form">
                          <form method="post" action="contact.html">
                            <div className="form-group">
                              <button
                                type="submit"
                                className="theme-btn btn-style-one"
                              >
                                Subscribe
                              </button>
                            </div>
                          </form>
                        </div>
                      </div>
                    </div>
                    */}
                  </div>
                </div>
              </div>
            </div>
          </div>
          {/*Footer Bottom*/}
          <div className="footer-bottom">
            <div className="auto-container">
              <ul className="social-icon-colored">
                <li>
                  <a href="https://www.instagram.com/nannappmexico/">
                    <span className="fab fa-instagram" />
                  </a>
                </li>
                
                  
              </ul>
              <div className="copyright"> Copyrights © Nannapp</div>
            </div>
          </div>
        </footer>
        {/*End Main Footer*/}
      </div>
    </div>
  );
}