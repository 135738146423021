import { signOut } from 'firebase/auth';
import {useState} from 'react'
import {auth} from '../firebase/config'
import { useAuthContext } from './useAuthContext'

export const useLogout = () =>{
    const[error,setError] = useState(null);
    const{dispatch} = useAuthContext();

    const logout = () => {

        signOut(auth).then(() => {
            dispatch({type:'LOGOUT'});
        }).catch((err) => {
            console.log('User logout error', err);
            setError(err.message);
        })

    }
    return {logout,error}
}