/* eslint-disable no-unused-vars */
/* eslint-disable jsx-a11y/anchor-is-valid */
import React from 'react';
import "bootstrap/dist/css/bootstrap.css";
import "bootstrap/dist/js/bootstrap.js";
import "./css/style.css";
import { AppBar, Box, Toolbar, Typography, Button } from "@mui/material";
import EmailIcon from "@mui/icons-material/Email";
import { useState, useEffect } from 'react';


import MenuNavbar from "./components/MenuNavbar";
import SignUpDialog from "./components/SignUpDialog";
import LoginDialog from "./components/LoginDialog";
import ResetPasswordDialog from "./components/ResetPasswordDialog";
import { Link } from "react-router-dom";
export default function FamilyPolicies() {
  const [imgUrl, setImgUrl] = useState("");
  const [width, setWidth] = useState(window.innerWidth);

  const [openSignUp, setOpenSignUp] = useState(false);
  const [openLogin, setOpenLogin] = useState(false);

  const [openResetPassword, setOpenResetPassword] = useState(false);
  const handleResetPasswordClick = () => {
    setOpenLogin(false);
    setOpenResetPassword(true);
  };

  useEffect(() => {
    const mediaQuery = window.matchMedia("(max-width: 768px)");
    const handleMediaQueryChange = (mediaQuery) => {
      if (mediaQuery.matches) {
        setImgUrl(require("./images/Banner_Slider_p3.png")); /*Mobile Slider*/
      } else {
        setImgUrl(require("./images/Banner_Slider_p.png")); /*Desktop Slider*/
      }
    };

    handleMediaQueryChange(mediaQuery);
    mediaQuery.addListener(handleMediaQueryChange);

    return () => mediaQuery.removeListener(handleMediaQueryChange);
  }, []);

  useEffect(() => {
    const handleResize = () => setWidth(window.innerWidth);
    window.addEventListener("resize", handleResize);
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  return (
    <div>
      <div className="page-wrapper">
        <header className="main-header header-style-two">
          {/* Header Top */}
          <div className="header-top">
            <Box sx={{ flexGrow: 1 }}>
              <AppBar position="static" sx={{ backgroundColor: "#393d72" }}>
                <Toolbar>
                  <Typography
                    variant="h6"
                    component="div"
                    sx={{ flexGrow: 1, textAlign: "left" }}
                  >
                  
                    <Button
                      color="inherit"
                      startIcon={
                        <EmailIcon style={{ color: "rgb(255,174,0)" }} />
                      }
                      href="mailto:contacto@nannapp.com.mx"
                    >
                      
                    </Button>
                  </Typography>
                  <Typography
                    variant="h6"
                    component="div"
                    sx={{ flexGrow: 1, textAlign: "right" }}
                  >
                    <Button color="inherit" onClick={() => setOpenSignUp(true)}>
                      Crear Cuenta
                    </Button>
                    <SignUpDialog
                      open={openSignUp}
                      onClose={() => setOpenSignUp(false)}
                    />
                    <Button color="inherit" onClick={() => setOpenLogin(true)}>
                      Iniciar Sesión
                    </Button>
                    <LoginDialog
                      open={openLogin}
                      onClose={() => setOpenLogin(false)}
                      onResetPasswordClick={handleResetPasswordClick} // Added this line
                    />
                    <ResetPasswordDialog
                      open={openResetPassword}
                      onClose={() => setOpenResetPassword(false)}
                    />
                  </Typography>
                </Toolbar>
              </AppBar>
            </Box>
          </div>
          {/* Header Top */}
          {/* Header Upper */}
          <div className="header-lower">
            <MenuNavbar />
          </div>
          {/*End Header Upper*/}
          {/* Sticky Header  */}
          <div className="sticky-header">
            <div className="auto-container clearfix">
              {/*Logo*/}
              <div className="logo pull-left">
                <a href="index.html" title>
                  <img src="images/logo.png" alt="" title />
                </a>
              </div>
              {/*Right Col*/}
              <div className="pull-right">
                {/* Main Menu */}
                <nav className="main-menu">
                  <div className="navbar-collapse show collapse clearfix">
                    <ul className="navigation clearfix">
                      <li className="current dropdown">
                      <Link to='/landing'>Home</Link>
                        <ul>
                          <li>
                            <a href="index.html">Home page 01</a>
                          </li>
                          <li>
                            <a href="index-2.html">Home page 02</a>
                          </li>
                          <li className="dropdown">
                            <a href="index.html">Header Styles</a>
                            <ul>
                              <li>
                                <a href="index.html">Header Style One</a>
                              </li>
                              <li>
                                <a href="index-2.html">Header Style Two</a>
                              </li>
                            </ul>
                          </li>
                        </ul>
                      </li>
                      {/* <li>
                        <a href="about.html">About Us</a>
                      </li>
                      <li className="dropdown">
                        <a href="events.html">Events</a>
                        <ul>
                          <li>
                            <a href="events.html">Events</a>
                          </li>
                          <li>
                            <a href="event-detail.html">Event Detail</a>
                          </li>
                        </ul>
                      </li>
                      <li className="dropdown">
                        <a href="programs.html">Programs</a>
                        <ul>
                          <li>
                            <a href="numbers-matching.html">Numbers Matching</a>
                          </li>
                          <li>
                            <a href="paper-plates-art.html">Paper Plates Art</a>
                          </li>
                          <li>
                            <a href="create-cartoon-egg.html">
                              Create Egg Cartoon
                            </a>
                          </li>
                          <li>
                            <a href="cooking-fun-cake.html">Cooking Fun Cake</a>
                          </li>
                          <li>
                            <a href="super-colors-game.html">
                              Super Colors Game
                            </a>
                          </li>
                          <li>
                            <a href="learning-disciplines.html">
                              Learning Disciplines
                            </a>
                          </li>
                        </ul>
                      </li>
                      <li>
                        <a href="gallery.html">Gallery</a>
                      </li>
                      <li className="dropdown">
                        <a href="news.html">Our News</a>
                        <ul>
                          <li>
                            <a href="news.html">Our News</a>
                          </li>
                          <li>
                            <a href="news-single.html">News Single</a>
                          </li>
                        </ul>
                      </li>
                      <li>
                        <a href="contact.html">Contact</a>
                      </li> */}
                    </ul>
                  </div>
                </nav>
                {/* Main Menu End*/}
              </div>
            </div>
          </div>
          {/* End Sticky Menu */}
        </header>
        {/* End Main Header */}
        {/*Page Title */}
        <section className="page-title" style={{backgroundImage: 'url(images/background/1.jpg)'}}>
    <div className="auto-container">
        <div className="inner-container clearfix">
            
            <h1>Políticas Familiares</h1>
        </div>
    </div>
</section>
{/* End Page Title */}
        <section style={{padding: '50px', backgroundColor: '#f2f2f2', color: '#333', textAlign: 'justify', fontFamily: 'Arial, sans-serif', paddingLeft: "40px" , paddingRight:"40px"}}>
          <h2>NUESTRAS POLÍTICAS. FAMILIAS NANNAPP</h2>
          
          <h3>Reservaciones y pagos:</h3>
          <p>Las reservaciones siempre son por medio del Whatsapp 5521296063 y los pagos con transferencia electrónica a la cuenta de la empresa. Si se quiere facturar los cuidados es más IVA: de igual manera se le agrega el IVA, cuando los pagos se realicen con depósito en efectivo. Los cuidados se pagan por anticipado para confirmar la reservación, cuando son fijos, se paga semanal.</p>

          <h3>Horas extra:</h3>
          <p>El costo por hora extra es de $150 pesos y debe avisarse a Nannapp, el tiempo no contratado previamente dependerá de la disposición de la nanny en turno. Cualquier cambio de horario, horas o días extra hay que notificar a Nannapp.</p>

          <h3>Transporte:</h3>
          <p>Pasando las 10pm se le deberá brindar Uber / Didi a la nanny de regreso a su domicilio.</p>

          <h3>Uniforme:</h3>
          <p>Las nannies llevan una filipina con el logotipo de Nannapp, ese es su uniforme.</p>

          <h3>Puntualidad:</h3>
          <p>Se les solicita a las nannies que lleguen 15 minutos antes para que no se retrasen en el acceso. En casos de fuerza mayor, tienen 15 minutos de tolerancia. Después del minuto 16, se cuenta cómo retraso, a ellas se les penaliza con 1 hora del cuidado y a la familia se le da una hora de cortesía o se les recupera el tiempo final, si les es útil. Si la nanny termina el cuidado después de su hora, a partir del minuto 16 cuenta como hora extra.</p>

          <h3>Alimentos:</h3>
          <p>Cuidados fijos hay que darles alimentos. Las nannies tienen mínimo 30 minutos para comer.</p>

          <h3>Vacaciones y viajes:</h3>
          <p>Si la familia va a salir de vacaciones, debe notificar con mínimo una semana de anticipación, para que la nanny pueda disponer de ese tiempo para realizar otros cuidados o aprovechar ese tiempo para asuntos o trámites personales. Si la nanny viaja con la familia hay que cubrir sus gastos: hospedaje, comidas, transporte. Y se debe firmar un disclosure. Cuando es en el extranjero, si la nanny tiene pasaporte/visa, la tarifa aumenta. En cuidados fijos, se paga el 25%, cuando la familia se ausenta, si se quiere conservar la misma nanny.</p>

          <h3>Días festivos:</h3>
          <p>Se da descanso en días festivos oficiales y si se requiere que asista, se pagan doble.</p>

          <h3>Cancelaciones:</h3>
          <p>Si la nanny cancela por causas justificables de fuerza mayor, se compensa a la familia con dos horas extra. Si la familia cancela 24 horas antes, el monto del cuidado queda a crédito. Si la familia cancela el mismo día, se pierde lo pagado. Si es causa justificable de fuerza mayor, se le considera el 50% para otro cuidado.</p>

          <h3>Seguro contra accidentes:</h3>
          <p>Tanto las nannies como los bebés cuentan con una póliza de seguro contra accidentes, como medida de prevención. Póliza #22763-05 Thona Seguros. Tel: 800 400 9911.</p>

          <h3>Lealtad:</h3>
          <p>Si se descubre que la nanny se contrata por fuera de la plataforma, será sancionada económica y legalmente. Si la familia reporta que la nanny le ofreció realizar cuidados por su cuenta, se le dará un turno de cortesía para recompensar su lealtad. Evitemos malas prácticas y cuidemos nuestra comunidad.</p>

          <p><strong>ATENTAMENTE:</strong><br />
          Claudia Cervantes<br />
          Fundadora y Directora</p>
        </section>
        {/*Main Footer*/}
        <footer className="main-footer">
          <div className="anim-icons">
            <span
              className="icon icon-sparrow wow zoomIn"
              data-wow-delay="400ms"
            />
            <span
              className="icon icon-rainbow-2 wow zoomIn"
              data-wow-delay="800ms"
            />
            <span className="icon icon-star-3" />
            <span className="icon icon-star-3 two" />
            <span className="icon icon-sun" />
            <span
              className="icon icon-plane wow zoomIn"
              data-wow-delay="1200ms"
            />
          </div>
          
          {/*footer upper*/}
          <div className="footer-upper">
            <div className="auto-container">
              <div className="row clearfix">
                {/*Big Column*/}
                <div className="big-column col-xl-5 col-lg-12 col-md-12 col-sm-12">
                  <div className="row clearfix">
                    {/*Footer Column*/}
                    <div className="footer-column col-lg-6 col-md-6 col-sm-12">
                      <div className="footer-widget logo-widget">
                        <div className="logo">
                          {/* <a href="index.html"><img src="images/footer-logo.png" alt="" /></a> */}
                        </div>
                        <div className="text">
                          Las mamás y los papás necesitan el apoyo de una nanny
                          que cuide a sus hijos con calidad
                        </div>
                      </div>
                    </div>
                    {/*Footer Column*/}
                    <div className="footer-column col-lg-5 col-md-6 col-sm-12">
                      <div className="footer-widget contact-widget">
                        <h4 className="widget-title">Contact</h4>
                        <div className="widget-content">
                          <ul className="contact-info">
                            <li>
                              <a href="mailto:contacto@nannapp.com.mx">
                                <span className="fa fa-envelope" />
                                contacto@nannapp.com.mx
                              </a>
                            </li>
                            <li>
                              <span className="fa fa-map" /> Santa Fe, Ciudad de
                              México{" "}
                            </li>
                          </ul>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                {/*Big Column*/}
                <div className="big-column col-xl-7 col-lg-12 col-md-12 col-sm-12">
                  <div className="row clearfix">
                    
                    <div className="footer-column col-xl-3 col-lg-3 col-md-6 col-sm-4">
                      <div className="footer-widget links-widget">
                        <h4 className="widget-title">Links</h4>
                        <div className="widget-content">
                        <ul className="list">
                            <li>
                              <Link to="/terms-and-conditions">Términos y Condiciones</Link>
                            </li>
                            <li>
                              <Link to="/privacy-notice">Aviso de Privacidad</Link>
                            </li>
                            <li>
                              <Link to="/family-policies">Políticas Familiares</Link>
                            </li>
                            <li>
                              <Link to="/nanny-policies">Políticas Nanny</Link>
                            </li>
                          </ul>
                        </div>
                      </div>
                    </div>
                    
                    {/*
                    <div className="footer-column col-xl-5 col-lg-6 col-md-12 col-sm-12">
                      <div className="footer-widget newsletter-widget">
                        <h4 className="widget-title">Newsletter</h4>
                        <div className="newsletter-form">
                          <form method="post" action="contact.html">
                            <div className="form-group">
                              <button
                                type="submit"
                                className="theme-btn btn-style-one"
                              >
                                Subscribe
                              </button>
                            </div>
                          </form>
                        </div>
                      </div>
                    </div>
                    */}
                  </div>
                </div>
              </div>
            </div>
          </div>
          {/*Footer Bottom*/}
          <div className="footer-bottom">
            <div className="auto-container">
              <ul className="social-icon-colored">
                <li>
                  <a href="https://www.instagram.com/nannappmexico/">
                    <span className="fab fa-instagram" />
                  </a>
                </li>
                
                  
              </ul>
              <div className="copyright"> Copyrights © Nannapp</div>
            </div>
          </div>
        </footer>
        {/*End Main Footer*/}
      </div>
    </div>
  );
}