import { useEffect, useState } from "react";
import { collection, query, where, onSnapshot, getDocs } from 'firebase/firestore';
import { db } from "../../firebase/config";
import { useAuthContext } from "../../hooks/useAuthContext";
import ConfigureServiceDialog from "./ConfigureServiceDialog";
import Searching from "./Searching";
import FoundServiceCard from "./FoundServiceCard";
import ProfileCard from "./ProfileCard";
import NannyNotFound from "./NannyNotFound";
export default function MainSearchLogic() {
  const [serviceConfigured, setServiceConfigured] = useState(false);
  const [hasPendingServices, setHasPendingServices] = useState(false);
  const [serviceData, setServiceData] = useState(null);

  const { user } = useAuthContext();

  const handleConfigureService = () => {
    setServiceConfigured(true);
  };

  useEffect(() => {

    if (!user) return;
    const fetchServices = async () => {
      const currentUserUid = user.uid;
      const servicesRef = collection(db, "public_services");
      const q = query(servicesRef, where("serviceCreatedBy", "==", currentUserUid));

      // Fetch services at the start
      const querySnapshot = await getDocs(q);
      querySnapshot.forEach((doc) => {
        const service = { id: doc.id, ...doc.data() };
        if (service.serviceStatus === "found") {
          setServiceData(service);
        } else if (service.serviceStatus === "pending") {
          setHasPendingServices(true);
        }
      });

      // Set up real-time listener
      const unsubscribe = onSnapshot(q, (snapshot) => {
        snapshot.docChanges().forEach((change) => {
          const service = { id: change.doc.id, ...change.doc.data() };
          if (change.type === "added" && service.serviceStatus === "pending") {
            setHasPendingServices(true);
          }

          if (change.type === "modified" && service.serviceStatus === "found") {
            setServiceData(service);
          }

          if (change.type === "removed" && service.serviceStatus === "pending") {
            setHasPendingServices(false);
            setServiceData(null);
            setServiceConfigured(false);
          }
           // Add case for "nanny_not_found"
           if (change.type === "modified" && service.serviceStatus === "nanny_not_found") {
            setServiceData(service);
            setServiceConfigured(false);
          }
          // Check for service dismissal by user
          if (change.type === "modified" && service.serviceStatus === "dismissed_by_user") {
            setServiceData(null);
            setServiceConfigured(false);
          }

          if (change.type === "removed" && service.serviceStatus === "payed") {
            setHasPendingServices(false);
            setServiceData(null);
            setServiceConfigured(false);
            //Set notification to user to acknowledge service has been payed

          }
        });
      });

      return () => unsubscribe();
    };
    fetchServices();
  }, [serviceConfigured]);

  return (
    <>
      {serviceConfigured || hasPendingServices || serviceData ? (
        serviceData 
        ? (
          serviceData.serviceStatus === 'nanny_not_found'
          ? <NannyNotFound />
          : <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', marginTop: '10px'}}>
              <FoundServiceCard service={serviceData} /> 
            </div>
        )
        : <Searching />
      ) : (
        <ConfigureServiceDialog onConfigureService={handleConfigureService} />
      )}
    </>
  );
}
