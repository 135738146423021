import React, { useEffect, useState } from "react";
import { loadStripe } from "@stripe/stripe-js";
import {
  CardElement,
  Elements,
  useStripe,
  useElements,
  PaymentElement,
} from "@stripe/react-stripe-js";
import {
  AppBar,
  Dialog,
  IconButton,
  Toolbar,
  Typography,
  Button,
  Divider,
  Card,
  CardContent,
  Accordion,
  AccordionSummary,
  AccordionDetails,
} from "@mui/material";
import { httpsCallable, getFunctions } from "firebase/functions";
import { functions, db } from "../../firebase/config";
import { useAuthContext } from "../../hooks/useAuthContext";
import {
  doc,
  updateDoc,
  arrayUnion,
  getDoc,
  deleteDoc,
  setDoc,
} from "firebase/firestore";
import CloseIcon from "@mui/icons-material/Close";
import Slide from "@mui/material/Slide";
import { TransitionProps } from "@mui/material/transitions";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import { useNavigate } from "react-router-dom";
import "./PaymentDialog.css";

const stripePromise = loadStripe(
  "pk_test_51K0ADBLDzW32tbBWJm3NXZmovOfcxhSTKwr6VINxte6ZXNGHErgSUO4Q089eQGh9lPQTEOt1oADnqbUEDOhSy3p100N2FFDvHM"
);
const functionsInstance = getFunctions();

const addServiceID = async (serviceID, userUID, nannyUID) => {
  // Assuming you have access to the user object and the serviceID
  const userRef = doc(db, "users", userUID);

  try {
    await updateDoc(userRef, {
      services: arrayUnion(serviceID),
    });
    console.log("Service added to user document");
  } catch (error) {
    console.error("Error adding service to user document: ", error);
  }

  const nannyRef = doc(db, "nanny", nannyUID);

  try {
    await updateDoc(nannyRef, {
      services: arrayUnion(serviceID),
    });
    console.log("Service added to nanny document");
  } catch (error) {
    console.error("Error adding service to nanny document: ", error);
  }
};

const changeServiceStatus = async (serviceID) => {
  const serviceRef = doc(db, "public_services", serviceID);

  try {
    await updateDoc(serviceRef, {
      serviceStatus: "payed",
    });
    console.log("Service status changed to assigned");
  } catch (error) {
    console.error("Error changing service status: ", error);
  }
};

const copyServiceToPrivateCollection = async (serviceID) => {
  const publicServiceRef = doc(db, "public_services", serviceID);
  const privateServiceRef = doc(db, "private_services", serviceID);

  try {
    const publicServiceDoc = await getDoc(publicServiceRef);
    if (publicServiceDoc.exists()) {
      const publicServiceData = publicServiceDoc.data();
      await deleteDoc(publicServiceRef);
      await setDoc(privateServiceRef, publicServiceData);
      console.log("Service copied to private collection");
    } else {
      console.error("Public service document does not exist");
    }
  } catch (error) {
    console.error("Error copying service to private collection: ", error);
  }
};

const StripeForm = ({ paymentDetails, handleClose, serviceID, nannyUID }) => {
  const navigate = useNavigate();
  
  const { user } = useAuthContext();
  const stripe = useStripe();
  const elements = useElements();

  const [buttonText, setButtonText] = useState("Confirm Payment");
  const [buttonColor, setButtonColor] = useState("btn-primary"); // initial color

  const handleChange = (event) => {
    if (event.error) {
      console.log(event.error.message);
    }
  };

  const handleSubmit = async (event) => {
    event.preventDefault();

    const cardElement = elements.getElement(CardElement);

    const { error, paymentMethod } = await stripe.createPaymentMethod({
      type: "card",
      card: cardElement,
    });
    const amountInCentavos = paymentDetails * 100; // convert to centavos

    if (!error) {
      const createPaymentIntent = httpsCallable(
        functionsInstance,
        "createPaymentIntent"
      );
      const { data } = await createPaymentIntent({
        paymentMethodId: paymentMethod.id,
        amount: amountInCentavos,
        serviceID: serviceID,
      });

      if (data.success) {
        console.log("Payment successful");
        setButtonText("Pago Realizado");
        setButtonColor("btn-success"); // set the button color to green

        // Add the serviceID to the user document
        await addServiceID(serviceID, user.uid, nannyUID);
        await changeServiceStatus(serviceID);
        await copyServiceToPrivateCollection(serviceID);

        setTimeout(() => {
          handleClose();
          navigate(`/service/${serviceID}/true`);
        }, 500);
      } else {
        console.log("Payment failed");
        setButtonText("Reintentar Pago");
        setButtonColor("btn-danger");
      }
    }
  };

  return (
    <form onSubmit={handleSubmit}>
      <div style={{ maxWidth: "95%", margin: "auto", marginBottom: "20px" }}>
        <div className="form-group">
          <label for="username">
            <h6>Card Owner</h6>
          </label>
          <input
            type="text"
            name="username"
            placeholder="Card Owner Name"
            required
            class="form-control"
          />
        </div>
        <div className="form-group">
          <label for="cardNumber">
            <h6>Card number</h6>
          </label>
          <div className="input-group">
            <CardElement
              options={{ style: { base: { fontSize: "16px" } } }}
              onChange={handleChange}
              className="form-control"
            />
          </div>
        </div>
      </div>
      <div className="card-footer">
        <button
          type="submit"
          class={`subscribe btn btn-block shadow-sm ${buttonColor}`}
        >
          {buttonText}
        </button>
      </div>
    </form>
  );
};

const PaymentSelector = ({
  paymentTotal,
  handleClose,
  serviceID,
  nannyUID,
}) => {
  const [paymentMethod, setPaymentMethod] = useState("credit-card");

  return (
    <div style={{ margin: '10px' }}>
      <div className="row mb-4">
        <Typography
          variant="h6"
          component="h2"
          style={{
            overflow: "hidden",
            textOverflow: "ellipsis",
            whiteSpace: "nowrap",
            width: "100%",
            fontFamily: '"Fredoka One", sans-serif', // Set the font family
            color: "rgb(57, 61, 114)", // Set the color
          }}
        >
          Datos de Pago
        </Typography>
      </div>
      <div className="row">
        <div className="col-lg-6 mx-auto">
          <div className="card ">
            <div className="card-header">
              <ul
                role="tablist"
                class="nav bg-light nav-pills rounded nav-fill mb-3"
              >
                <li className="nav-item">
                  <button
                    className={`nav-link ${
                      paymentMethod === "credit-card" ? "active" : ""
                    }`}
                    onClick={() => setPaymentMethod("credit-card")}
                  >
                    <i className="fas fa-credit-card mr-2"></i> Tarjetas de Crédito/Débito
                  </button>
                </li>
                {/* <li className="nav-item">
                  <button
                    className={`nav-link ${
                      paymentMethod === "paypal" ? "active" : ""
                    }`}
                    onClick={() => setPaymentMethod("paypal")}
                  >
                    <i className="fab fa-paypal mr-2"></i> Paypal
                  </button>
                </li> */}
              </ul>
            </div>
            <div className="tab-content">
              {paymentMethod === "credit-card" && (
                <div
                  id="credit-card"
                  className="tab-pane fade show active pt-3"
                >
                  <Elements stripe={stripePromise}>
                    <StripeForm
                      paymentDetails={paymentTotal}
                      handleClose={handleClose}
                      serviceID={serviceID}
                      nannyUID={nannyUID}
                    />
                  </Elements>
                </div>
              )}
              {paymentMethod === "paypal" && (
                <div id="paypal" className="tab-pane fade pt-3">
                  <h6 className="pb-2">Select your paypal account type</h6>
                  <div className="form-group">
                    <label className="radio-inline">
                      <input type="radio" name="optradio" checked /> Domestic
                    </label>
                    <label className="radio-inline">
                      <input type="radio" name="optradio" className="ml-5" />{" "}
                      International
                    </label>
                  </div>
                  <p>
                    <button type="button" className="btn btn-primary">
                      <i className="fab fa-paypal mr-2"></i> Log into my Paypal
                    </button>
                  </p>
                  <p className="text-muted">
                    Note: After clicking on the button, you will be directed to
                    a secure gateway for payment. After completing the payment
                    process, you will be redirected back to the website to view
                    details of your order.
                  </p>
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

const StripePayment = ({
  paymentTotal,
  handleClose,
  serviceID,
  nannyUID}) => {
  const [clientSecret, setClientSecret] = useState("");

  const { user } = useAuthContext();

  useEffect(() => {
    const createPaymentIntent = async () => {
      try {
        const amountInCentavos = paymentTotal * 100;

        const createPaymentIntentFunction = httpsCallable(
          functions,
          'createPaymentIntent'
        );
        const paymentResponse = await createPaymentIntentFunction({
          amount: amountInCentavos,
          serviceID: serviceID,
          userUID: user.uid,
          nannyUID: nannyUID,
        });

        const clientSecretFetch = paymentResponse.data.clientSecret;
        setClientSecret(clientSecretFetch);
      } catch (error) {
        console.error('Error creating payment intent:', error.message);
      }
    };

    createPaymentIntent();
  }, [paymentTotal, serviceID]);

  const appearance = {
    theme: 'flat',
    variables: {
      fontFamily: ' "Fredoka One", sans-serif',
      fontLineHeight: '1.5',
      borderRadius: '10px',
      colorBackground: '#ffffff',
      accessibleColorOnColorPrimary: '#262626'
    },
    rules: {
      '.Block': {
        backgroundColor: 'var(--colorBackground)',
        boxShadow: 'none',
        padding: '12px'
      },
      '.Input': {
        padding: '12px'
      },
      '.Input:disabled, .Input--invalid:disabled': {
        color: 'lightgray'
      },
      '.Tab': {
        padding: '10px 12px 8px 12px',
        border: 'none'
      },
      '.Tab:hover': {
        border: 'none',
        boxShadow: '0px 1px 1px rgba(0, 0, 0, 0.03), 0px 3px 7px rgba(18, 42, 66, 0.04)'
      },
      '.Tab--selected, .Tab--selected:focus, .Tab--selected:hover': {
        border: 'none',
        backgroundColor: '#fff',
        boxShadow: '0 0 0 1.5px var(--colorPrimaryText), 0px 1px 1px rgba(0, 0, 0, 0.03), 0px 3px 7px rgba(18, 42, 66, 0.04)'
      },
      '.Label': {
        fontWeight: '500'
      }
    }
  };

  const options = {
    clientSecret, 
    appearance,
    locale: 'es'
  }

  return (
    <div>
      {clientSecret && 
        (
          <Elements options={options} stripe={stripePromise}>
            <CheckoutForm paymentDetails={paymentTotal}
                      handleClose={handleClose}
                      serviceID={serviceID}
                      nannyUID={nannyUID}/>
          </Elements>
        )
      }
    </div>
  );

};

const CheckoutForm = (
  {handleClose,
    paymentDetails,
    serviceID,
    nannyUID,}
) => {
  const stripe = useStripe();
  const elements = useElements();

  const [message, setMessage] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  
  const {user} = useAuthContext();  

  useEffect(() => {
    if (!stripe) {
      return;
    }

    const clientSecret = new URLSearchParams(window.location.search).get(
      "payment_intent_client_secret"
    );

    if (!clientSecret) {
      return;
    }

    stripe.retrievePaymentIntent(clientSecret).then(({ paymentIntent }) => {
      switch (paymentIntent.status) {
        case "succeeded":
          addServiceID(serviceID, user.uid, nannyUID);
          changeServiceStatus(serviceID);
          copyServiceToPrivateCollection(serviceID);
  
          
          setMessage("Payment succeeded!");
          break;
        case "processing":
          setMessage("Your payment is processing.");
          break;
        case "requires_payment_method":
          setMessage("Your payment was not successful, please try again.");
          break;
        default:
          setMessage("Something went wrong.");
          break;
      }
    });
  }, [stripe]);

  const handleSubmit = async (e) => {
    e.preventDefault();

    if (!stripe || !elements) {
      return;
    }

    setIsLoading(true);

    const { error } = await stripe.confirmPayment({
      elements,
      confirmParams: {
        return_url: `https://www.nannapp.com.mx/service/${serviceID}/true`,
      },
    });

    console.log(error);
    if (error.type === "card_error" || error.type === "validation_error") {
      setMessage(error.message);
    } else {
      setMessage("An unexpected error occurred. Try again.");
    }

    setIsLoading(false);
  };

  const paymentElementOptions = {
    layout: "tabs"
  }

  return (
    <form id="payment-form" onSubmit={handleSubmit} style={{width:'100%'}}>

      <PaymentElement id="payment-element" options={paymentElementOptions} />
      {message && <div id="payment-message">{message}</div>}
      <button disabled={isLoading || !stripe || !elements} id="submit" style={{
         borderRadius: '10px', 
         color: 'white', 
         backgroundColor: '#fed557', 
         padding: '12px 24px',
         fontSize: '16px',
         marginTop: '15px', 
         width: '100%', 
         fontFamily: '"Fredoka One", sans-serif',
      }}
      >
        <span id="button-text">
          {isLoading ? <div className="spinner" id="spinner"></div> : "Realizar Pago"}
        </span>
      </button>
    </form>
  );

}

const PaymentDialog = ({
  handleClose,
  paymentDetails,
  serviceID,
  nannyUID,
}) => {
  const [serviceData, setServiceData] = useState(null);

  useEffect(() => {
    // Define the function to fetch service data
    const fetchServiceData = async () => {
      try {
        const docRef = doc(db, "public_services", serviceID);
        const docSnap = await getDoc(docRef);

        if (docSnap.exists()) {
          setServiceData(docSnap.data()); // Update state with fetched data
        } else {
          console.log("No such document!");
        }
      } catch (error) {
        console.error("Error getting document:", error);
      }
    };

    fetchServiceData(); // Call the fetch function
  }, [serviceID]);

  return (
    <React.Fragment>
      <Dialog
        fullScreen
        TransitionComponent={Transition}
        open={true}
        sx={{
          "& .MuiDialog-paper": {
            // Targeting the paper element inside the dialog
            backgroundColor: "#f9f5f0", // Replace #yourColor with your desired color
            // You can add more styling here as needed
          },
        }} // Set the background color
        onClose={handleClose}
      >
        <AppBar sx={{ position: "relative", backgroundColor: "#393d72" }}>
          <Toolbar>
            <IconButton
              edge="start"
              color="inherit"
              onClick={handleClose}
              aria-label="close"
            >
              <CloseIcon />
            </IconButton>
            <Typography
              sx={{
                ml: 2,
                flex: 1,
                fontFamily: "Fredoka One",
                textAlign: "center",
                textColor: "#ffae00",
              }}
              variant="h7"
              component="div"
            >
              Confirma y Realiza tu Pago del Cuidado
            </Typography>
          </Toolbar>
        </AppBar>
        {serviceData && (
          <div style={{ margin: "10px", marginTop: "15px" }}>
            <div
              style={{
                borderRadius: "10px",
                padding: "10px",
                backgroundColor: "#ffff",
              }}
            >
              <div>
                <Typography
                  variant="h6"
                  component="h2"
                  style={{
                    overflow: "hidden",
                    textOverflow: "ellipsis",
                    whiteSpace: "nowrap",
                    width: "100%",
                    fontFamily: '"Fredoka One", sans-serif', // Set the font family
                    color: "rgb(57, 61, 114)", // Set the color
                  }}
                >
                  Tipo de Servicio:
                </Typography>{" "}
                {serviceData.serviceType}
              </div>
            </div>
            <div
              style={{
                borderRadius: "10px",
                marginTop: "10px",
                padding: "10px",
                backgroundColor: "#ffff",
              }}
            >
              <div>
                <Typography
                  variant="h6"
                  component="h2"
                  style={{
                    overflow: "hidden",
                    textOverflow: "ellipsis",
                    whiteSpace: "nowrap",
                    width: "100%",
                    fontFamily: '"Fredoka One", sans-serif', // Set the font family
                    color: "rgb(57, 61, 114)", // Set the color
                  }}
                >
                  Detalles de l@s pequeñ@/s:
                </Typography>{" "}
              </div>
              {serviceData.serviceFor.map((service, index) => (
                <div
                  key={index}
                  style={{
                    display: "flex",
                    marginBottom: "10px",
                    marginTop: "10px",
                  }}
                >
                  {/* First column with icon and text */}
                  <div
                    style={{
                      marginRight: "20px",
                      display: "flex",
                      alignItems: "center",
                    }}
                  >
                    {/* Replace with your icon component */}
                    <img
                      loading="lazy"
                      src="https://cdn.builder.io/api/v1/image/assets/TEMP/bdd5a3f8dada3ccb2edecc4816ff901a0a425d44f384be6c75592b78668cbdd6?apiKey=f9ed1825c5104496bd57288a76ba297c&"
                      className="img"
                    />
                    <span style={{ marginLeft: "10px" }}>{service.name}</span>
                  </div>
                  {/* Second column with just text */}
                  <div>{service.age}</div>
                </div>
              ))}
            </div>
            <div
              style={{
                borderRadius: "10px",
                marginTop: "10px",
                padding: "10px",
                backgroundColor: "#ffff",
              }}
            >
              <div>
                <Typography
                  variant="h6"
                  component="h2"
                  style={{
                    overflow: "hidden",
                    textOverflow: "ellipsis",
                    whiteSpace: "nowrap",
                    width: "100%",
                    fontFamily: '"Fredoka One", sans-serif', // Set the font family
                    color: "rgb(57, 61, 114)", // Set the color
                  }}
                >
                  Dirección:
                </Typography>
                {serviceData.serviceIsRestaurant
                  ? serviceData.serviceRestaurantAddress
                  : serviceData.serviceAddress}
              </div>
            </div>

            <Divider
              variant="middle"
              style={{ margin: "10px", backgroundColor: "rgb(140, 140, 140)" }}
            />
            <div>
              <Accordion elevation={0} sx={{ borderRadius: "10px" }}>
                <AccordionSummary
                  expandIcon={<ExpandMoreIcon />}
                  aria-controls="panel1a-content"
                  id="panel1a-header"
                >
                  {/* Adjusted for left alignment */}
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "column",
                      alignItems: "flex-start",
                      width: "100%",
                    }}
                  >
                    <Typography
                      variant="h6"
                      component="h2"
                      style={{
                        overflow: "hidden",
                        textOverflow: "ellipsis",
                        whiteSpace: "nowrap",
                        fontFamily: '"Fredoka One", sans-serif', // Set the font family
                        color: "rgb(57, 61, 114)", // Set the color
                        textAlign: "left", // Align text to the left
                      }}
                    >
                      <strong>Detalles de Horario</strong>
                    </Typography>
                    {serviceData.serviceDays && (
                      <Typography
                        variant="body2"
                        color="text.secondary"
                        style={{ textAlign: "left" }} // Align text to the left
                      >
                        Número de Días: {serviceData.serviceDays.length}
                      </Typography>
                    )}
                  </div>
                </AccordionSummary>
                <AccordionDetails style={{ flexDirection: "column" }}>
                  <div
                    style={{
                      maxHeight: "300px",
                      overflowY: "auto",
                      marginTop: "20px",
                    }}
                  >
                    {serviceData.serviceDays &&
                      serviceData.serviceDays.map((serviceDay, index) => {
                        const miliseconds = serviceDay.day * 1000;
                        const formattedDate = new Date(
                          miliseconds
                        ).toLocaleDateString("es-ES", {
                          day: "numeric",
                          month: "long",
                          year: "numeric",
                        });
                        const formattedStartTime = new Date(
                          serviceDay.startTime
                        ).toLocaleTimeString("es-ES", {
                          hour: "2-digit",
                          minute: "2-digit",
                        });
                        const formattedEndTime = new Date(
                          serviceDay.endTime
                        ).toLocaleTimeString("es-ES", {
                          hour: "2-digit",
                          minute: "2-digit",
                        });
                        return (
                          <Card
                            key={index}
                            style={{
                              marginBottom: "10px",
                              backgroundColor: "#f9f5f0",
                              borderColor: "rgb(57, 61, 114)",
                            }}
                          >
                            <CardContent>
                              <Typography variant="h6" component="h2">
                                {formattedDate}
                              </Typography>
                              <Typography
                                variant="body2"
                                color="text.secondary"
                              >
                                Hora de Inicio: {formattedStartTime}
                              </Typography>
                              <Typography
                                variant="body2"
                                color="text.secondary"
                              >
                                Hora de Finalización: {formattedEndTime}
                              </Typography>
                            </CardContent>
                          </Card>
                        );
                      })}
                  </div>
                </AccordionDetails>
              </Accordion>
            </div>
            <div
              style={{
                borderRadius: "10px",
                padding: "10px",
                backgroundColor: "#ffff",
                marginTop: "10px",
              }}
            >
              <Typography
                variant="h5"
                component="h2"
                style={{
                  overflow: "hidden",
                  textOverflow: "ellipsis",
                  whiteSpace: "nowrap",
                  width: "100%",
                  fontFamily: '"Fredoka One", sans-serif', // Set the font family
                  color: "rgb(57, 61, 114)", // Set the color
                }}
              >
                Pago Nannapp:
              </Typography>
              <Typography
                variant="h4"
                component="h1"
                style={{
                  overflow: "hidden",
                  textOverflow: "ellipsis",
                  whiteSpace: "nowrap",
                  width: "100%",
                  fontFamily: '"Fredoka One", sans-serif', // Set the font family
                  color: "#FED557", // Set the color
                }} // Align text to the left
              >
                ${serviceData.serviceTotal.toFixed(2)}
              </Typography>
              <div
                style={{
                  display: "flex",
                  justifyContent: "space-between",
                  alignItems: "center",
                }}
              >
                <Typography
                  variant="h6"
                  component="h2"
                  style={{
                    overflow: "hidden",
                    textOverflow: "ellipsis",
                    whiteSpace: "nowrap",
                    fontFamily: '"Fredoka One", sans-serif', // Set the font family
                    color: "rgb(57, 61, 114)", // Set the color
                  }}
                >
                  Cuota transporte privado:
                  <br />
                  (A partir de las 10 PM)
                </Typography>
                <Typography
                  variant="h5"
                  component="h2"
                  style={{
                    overflow: "hidden",
                    textOverflow: "ellipsis",
                    whiteSpace: "nowrap",
                    fontFamily: '"Fredoka One", sans-serif', // Set the font family
                    color: "#FED557", // Set the color
                  }} // Align text to the left
                >
                  ${serviceData.transportFeeTotal.toFixed(2)}
                </Typography>
              </div>
              <div
                style={{
                  display: "flex",
                  justifyContent: "space-between",
                  alignItems: "center",
                }}
              >
                <Typography
                  variant="h6"
                  component="h2"
                  style={{
                    overflow: "hidden",
                    textOverflow: "ellipsis",
                    whiteSpace: "nowrap",
                    fontFamily: '"Fredoka One", sans-serif', // Set the font family
                    color: "rgb(57, 61, 114)", // Set the color
                  }}
                >
                  Comisión Pasarela de Pago:
                  <br />
                  (3.6%)
                </Typography>
                <Typography
                  variant="h5"
                  component="h2"
                  style={{
                    overflow: "hidden",
                    textOverflow: "ellipsis",
                    whiteSpace: "nowrap",
                    fontFamily: '"Fredoka One", sans-serif', // Set the font family
                    color: "#FED557", // Set the color
                  }} // Align text to the left
                >
                  ${serviceData.serviceTransactionFee.toFixed(2)}
                </Typography>
              </div>
              <div
                style={{
                  display: "flex",
                  justifyContent: "flex-end", // Align items to the right
                  alignItems: "center", // Correctly align items vertically
                }}
              >
                <Typography
                  variant="h6"
                  component="h2"
                  style={{
                    overflow: "hidden",
                    textOverflow: "ellipsis",
                    whiteSpace: "nowrap",
                    fontFamily: '"Fredoka One", sans-serif', // Set the font family
                    color: "rgb(57, 61, 114)", // Set the color
                  }}
                >
                    Subtotal: &nbsp;
                </Typography>
                <Typography
                  variant="h5"
                  component="h2"
                  style={{
                    overflow: "hidden",
                    textOverflow: "ellipsis",
                    whiteSpace: "nowrap",
                    fontFamily: '"Fredoka One", sans-serif', // Set the font family
                    color: "#FED557", // Set the color
                  }} // Correct comment to align with CSS properties
                >
                  ${serviceData.serviceSubtotal.toFixed(2)}
                </Typography>
              </div>
              <div
                style={{
                  display: "flex",
                  justifyContent: "flex-end", // Align items to the right
                  alignItems: "center", // Correctly align items vertically
                }}
              >
                <Typography
                  variant="h6"
                  component="h2"
                  style={{
                    overflow: "hidden",
                    textOverflow: "ellipsis",
                    whiteSpace: "nowrap",
                    fontFamily: '"Fredoka One", sans-serif', // Set the font family
                    color: "rgb(57, 61, 114)", // Set the color
                  }}
                >
                    IVA: &nbsp;
                </Typography>
                <Typography
                  variant="h5"
                  component="h2"
                  style={{
                    overflow: "hidden",
                    textOverflow: "ellipsis",
                    whiteSpace: "nowrap",
                    fontFamily: '"Fredoka One", sans-serif', // Set the font family
                    color: "#FED557", // Set the color
                  }} // Correct comment to align with CSS properties
                >
                  ${serviceData.serviceTax.toFixed(2)}
                </Typography>
              </div>
              <div
                style={{
                  display: "flex",
                  justifyContent: "flex-end", // Align items to the right
                  alignItems: "center", // Correctly align items vertically
                  marginTop: "5px",
                }}
              >
                <Typography
                  variant="h4"
                  component="h2"
                  style={{
                    overflow: "hidden",
                    textOverflow: "ellipsis",
                    whiteSpace: "nowrap",
                    fontFamily: '"Fredoka One", sans-serif', // Set the font family
                    color: "rgb(57, 61, 114)", // Set the color
                  }}
                >
                    Total: &nbsp;
                </Typography>
                <Typography
                  variant="h4"
                  component="h2"
                  style={{
                    overflow: "hidden",
                    textOverflow: "ellipsis",
                    whiteSpace: "nowrap",
                    fontFamily: '"Fredoka One", sans-serif', // Set the font family
                    color: "#FED557", // Set the color
                  }} // Correct comment to align with CSS properties
                >
                  ${serviceData.serviceTotalWithComission.toFixed(2)}
                </Typography>
              </div>
            </div>
          </div>
        )}
        <div style={{margin: '10px', alignContent:'center', justifyContent:'center'}}>
          <StripePayment
            paymentTotal={paymentDetails}
            serviceID={serviceID}
            nannyUID={nannyUID}
            handleClose={handleClose}
          />
        </div>
      </Dialog>
    </React.Fragment>
  );
};
export default PaymentDialog;
