import React, { useState } from 'react';
import { Dialog, DialogTitle, DialogContent, DialogActions } from "@mui/material";
import { getAuth, sendPasswordResetEmail } from 'firebase/auth';


const ResetPasswordDialog = ({ open, onClose }) => {
  const [email, setEmail] = useState('');
  const [emailHasBeenSent, setEmailHasBeenSent] = useState(false);
  const [error, setError] = useState(null);

  const onChangeHandler = (event) => {
    setEmail(event.target.value);
  };

  const sendResetEmail = (event) => {
    event.preventDefault();
    const auth = getAuth();
    sendPasswordResetEmail(auth, email)
      .then(() => {
        setEmailHasBeenSent(true);
        setTimeout(() => { setEmailHasBeenSent(false) }, 3000);
      })
      .catch((error) => {
        setError('Error resetting password: ' + error.message);
      });
  };

  return (
    <Dialog
      open={open}
      onClose={onClose}
      PaperProps={{
        style: {
          borderRadius: 15,
        },
      }}
    >
      <DialogTitle className="dialog-title-component">
        <p className="dialog-title-style">Restablece tu contraseña</p>
      </DialogTitle>
      <DialogContent style={{ fontFamily: "Fredoka One" }}>
        <div className="form-group input-styles">
          <fieldset>
            <input
              type="email"
              className="form-control"
              name="email"
              placeholder="Email"
              required
              onChange={onChangeHandler}
              value={email}
            />
          </fieldset>
        </div>
      </DialogContent><div style={{ display: "flex", flexDirection: "column", alignItems: "center", marginTop: "5px", marginBottom: "5px" }}>
            {emailHasBeenSent && <div style={{ textAlign: "center" }}>¡Se ha enviado un correo electrónico a tu dirección!</div>}
            {error !== null && <div style={{ color: "red", textAlign: "center", marginTop: "10px", wordWrap: "break-word" }}>{error}</div>}
        </div>
    <DialogActions
        style={{
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          paddingBottom: "20px"
        }}
        >
        <button
          onClick={sendResetEmail}
          className="theme-btn btn-style-one login-button"
          style={{ padding: "10px 20px 10px" }}
        >
          Enviame un link de restablecimiento
        </button>
      </DialogActions>
    </Dialog>
  );
};

export default ResetPasswordDialog;
