import React, { useState } from "react";
import {
  Chip,
  Typography,
  TextField,
  Divider,
  InputLabel,
  Select,
  Box,
  OutlinedInput,
  MenuItem,
} from "@mui/material";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { DatePicker, MobileTimePicker } from "@mui/x-date-pickers";
import dayjs from "dayjs";
import { format } from "date-fns";
import { es } from "date-fns/locale";
const adapter = new AdapterDayjs();

function calculateAge(birthdate) {
  const today = new Date();
  const birthDate = new Date(birthdate);
  const oneDay = 24 * 60 * 60 * 1000; // Number of milliseconds in one day
  const diffInDays = Math.round(
    Math.abs((today.getTime() - birthDate.getTime()) / oneDay)
  );
  return diffInDays;
}

export default function PackageCareForm({
  userData,
  packageCareData,
  handlePackageCareData,
}) {
  const [startTime, setStartTime] = useState(null);

  const handlePackageTypeChange = (event) => {
    handlePackageCareData({
      target: {
        name: "packageType",
        value: event.target.value,
      },
    });

    const newPackageHours =
      event.target.value === "Tiempo Completo (8hrs)" ? 8 : 4;
    calculateEndTime(startTime, newPackageHours);
  };

  const [displayAddress, setDisplayAddress] = useState(false);
  const [displayRestaurant, setDisplayRestaurant] = useState(false);

  const kids = (userData?.kids || []).map((kid) => ({
    ...kid,
    age: calculateAge(kid.birthdate),
  }));

  const addresses = (userData?.addresses || []).map((address) => ({
    ...address,
  }));

  const ITEM_HEIGHT = 48;
  const ITEM_PADDING_TOP = 8;
  const MenuProps = {
    PaperProps: {
      style: {
        maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
        width: 250,
      },
    },
  };

  const handleChangeRestaurant = (event) => {
    handlePackageCareData({
      target: {
        name: "serviceRestaurantAddressName",
        value: event.target.value,
      },
    });
  };

  const handleChangeRestaurantAddress = (event) => {
    handlePackageCareData({
      target: {
        name: "serviceRestaurantAddress",
        value: event.target.value,
      },
    });
  };

  const handleDateChange = (newDate) => {
    const newDateWithWeekAdded = adapter.date(newDate).add(1, "week").toDate();

    handlePackageCareData({
      target: {
        name: "startDate",
        value: newDate, // You can also convert it to a string if needed
      },
    });

    handlePackageCareData({
      target: {
        name: "endDate",
        value: newDateWithWeekAdded,
      },
    });

    handlePackageCareData({
      target: {
        name: "serviceDays",
        value: getServiceDays(newDate.$d.$d),
      },
    });
  };

  const handleNoteChange = (event) => {
    handlePackageCareData({
      target: {
        name: "serviceNotes",
        value: event.target.value,
      },
    });
  };

  const formatDate = (date) => {
    if (date) {
      return new Intl.DateTimeFormat("es-ES", {
        year: "numeric",
        month: "long",
        day: "numeric",
      }).format(date);
    }
    return "";
  };

  const handleEndTimeChange = (newEndTime) => {
    handlePackageCareData({
      target: {
        name: "endTime",
        value: newEndTime,
      },
    });
  };

  const calculateEndTime = (startTime, packageHours) => {
    if (startTime) {
      const endTime = new Date(
        startTime.getTime() + packageHours * 60 * 60 * 1000
      );
      handleEndTimeChange(endTime); // Update the packageCareData.endTime state
      return endTime;
    }
    return null;
  };

  const handleChangeChild = (event) => {
    const {
      target: { value },
    } = event;
    handlePackageCareData({
      target: {
        name: "serviceFor",
        value: typeof value === "string" ? value.split(",") : value,
      },
    });
  };

  const handleChangeAddress = (event) => {
    handlePackageCareData({
      target: {
        name: "serviceAddress",
        value: event.target.value,
      },
    });
  };

  const packageHours =
    packageCareData.packageType === "Tiempo Completo (8hrs)" ? 8 : 4;

  const tomorrow = new Date();
  tomorrow.setDate(tomorrow.getDate() + 1); // set date to tomorrow

  const handleToggleDisplayAddress = () => {
    setDisplayAddress(!displayAddress);
    setDisplayRestaurant(false);
    handlePackageCareData({
      target: {
        name: "serviceIsRestaurant",
        value: false,
      },
    });
  };

  const handleToggleDisplayRestaurant = () => {
    setDisplayRestaurant(!displayRestaurant);
    setDisplayAddress(false);
    handlePackageCareData({
      target: {
        name: "serviceIsRestaurant",
        value: true,
      },
    });
  };

  function getServiceDays(startDate) {
    let serviceDays = [];
    // Initialize the date using the startDate string
    let date = new Date(startDate);

    // Function to check if it's a weekend and adjust to next Monday
    function adjustToNextWorkingDay(d) {
      let day = d.getDay();
      if (day === 6) {
        // Saturday
        d.setDate(d.getDate() + 2); // Move to Monday
      } else if (day === 0) {
        // Sunday
        d.setDate(d.getDate() + 1); // Move to Monday
      }
    }

    adjustToNextWorkingDay(date); // Adjust the start date if necessary

    for (let i = 0; i < 5; i++) {
      while (date.getDay() === 6 || date.getDay() === 0) {
        // If it's weekend, adjust to next Monday
        adjustToNextWorkingDay(date);
      }

      serviceDays.push({
        day: date.getTime() / 1000, // Convert to Unix timestamp (seconds)
        startTime: null,
        endTime: null,
      });

      // Move to the next day
      date.setDate(date.getDate() + 1);
    }

    return serviceDays;
  }

  return (
    <div>
      <div className="sectionCard" style={{ marginTop: "10px" }}>
        <Typography variant="h6">
          Seleccione el tipo de paquete:
        </Typography>
        <Typography variant="subtitle1" sx={{ marginBottom: 2 }}>
          5 días de cuidado de lunes a viernes
        </Typography>
        <Chip
          label="Medio Tiempo (4hrs)"
          onClick={() =>
            handlePackageTypeChange({
              target: { value: "Medio Tiempo (4hrs)" },
            })
          }
          variant={
            packageCareData.packageType === "Medio Tiempo (4hrs)"
              ? "filled"
              : "outlined"
          }
          color={
            packageCareData.packageType === "Medio Tiempo (4hrs)"
              ? "primary"
              : "default"
          }
          sx={{ cursor: "pointer", marginRight: 1 }}
        />
        <Chip
          label="Tiempo Completo (8hrs)"
          onClick={() =>
            handlePackageTypeChange({
              target: { value: "Tiempo Completo (8hrs)" },
            })
          }
          variant={
            packageCareData.packageType === "Tiempo Completo (8hrs)"
              ? "filled"
              : "outlined"
          }
          color={
            packageCareData.packageType === "Tiempo Completo (8hrs)"
              ? "primary"
              : "default"
          }
          sx={{ cursor: "pointer" }}
        />
        <Divider sx={{ marginTop: 2, marginBottom: 2 }} />
        <Typography
          variant="h6"
          sx={{
            marginBottom: 2,
            fontFamily: "Fredoka One",
            textAlign: "left",
            color: "#393d72",
          }}
        >
          Seleccione la fecha de inicio:
        </Typography>
        <DatePicker
          value={packageCareData.startDate}
          onChange={handleDateChange}
          minDate={adapter.date(tomorrow)}
          format="DD/MM/YYYY"
          inputFormat="DD/MM/YYYY"
          shouldDisableDate={(date) => {
            const jsDate = new Date(date);
            const day = jsDate.getDay();
            return day === 0 || day === 6;
          }}
        />
        <div>
          <Typography
            variant="h6"
            sx={{
              marginTop: 2,
              marginBottom: 2,
              fontFamily: "Fredoka One",
              color: "#393d72",
            }}
          >
            Días de servicio:
          </Typography>              
          {packageCareData.serviceDays && packageCareData.serviceDays.map((day, index) => {
            const date = new Date(day.day * 1000);
            const formattedDate = format(date, "EEEE d MMMM", { locale: es });
            return <Chip key={index} label={formattedDate} color="primary" sx={{ margin: '5px', backgroundColor: 'rgb(255, 72, 128)' }} />;
          })}
        </div>
        <Typography
          variant="h6"
          sx={{
            marginTop: 2,
            marginBottom: 2,
            fontFamily: "Fredoka One",
            textAlign: "left",
            color: "#393d72",
          }}
        >
          Seleccione el horario de inicio:
        </Typography>
        <MobileTimePicker
          label="Hora de Inicio"
          value={dayjs(packageCareData.startTime)}
          onChange={(newValue) => {
            const newStartTime = newValue ? newValue.toDate() : null;
            setStartTime(newStartTime); // Update the startTime state
            calculateEndTime(newStartTime, packageHours); // Call calculateEndTime to update endTime when startTime changes
            handlePackageCareData({
              target: { name: "startTime", value: newStartTime },
            });
            handlePackageCareData({
              target: { name: "endTime",value: calculateEndTime(newStartTime, packageHours) },
          });}}
          renderInput={(params) => <TextField {...params} />}
          disabled={!packageCareData.startDate}
        />

        {packageCareData.endTime && (
          <Typography variant="subtitle1" sx={{ marginTop: 1 }}>
            Hora de Fin:{" "}
            {new Intl.DateTimeFormat("es-ES", {
              hour: "2-digit",
              minute: "2-digit",
            }).format(packageCareData.endTime)}
          </Typography>
        )}
      </div>
      <div className="sectionCard" style={{ marginTop: "10px" }}>
        <Typography
          sx={{
            ml: 2,
            flex: 1,
            fontFamily: "Fredoka One",
            textAlign: "left",
            color: "#393d72",
          }}
          variant="h6"
          component="div"
        >
          Selecciona para quien es el cuidado:
        </Typography>
        <div style={{ marginTop: "10px" }}>
          <InputLabel id="select-multiple-chip">Nombres:</InputLabel>
          <Select
            labelId="select-mutiple-chip"
            id="child-select"
            multiple
            value={packageCareData.serviceFor || []}
            onChange={handleChangeChild}
            style={{ width: "50%" }}
            input={<OutlinedInput id="select-multiple-chip" label="Nombres:" />}
            renderValue={(selected) => (
              <Box sx={{ display: "flex", flexWrap: "wrap", gap: 0.5 }}>
                {selected.map((value) => (
                  <Chip
                    key={value}
                    label={value}
                    sx={{ backgroundColor: "#ffae00" }}
                  />
                ))}
              </Box>
            )}
            MenuProps={MenuProps}
          >
            {kids.map((kid) => (
              <MenuItem key={kid.name} value={kid.name}>
                {kid.name}
              </MenuItem>
            ))}
          </Select>
        </div>
      </div>
      <div className="sectionCard" style={{ marginTop: "10px" }}>
        <Typography
          sx={{
            ml: 2,
            flex: 1,
            fontFamily: "Fredoka One",
            textAlign: "left",
            color: "#393d72",
          }}
          variant="h6"
          component="div"
        >
          Selecciona la dirección del cuidado:
        </Typography>
        <div
          style={{
            alignItems: "center",
            marginTop: "10px",
          }}
        >
          <Chip
            label="Hogar"
            clickable
            color="primary"
            variant={displayAddress ? "filled" : "outlined"}
            onClick={handleToggleDisplayAddress}
            style={{ marginRight: "10px" }}
          />
          <Chip
            label="Restaurante/Salón de Fiestas"
            clickable
            color="primary"
            variant={displayRestaurant ? "filled" : "outlined"}
            onClick={handleToggleDisplayRestaurant}
          />
        </div>
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            marginTop: "10px",
          }}
        >
          {displayAddress && (
            <div>
              <InputLabel id="select-addresses">Direcciones:</InputLabel>
              <Select
                labelId="select-addresses"
                id="address-select"
                value={packageCareData.serviceAddress}
                onChange={handleChangeAddress}
                label="Direcciones"
                style={{ width: "80%" }}
              >
                {addresses.map((address) => (
                  <MenuItem
                    key={address.street}
                    value={`${address.street}, ${address.neighborhood}, ${address.city}, ${address.state}`}
                  >
                    {address.street}, {address.city}, {address.state}
                  </MenuItem>
                ))}
              </Select>
            </div>
          )}
          {displayRestaurant && (
            <div
              style={{
                display: "flex",
                flexDirection: "column",
                marginTop: "10px",
              }}
            >
              <TextField
                label="Nombre Salón/Restaurante"
                variant="outlined"
                style={{ marginBottom: "10px" }}
                value={packageCareData.serviceRestaurantAddressName}
                onChange={handleChangeRestaurant}
              />
              <TextField
                label="Dirección"
                multiline
                rows={4}
                variant="outlined"
                value={packageCareData.serviceRestaurantAddress}
                onChange={handleChangeRestaurantAddress}
              />
            </div>
          )}
        </div>
      </div>
      <div className="sectionCard" style={{ marginTop: "10px" }}>
        <Typography
          sx={{
            ml: 2,
            flex: 1,
            fontFamily: "Fredoka One",
            textAlign: "left",
            color: "#393d72",
          }}
          variant="h6"
          component="div"
        >
          Información adicional:
        </Typography>
        <div style={{ marginTop: "10px" }}>
          <TextField
            id="outlined-multiline-static"
            label="Información adicional"
            multiline
            rows={4}
            value={packageCareData.serviceNotes || ""}
            variant="outlined"
            style={{ width: "80%" }}
            onChange={handleNoteChange}
            placeholder="Ej. Alergias, medicamentos, rutinas, etc."
          />
        </div>
      </div>
    </div>
  );
}
